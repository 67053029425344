import { AxiosError } from 'axios'

import { Store } from 'pinia-class-component'

import { BaseStore } from '#stores/base'

import { APIApp, APIAppListItem } from '#types'

export type SearchType = 'uuid' | 'info' | 'email'

@Store()
export class AppsStore extends BaseStore {
  public dataWait = false

  public app: APIApp | null = null

  public apps: APIAppListItem[] = []

  public async searchApps(data?: { searchType: SearchType; search: string }) {
    // empty `searchType` and `search` will list all apps
    this.dataWait = true
    const response = await this.makeRequest(
      { method: 'get', url: '/api/v1/apps/search', params: data },
      'searchApps',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.dataWait = false
    this.apps = response?.data?.applications
  }

  public async appDetails(uid: string) {
    this.dataWait = true
    const response = await this.makeRequest({ method: 'get', url: `/api/v1/apps/${uid}/details` }, 'appDetails').catch(
      (_axiosError: AxiosError) => {
        //ignore for now
        return null
      },
    )
    this.dataWait = false
    this.app = response?.data
  }

  public deselectApp() {
    this.app = null
  }

  public async csvDownload() {
    const response = await this.makeRequest(
      {
        method: 'get',
        url: '/api/v1/apps/csv-export',
        headers: {
          Accept: 'text/csv',
        },
        responseType: 'blob',
      },
      'appDetails',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })

    if (response?.status !== 200) {
      return
    }

    return response.data
  }

  public async setAppStatus(app: { uid: string; status: string }) {
    this.dataWait = true
    await this.makeRequest(
      {
        method: 'put',
        url: `/api/v1/apps/${app.uid}`,
        data: {
          status: app.status,
        },
      },
      'setAppStatus',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.dataWait = false
  }
}
