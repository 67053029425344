<template>
  <v-text-field
    v-if="$route.params.uuid || ($route.meta && $route.meta.title === 'Home')"
    ref="searchRef"
    v-model.trim="search"
    variant="solo"
    color="white"
    :loading="isSearching"
    single-line
    hide-no-data
    hide-details
    no-data-text="Search did not match any members"
    append-icon="mdi-magnify"
    placeholder="Quick search for members..."
    class="flex-grow-1 flex-shrink-1 search text-white ml-1 mr-2"
    :class="{ 'ml-4': !$route.params.uuid }"
    @update:model-value="changeUser()"
    @keydown.space.prevent
    @keyup:enter="changeUser()"
  />
</template>

<script lang="ts">
  import { ref } from 'vue'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { Debounce } from '@jouzen/outo-toolkit-vuetify'

  import { SearchType, UserSearch, UserSearchResponseSingleUser, isSearchError } from '#utils/user/search'

  import { UserStore } from '#stores'

  import { Member } from '#types'

  @Component
  class MembersSearchBar extends Vue {
    @Prop() public title!: string
    @Prop() public user!: Member | null

    @Prop() public rights!: string[]

    @Prop() public users!: UserSearchResponseSingleUser[]

    @Prop() public searchBoxNotification!: string | undefined

    public search = ''
    public searchRef: any = ref(null)

    public isSearching = false

    public lastSearchKeyword = ''
    public searchBoxDefaultNotification =
      'Give exact user ID or email, or use <a class="text-info" href="/users?search=#search">advanced search</a>.'

    protected readonly userStore = new UserStore()

    @Watch('$route', { immediate: true })
    protected onRouteChanged(val: any) {
      if (!val?.params?.uuid) {
        this.search = ''
      }
    }

    @Emit('update:searchBoxNotification')
    private updateSearchBoxNotification(noti: string | undefined) {
      return noti
    }

    @Debounce(500)
    public changeUser() {
      logEvent(this.$analytics, 'header_search', {
        category: `Users:Header Search`,
        action: 'Click header search',
        label: 'Click header search',
        page_location: window.location.toString().split('?')[0],
        page_title: this.$route.params.uuid ? 'Oura User' : this.title,
      })

      this.updateSearchBoxNotification(undefined)

      if (!this.search) {
        return
      }

      if (this.search.length) {
        const data = this.users.find(
          (u: any) =>
            u.uuid?.toLowerCase() === this.search?.toLowerCase() ||
            u.email?.toLowerCase() === this.search?.toLowerCase(),
        )

        if (data) {
          this.goToUser(data)
        } else {
          let payload:
            | { count: number; search: string; filters: string[] }
            | { search: string; searchType: SearchType } = { count: 20, search: this.search, filters: [] }

          this.userStore.resetSearch()

          const userSearch = new UserSearch()
          const searchConfig = userSearch.getSearchOptions(this.search, this.rights)

          let searchBoxNotification = this.searchBoxDefaultNotification.replace('#search', searchConfig.keyword)
          let defaultNotification = true

          if (isSearchError(searchConfig)) {
            this.updateSearchBoxNotification(this.searchBoxDefaultNotification.replace('#search', searchConfig.keyword))
            return
          }

          if (
            searchConfig?.searchType &&
            searchConfig.searchType !== 'uuid' &&
            searchConfig.searchType !== 'emailExact'
          ) {
            this.updateSearchBoxNotification(this.searchBoxDefaultNotification.replace('#search', searchConfig.keyword))
            return
          }

          payload = { search: searchConfig.keyword, searchType: searchConfig.searchType }

          this.isSearching = true
          this.lastSearchKeyword = this.search

          this.userStore.search(payload).then((response) => {
            this.isSearching = false

            if (response?.count == 0) {
              if (searchConfig.searchType === 'uuid') {
                searchBoxNotification = 'User was not found with provided user UUID'
              }
              if (searchConfig.searchType === 'emailExact') {
                searchBoxNotification = 'User was not found with provided email address'
              }
              const searchWithEmailAccess = this.rights.includes('allowPersonalDataAccess')
              if (!searchWithEmailAccess) {
                searchBoxNotification =
                  "Please enter user UUID. You don't have permission to search with email address."
              }

              defaultNotification = false

              this.updateSearchBoxNotification(searchBoxNotification)
            }

            if (response && response?.count >= 1) {
              this.updateSearchBoxNotification('')

              const data = response.contents.find(
                (u: any) =>
                  u.uuid?.toLowerCase() === this.search?.toLowerCase() ||
                  u.email?.toLowerCase() === this.search?.toLowerCase(),
              )

              if (data) {
                this.goToUser(data)
              }
            }
          })

          if (defaultNotification && this.search != this.lastSearchKeyword) {
            this.updateSearchBoxNotification('')
          }
        }
      }
    }

    private goToUser(data: any) {
      this.search = ''

      this.searchRef?.blur()

      this.$router.push({ path: `/users/${data.uuid}` }).catch((_err) => {})
    }
  }

  export default toNative(MembersSearchBar)
</script>
