import { LatestVersions, LatestVersionsResponse } from '#types'

export function extractLatestVersions(data: LatestVersionsResponse): LatestVersions {
  return {
    firmwareVersion: data.firmwareVersion,
    bootloaderVersion: data.bootloaderVersion,
    minimumOsVersion: data.minimumOsVersion,
    appVersion: data.appVersion,
  }
}
