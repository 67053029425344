export const orderSearchHeaders: any[] = [
  {
    title: 'Email',
    key: 'object.account.email',
    sortable: false,
  },
  {
    title: 'Order ID',
    key: 'object.orderId',
    sortable: false,
  },
  {
    title: 'Order status',
    key: 'object.orderStatus',
    sortable: true,
  },
  {
    title: 'Created at',
    key: 'object.createdAt',
    sortable: true,
    align: 'end',
  },
]

enum OrderSearchTypes {
  email = 'Member email',
  orderId = 'Order ID',
}

export type OrderSearchType = keyof typeof OrderSearchTypes

export const orderSearchTypes = Object.entries(OrderSearchTypes).map(([value, title]) => ({ title, value }))
