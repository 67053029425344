import { UserStore } from './user'

import { AxiosError } from 'axios'

import { Store } from 'pinia-class-component'

import { BaseStore } from '#stores/base'

let retryCount = 0

@Store()
export class DebugStore extends BaseStore {
  public dataWait = false
  public initWait = false

  public debugLogs: any[] = []
  public extraLogs: any[] = []

  public async setDebugLogs(data: any) {
    const userStore = new UserStore()

    // Make data.uuid property mandatory so we can get rid of this if
    if (data.uuid) {
      // We should provide query parameters for Axios as an object, not assemble query string manually
      let url = `/api/v1/users/${data.uuid}/debuglog-search?count=${data.count}&highlight=strong`
      const to = new Date(data.to || Date.now())
      const from = new Date(data.from || to.getTime() - 30 * 24 * 60 * 60 * 1000)
      url += `&from=${from?.toISOString()}&to=${to?.toISOString().replace('T00:00:00.000Z', 'T23:59:59.999Z')}`

      if (data.search) {
        url += `&search=${encodeURIComponent(data.search)}&case_sensitive=${data.caseSensitive}`
      }

      if (data.exclude) {
        url += `&exclude=${data.exclude}`
      }

      this.dataWait = true
      this.debugLogs = []

      const response = await this.makeRequest(
        { method: 'get', url: url },
        'setDebugLogs',
        data.uuid,
        userStore.user?.uuid,
      ).catch((_axiosError: AxiosError) => {
        //ignore for now
        return null
      })

      // WTF is this?
      if (response?.status == 202 && retryCount < 10) {
        retryCount++

        this.initWait = true

        window.setTimeout(() => this.setDebugLogs(data), 15 * 1000)
      } else {
        retryCount = 0
        this.debugLogs = response?.data?.contents
        this.initWait = false
        this.dataWait = false
      }
    }
  }
  public async setExtraLogs(data: any) {
    const userStore = new UserStore()

    let url = `/api/v1/users/${data.uuid}/debuglog-search/context?before=${data.before}&after=${data.after}`
    url += `&file_timestamp=${encodeURIComponent(data.fileTimestamp)}&line=${data.line}`
    this.dataWait = true

    const response = await this.makeRequest(
      { method: 'get', url: url },
      'setExtraLogs',
      data.uuid,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.extraLogs = response?.data?.contents
    this.dataWait = false
  }

  public resetLogs() {
    this.debugLogs = []
    this.extraLogs = []
  }
}
