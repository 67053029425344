<template>
  <v-card min-width="100%">
    <v-card-title>
      <p class="text-overline">Connected Accounts</p>
    </v-card-title>
    <v-container v-if="connectedAccountChangeEvents.length > 0">
      <v-timeline v-if="!isLoading" class="ml-4" density="comfortable">
        <template v-for="(accountChange, index) in connectedAccountChangeEvents" :key="index">
          <v-timeline-item size="small" :dot-color="accountChange.eventColor">
            <div class="d-flex">
              <strong class="me-6 pt-2">{{ formatDateTime(accountChange.time, 'DD MMM YYYY') }}</strong>
              <div>
                <p>{{ accountChange.message }}</p>
                <div class="text-caption">{{ accountChange.helpText }}</div>
              </div>
            </div>
          </v-timeline-item>
        </template>
      </v-timeline>
    </v-container>

    <v-card v-if="!isLoading && connectedAccountChangeEvents.length < 1">
      <v-card-text class="mt-n3">
        <div class="d-flex pa-4">
          <v-icon color="blue">mdi-alert</v-icon>
          <p class="mb-0 ml-3">No connected accounts found for this ring</p>
        </div>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { getConnectedAccountChangeEvents } from '#utils/ring/connectedAccounts'

  import { RingsStore, UserStore } from '#stores'

  @Component({})
  class RingHistoryInfo extends mixins(DateTime) {
    @Prop() public ringSerial!: string

    protected readonly userStore = new UserStore()
    protected readonly ringsStore = new RingsStore()

    public get isLoading() {
      return this.ringsStore.dataWait
    }

    private get connectedAccounts() {
      return this.ringsStore.connectedAccounts
    }

    public get connectedAccountChangeEvents() {
      return getConnectedAccountChangeEvents(this.connectedAccounts, this.userStore.user?.uuid ?? null)
    }

    @Watch('ringSerial', { immediate: true })
    protected ringSerialChanged() {
      if (this.ringSerial) {
        this.ringsStore.getConnectedAccounts(this.ringSerial)
      }
    }
  }

  export default toNative(RingHistoryInfo)
</script>

<style lang="scss" scoped></style>
