import { Pinia, Store, getActivePinia } from 'pinia'

import { BaseStoreState } from '#types'

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>
}

/**
 * Reset data on Pinia stores, and abort in-flight Axios requests on them.
 *
 * Inspiration from: https://github.com/vuejs/pinia/discussions/911#discussioncomment-4445268 and
 * https://lobotuerto.com/notes/til-how-to-reset-all-stores-in-pinia
 */
export function resetAllPiniaStores() {
  const pinia = getActivePinia() as ExtendedPinia

  if (!pinia) {
    throw new Error('There is no active Pinia instance')
  }

  pinia._s.forEach((store: any) => {
    // We don't want to reset 'AppStore' (Darwin will break) or 'PrefsStore' (contains Darwin user preferences).
    if (store.resetAllowed) {
      storeCleanup(store)
      store.$reset()
    }
  })
}

function storeCleanup(currentState: BaseStoreState) {
  // Abort all in-flight requests
  if (currentState.abortControllers) {
    Object.keys(currentState.abortControllers).every((k) =>
      currentState.abortControllers[k].forEach((controller: any) => {
        controller.controller.abort('Store was reset')
      }),
    )
  }
}
