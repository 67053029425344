import { Ring } from '#types'

/**
 * Replace all ring names provided in searchRingNames with replaceWith in ring list.
 *
 * @param rings             Ring data we want to modify
 * @param searchRingNames   List of ring names to search for
 * @param replaceWith       Replace these ring names with this string
 */
export function replaceRingName(rings: Ring[], searchRingNames: string[], replaceWith: string = 'unknown'): Ring[] {
  rings.forEach(function (ring: Ring, index, array) {
    if (ring.ringSerialInfo?.product) {
      for (const searchRingName of searchRingNames) {
        ring.ringSerialInfo.product = ring.ringSerialInfo.product.replaceAll(searchRingName, replaceWith)
      }
    }
    array[index] = ring
  })
  return rings
}
