import { convertTotalSecondsToHoursMinutes } from '#utils/datetime/calculate'
import { formatDateTime, formatDateTimeWithDisplayTimezone } from '#utils/datetime/format'

import { DisplayTimezone, SleepPeriodSingleResponse, SleepPeriodsItem, SleepPeriodsResponse } from '#types'

export function extractSingleSleepPeriodsItemFromResponse(
  index: number,
  singleResponse: SleepPeriodSingleResponse,
  displayTimezone: DisplayTimezone = DisplayTimezone.MEMBER,
): SleepPeriodsItem {
  const bedtimeStart = formatDateTimeWithDisplayTimezone(singleResponse.bedtimeStart, displayTimezone, 'DD MMM HH:mm')
  const bedtimeEnd = formatDateTimeWithDisplayTimezone(singleResponse.bedtimeEnd, displayTimezone, 'DD MMM HH:mm')

  return {
    index: index,
    day: singleResponse.day,
    date: formatDateTime(singleResponse.day, 'DD MMM YYYY') ?? 'Unknown',
    type: singleResponse.type ?? 'Unknown',
    bedtimeStartFull: singleResponse.bedtimeStart,
    bedtimeEndFull: singleResponse.bedtimeEnd,
    bedtimeStart: bedtimeStart,
    bedtimeEnd: bedtimeEnd,
    duration: convertTotalSecondsToHoursMinutes(singleResponse.durationSeconds),
  }
}

export function extractSleepPeriodsFromResponse(
  sleepPeriodsDataResponse: SleepPeriodsResponse,
  displayTimezone: DisplayTimezone = DisplayTimezone.MEMBER,
): SleepPeriodsItem[] {
  const sleepingPeriodsData: SleepPeriodsItem[] = []
  sleepPeriodsDataResponse.sleepPeriods.forEach((sleep: SleepPeriodSingleResponse, index: number) => {
    sleepingPeriodsData.push(extractSingleSleepPeriodsItemFromResponse(index, sleep, displayTimezone))
  })
  return sleepingPeriodsData
}
