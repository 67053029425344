import { getNumber, getRemoteConfig } from 'firebase/remote-config'

import { AvailableDiagnostic } from '#types'

/***
 * Get the diagnostic version from the remote config or return the default version
 * @param diagnostic
 */
export function getDiagnosticVersion(diagnostic: AvailableDiagnostic): number {
  const featureFlagValue = getNumber(getRemoteConfig(), diagnostic.versionFeatureFlagName)

  const defaultVersion = Math.min(...diagnostic.versions)

  if (featureFlagValue) {
    return diagnostic.versions.includes(featureFlagValue) ? featureFlagValue : defaultVersion
  }

  return defaultVersion
}
