<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="text-overline">Data troubleshooter</div>
      </v-card-title>

      <v-card-text>
        <v-alert v-if="!sensitiveDataVisible" type="info" class="mb-10" style="position: relative">
          Some filters are disabled, you can enable them by enabling showing of sensitive data from the top bar.
        </v-alert>

        <v-toolbar color="transparent">
          <v-row class="px-3 flex-sm-column flex-md-row">
            <v-col class="d-flex align-start" cols="12" md="1">
              <v-btn color="rgb(var(--v-theme-surface))" class="mr-8" variant="elevated" @click="dialog = true">
                <v-icon left>mdi-filter-outline</v-icon>
                All
              </v-btn>
            </v-col>
            <v-col cols="5" md="5">
              <v-autocomplete
                v-model="chips"
                :items="availableComboboxFields"
                :disabled="filterLimitsExceed"
                :menu-props="menuProps"
                :label="addFilterLabel"
                placeholder="Type to search for a filter"
                multiple
                hide-selected
                item-title="name"
                item-value="value"
                return-object
                variant="solo-filled"
                @update:model-value="comboboxSelect($event)"
              >
                <template #selection="{ item, index }">
                  <span style="max-width: 140px !important">
                    <v-chip v-if="index < 2">
                      <span>{{ item.title }}</span>
                    </v-chip>
                    <span v-if="index === 2" class="text-grey text-caption align-self-center">
                      (+{{ chips.length - 2 }} other{{ chips.length > 3 ? 's' : '' }})
                    </span>
                  </span>
                </template>
                <template #no-data>
                  <v-list-item>
                    <v-chip color="blue lighten-3" closable label small @click="dialog = true">See all filters</v-chip>
                  </v-list-item>
                </template>
                <template #item="{ props, item }">
                  <div class="d-flex align-center">
                    <v-col cols="10"><v-list-item v-bind="props" :title="item?.raw?.name" /></v-col>
                    <v-col cols="2">
                      <v-tooltip location="bottom">
                        <!-- eslint-disable-next-line vue/no-template-shadow -->
                        <template #activator="{ props }">
                          <v-icon
                            v-bind="props"
                            size="small"
                            class="mr-2"
                            @click="showGraphInfo = item?.raw?.name"
                            @click.stop
                          >
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <div class="tooltip">
                          <div>
                            <strong>Type:</strong>
                            {{ item?.raw?.graphType }}
                          </div>
                          <div>
                            <strong>Description:</strong>
                            {{ item?.raw?.description }}
                          </div>
                          <div>
                            <strong>Usage:</strong>
                            {{ item?.raw?.usageInfo }}
                          </div>
                        </div>
                      </v-tooltip>
                    </v-col>
                  </div>
                </template>
              </v-autocomplete>

              <v-dialog :model-value="dialog" width="1200" @after-leave="dialog = false">
                <v-card tile opacity="100" min-height="500">
                  <v-card-title>
                    <div class="title text-primary">TIMELINE SETTINGS</div>
                    <v-spacer />
                    <v-btn variant="plain" class="text-primary" icon @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>

                  <div style="width: 660px; display: inline-block; position: absolute; right: 240px">
                    <v-alert v-if="dialogErrorMessage" type="error" style="text-align: center">
                      {{ dialogErrorMessage }}
                    </v-alert>
                  </div>

                  <v-card-text>
                    <v-row>
                      <v-col cols="3" class="left-menu">
                        <v-list flat cols="4">
                          <v-list-item-title class="pl-3 text-primary">TIME RANGE</v-list-item-title>

                          <v-select
                            :items="quickTimeFields"
                            :value="quickSelect.find((field) => field.key === 'time')?.name || null"
                            return-object
                            item-title="name"
                            class="pl-4 pr-4"
                            hide-details
                            @update:model-value="$event ? selectQuickTime($event) : ''"
                          />
                        </v-list>

                        <v-divider class="mt-8 mb-4" />

                        <v-list>
                          <v-list-item-title v-if="preDefinedGroups" class="pl-3 text-primary">
                            GROUPS
                          </v-list-item-title>
                          <v-list-item
                            v-for="(item, i) in preDefinedGroups"
                            :key="i"
                            :class="preDefinedIsSelected(i) ? 'selected-group' : ''"
                            @click="selectPreDefined(i, item)"
                          >
                            <span>
                              {{ item.title }}
                            </span>
                          </v-list-item>
                        </v-list>

                        <v-divider class="mt-8 mb-4" />

                        <v-list-item-title class="d-flex">
                          <v-btn
                            class="grow ma-2 bg-dark"
                            :variant="alphabetize ? 'flat' : 'outlined'"
                            @click="alphabetize = !alphabetize"
                          >
                            A-Z
                            <v-icon dark right>mdi-sort-alphabetical-ascending</v-icon>
                          </v-btn>
                        </v-list-item-title>

                        <v-list-item-title class="d-flex">
                          <v-btn class="grow ma-2" color="primary" variant="outlined" @click="saveFilters()">
                            Save as default
                            <v-icon dark right>mdi-content-save</v-icon>
                          </v-btn>
                        </v-list-item-title>
                        <v-list-item-title class="d-flex">
                          <v-btn class="grow ma-2" color="primary" variant="outlined" @click="clearFilters()">
                            Clear filters
                            <v-icon dark right>mdi-filter-off</v-icon>
                          </v-btn>
                        </v-list-item-title>
                        <div v-if="$featureEnabled('troubleshooterWeights') && developerMode">
                          <b>Developer data</b>
                          filters selected: {{ chips.length }}, days selected: {{ selectedDays }}, max days:
                          {{ maxDays }}, earliest start date: {{ earliestStartDate }}, weight:
                          {{ currentTotalWeight }} / {{ maxTotalWeight }} ({{ weightPercentage }}%)
                        </div>
                      </v-col>

                      <v-col v-if="rights" cols="9" class="d-flex flex-wrap mt-n8">
                        <v-row
                          v-if="$featureEnabled('troubleshooterWeights')"
                          :no-gutters="true"
                          class="pb-0 pt-2 mb-0 mt-2"
                          style="max-height: 10px"
                        >
                          <v-progress-linear
                            v-model="weightPercentage"
                            :absolute="false"
                            :indeterminate="false"
                            :color="weightProgressBarColor"
                            :height="10"
                            style="display: inline"
                          />
                        </v-row>
                        <v-card flat>
                          <v-tabs v-model="activeTab" center-active grow show-arrows class="pb-0">
                            <v-tab v-for="(group, index) in timelineFields" :key="index">
                              {{ group[0].header }} ({{ getSelectedCount(group) }})
                            </v-tab>
                          </v-tabs>

                          <v-window v-model="activeTab">
                            <v-window-item v-for="(group, index) in modifiedTimelineFields" :key="index">
                              <v-row dense class="pa-4">
                                <v-col
                                  v-for="item in group"
                                  :key="item.name"
                                  cols="12"
                                  md="4"
                                  sm="6"
                                  class="pa-0 d-flex align-center"
                                >
                                  <v-col class="pa-0">
                                    <v-checkbox
                                      v-if="item.name"
                                      :model-value="filters.includes(item.value)"
                                      :disabled="singleFilterToggleDisabled(item)"
                                      class="pa-0 ma-0"
                                      hide-details
                                      @change="selectFilter(item)"
                                    >
                                      <template #label>
                                        {{ item.name
                                        }}{{
                                          $featureEnabled('troubleshooterWeights') && developerMode
                                            ? ' (' + item.weight + ')'
                                            : ''
                                        }}
                                      </template>
                                    </v-checkbox>
                                    <v-tooltip
                                      v-if="singleFilterToggleDisabled(item)"
                                      activator="parent"
                                      location="bottom"
                                    >
                                      {{ singleFilterToggleDisabledReason(item) }}
                                    </v-tooltip>
                                  </v-col>
                                  <v-col cols="2" class="pa-0">
                                    <v-tooltip location="bottom">
                                      <template #activator="{ props }">
                                        <v-icon
                                          v-bind="props"
                                          size="small"
                                          class="mr-2"
                                          @click="showGraphInfo = item.name"
                                          @click.stop
                                        >
                                          mdi-information-outline
                                        </v-icon>
                                      </template>
                                      <div class="tooltip">
                                        <div>
                                          <strong>Type:</strong>
                                          {{ item.graphType }}
                                        </div>
                                        <div>
                                          <strong>Weight:</strong>
                                          {{ item.weight }}
                                        </div>
                                        <div>
                                          <strong>Description:</strong>
                                          {{ item.description }}
                                        </div>
                                        <div>
                                          <strong>Usage:</strong>
                                          {{ item.usageInfo }}
                                        </div>
                                      </div>
                                    </v-tooltip>
                                  </v-col>
                                </v-col>
                              </v-row>
                            </v-window-item>
                          </v-window>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>

            <v-col cols="12" md="6">
              <DateRangePicker
                v-model:start-date="startDate"
                v-model:end-date="endDate"
                :min-date="earliestStartDate"
                @valid="datesAreValid = $event"
              />
            </v-col>
          </v-row>
        </v-toolbar>

        <v-row
          v-if="$featureEnabled('troubleshooterWeights')"
          :no-gutters="true"
          class="flex-sm-column flex-md-row pb-0 pt-0 mb-6 mt-8"
          style="max-height: 10px"
        >
          <v-progress-linear
            v-model="weightPercentage"
            :absolute="false"
            :indeterminate="false"
            :color="weightProgressBarColor"
            :height="10"
            style="display: inline"
          />
        </v-row>

        <v-row class="px-3 mb-3">
          <v-chip
            v-for="item in chips"
            :key="item.name"
            :color="getColorForItem(item)"
            :style="{ opacity: activeChips.includes(item.name) ? '1' : '.5' }"
            closable
            small
            class="text--white ma-1"
            @click:close="removeChip(item.name)"
            @click="toggleChip(item)"
          >
            <span class="pr-2">{{ item.name }}</span>
          </v-chip>
        </v-row>

        <NotificationBar :notifications="graphNotifications" />

        <div>
          <GenericGraph
            source="troubleshooter"
            :graphs="allowedGraphs"
            :user-uuid="uuid"
            :ring="ring"
            :start-date="validStartDate"
            :end-date="validEndDate"
            :fullscreen-supported="true"
          />
        </div>
      </v-card-text>
    </v-card>

    <v-snackbar v-model="snackbar" bottom right :timeout="5000">Filters and view saved as default</v-snackbar>
  </div>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { DateTime } from '#mixins/dateTime'

  import {
    allowSensitiveDataFields,
    defaultTimelineStartOffset,
    graphDefaultEarliestStartDate,
    graphMaxDays,
    graphMaxTotalWeight,
    graphSingleDayWeight,
    quickTimeFields,
    timelineMaxChipLength,
  } from '#views/members/constants'

  import { selectedPredefinedGroups } from '#utils/generic-graph/predefined-groups'
  import {
    calculateTimeWeightMultiplier,
    calculateTotalWeight,
    calculateWeightDateLimits,
  } from '#utils/generic-graph/weights'

  import { AppStore, GenericGraphStore, TimelineStore, UserStore } from '#stores'

  import {
    AvailableGraphs,
    CategoriesKeyObject,
    GraphFilterWeights,
    GraphGroupItem,
    GraphGroups,
    Notifications,
  } from '#types'

  @Component
  class DataTroubleshooter extends mixins(DateTime) {
    @Prop() public uuid!: string
    @Prop() public ring!: any

    protected readonly appStore = new AppStore()
    protected readonly userStore = new UserStore()
    protected readonly timelineStore = new TimelineStore()
    protected readonly genericGraphStore = new GenericGraphStore()

    public menuProps = {
      disabled: false,
      offset: '8px',
    }

    public view = 2

    public dialog = false
    public snackbar = false
    public alphabetize = true

    public chips: AvailableGraphs[] = []
    public maxChipsLength = timelineMaxChipLength
    public filters: string[] = []
    public activeChips: string[] = []

    public activeTab = 0

    /**
     * List of graphs user is allowed to see.
     *
     * Example data: ['batteryLevel', 'spo2usage', 'whrUsage']
     */
    public allowedGraphs: string[] = []

    public timelineFields: null | ({ header: string } | AvailableGraphs | { divider: boolean } | any)[][] = null
    public quickTimeFields = quickTimeFields
    public allowSensitiveDataFields = allowSensitiveDataFields

    /**
     * Earliest possible start date as YYYY-MM-DD string
     */
    public earliestStartDate = graphDefaultEarliestStartDate

    /**
     * Date values for date picker
     */
    public endDate = ''
    public startDate = ''

    public datesAreValid = false

    /**
     * Date sent to GenericGraph, values not changed if endDate and startDate not valid
     */
    public validEndDate = ''
    public validStartDate = ''

    public quickSelect: { key: string; value: any; name?: string; data?: string[] }[] = []

    private comboboxFields: any = []
    public showGraphInfo: string = ''
    public groupSelectionErrorMessage: string | null = null
    public timeLimitedMessage: string | null = null
    public selectedPredefinedGroups: string[] = []

    /**
     * Current time weight multiplier (single day weight * number of days shown)
     */
    public currentTimeWeightMultipler: number = 1

    /**
     * Current total weight. Filters weight * time weight multiplier
     */
    public currentTotalWeight: number = 1

    /**
     * Maximum allowed total weight
     */
    public maxTotalWeight: number = graphMaxTotalWeight

    /**
     * Maximum allowed days user can pick based on chosen filters and their weights
     */
    public maxDays: number = graphMaxDays

    /**
     * Selected number of days
     */
    public selectedDays: number = 0

    public weightPercentage: number = 0

    public weightProgressBarColor: string = 'green'

    @Emit('open-diff')
    public openDiff(data: any) {
      return data
    }

    public get graphNotifications(): Notifications {
      return this.genericGraphStore.notifications
    }

    public get rights() {
      return this.appStore.activeRights || []
    }

    public get timeline() {
      const data = this.timelineStore.timeline || []

      if (data.length) {
        logEvent(this.$analytics, 'troubleshooter_available', {
          category: `User:Timeline data available`,
          action: 'Filters in Troubleshooter available',
          label: 'Filters in Troubleshooter available',
          page_title: 'Oura user',
          page_location: window.location.toString().split('?')[0],
        })
      }

      return data
    }

    public get sensitiveDataVisible() {
      return this.userStore.sensitiveDataVisible
    }

    public get developerMode(): boolean {
      return false
    }

    public get availableComboboxFields() {
      return this.comboboxFields.filter((fields: any) => !this.singleFilterToggleDisabled(fields)) as any[]
    }

    private get localstorageFilterKey() {
      return 'OuraTimelineFiltersV2'
    }

    // modify timeline fields remove first and last element (header and divider)
    public get modifiedTimelineFields() {
      var groups = (this.timelineFields ?? []).map((group) => group.slice(1, -1))
      if (this.alphabetize) {
        for (const group of groups) {
          group.sort((a, b) => a.name.localeCompare(b.name))
        }
      }
      return groups
    }

    public get addFilterLabel(): string {
      if (this.$featureEnabled('troubleshooterWeights')) {
        return 'Add filter'
      } else {
        return `Add filter (max ${this.maxChipsLength})`
      }
    }

    /**
     * Have we already exceed filter limits (number of filters / weight).
     */
    public get filterLimitsExceed(): boolean {
      if (this.$featureEnabled('troubleshooterWeights')) {
        return this.currentTotalWeight >= this.maxTotalWeight
      } else {
        return this.chips.length >= this.maxChipsLength
      }
    }

    /**
     * Determines whether checkbox of toggling a single filter is enabled or not.
     *
     * This takes into consideration:
     * - Would we exceed filter limits (number of filters / weight) if this filter would be added.
     * - Filter is already selected (so we can unselect it)
     * - Access to the filter (sensitive data)
     */
    public singleFilterToggleDisabled(item: AvailableGraphs): boolean {
      return !!this.singleFilterToggleDisabledReason(item)
    }

    public singleFilterToggleDisabledReason(item: AvailableGraphs): string | null {
      if (!(this.canAddSingleFilter(item) || this.chips.some((chip) => chip.name == item.name))) {
        return 'Too many filters / weight'
      }
      if (!this.checkAccess(item)) {
        return this.sensitiveDataVisible
          ? "You don't have the rights to use this filter"
          : 'Enable sensitive data to use this filter'
      }
      if (item.needsRingSerial && !this.ring?.serialNumber) {
        return 'Member needs to have a ring to use this filter'
      }
      return null
    }

    /**
     * Can this filter still be added or would it exceed our filter limits (number of filters / weight).
     */
    public canAddSingleFilter(item: AvailableGraphs | null): boolean {
      if (item?.weight && this.$featureEnabled('troubleshooterWeights')) {
        return this.currentTotalWeight + item.weight * this.currentTimeWeightMultipler < this.maxTotalWeight
      } else {
        return this.chips.length < this.maxChipsLength
      }
    }

    public get dialogErrorMessage(): string | null {
      if (this.groupSelectionErrorMessage) {
        return this.groupSelectionErrorMessage
      }
      if (this.timeLimitedMessage) {
        return this.timeLimitedMessage
      }

      if (!this.$featureEnabled('troubleshooterWeights')) {
        if (this.chips.length >= this.maxChipsLength) {
          return `No more than ${this.maxChipsLength} filters can be selected!`
        }
      }
      return null
    }

    public getSelectedCount(group: CategoriesKeyObject[]) {
      return group.filter((field: CategoriesKeyObject) => this.chips.some((chip) => chip.name === field.name)).length
    }

    public get filterWeights(): GraphFilterWeights | null {
      return this.genericGraphStore.filterWeights
    }

    @Watch('uuid')
    protected onUuidChanged(_val: boolean, _oldVal: boolean) {
      this.refreshAvailableGraphs()
    }

    @Watch('sensitiveDataVisible')
    protected onSensitiveDataVisibleChanged() {
      this.refreshAvailableGraphs()
    }

    protected refreshAvailableGraphs() {
      if (this.availableGraphs) {
        this.genericGraphStore.getGraphOptions({
          uuid: this.uuid,
        })
      }
    }

    @Watch('availableGraphs')
    protected onAvailableGraphsChanged() {
      if (this.availableGraphs) {
        this.updateTimelineFields()
      }
      this.refreshConfigFromLocalStorage()
    }

    @Watch('filters')
    protected onFiltersChanged(val: string[], _oldVal: string[]) {
      this.changedFilters(val)
    }

    protected changedFilters(val: string[]) {
      if (this.preDefinedGroups) {
        this.selectedPredefinedGroups = selectedPredefinedGroups(this.preDefinedGroups, this.filters)
      }
      this.activeChips = val.map(
        (field: any) => this.comboboxFields.find((e: any) => e.value && e.value === field)?.name,
      )
      this.updateAllowedGraphs()
      this.updateCurrentTotalWeight()
      this.updateMaxDays(false)
    }

    public updateCurrentTotalWeight() {
      if (this.$featureEnabled('troubleshooterWeights') && this.filterWeights) {
        this.currentTotalWeight = calculateTotalWeight(
          this.filters,
          this.filterWeights,
          this.currentTimeWeightMultipler,
        )
        this.weightPercentage = Math.ceil((this.currentTotalWeight / this.maxTotalWeight) * 100)

        if (this.weightPercentage <= 33) {
          this.weightProgressBarColor = 'green'
        } else if (this.weightPercentage >= 33 && this.weightPercentage <= 66) {
          this.weightProgressBarColor = 'yellow'
        } else {
          this.weightProgressBarColor = 'red'
        }
      }
    }

    public updateMaxDays(adjustStartDate: boolean = false) {
      if (
        this.$featureEnabled('troubleshooterWeights') &&
        this.filterWeights &&
        this.currentTotalWeight > 0 &&
        this.validStartDate &&
        this.validEndDate
      ) {
        const { maxDays, earliestStartDateString, startDateNeedsToBeAdjusted } = calculateWeightDateLimits(
          calculateTotalWeight(this.filters, this.filterWeights, graphSingleDayWeight),
          this.maxTotalWeight,
          new Date(this.validStartDate),
          new Date(this.validEndDate),
        )
        this.maxDays = maxDays
        this.earliestStartDate = earliestStartDateString
        if (adjustStartDate && startDateNeedsToBeAdjusted) {
          this.timeLimitedMessage = 'Start date was adjusted to avoid exceeding filter weight limits'
          this.startDate = earliestStartDateString
        }
      }
    }

    @Watch('endDate')
    @Watch('startDate')
    @Watch('datesAreValid', { immediate: true })
    protected onDatesChanged() {
      if (this.datesAreValid) {
        this.validEndDate = this.endDate
        this.validStartDate = this.startDate
      }
    }

    @Watch('validEndDate')
    protected onEndDateChanged() {
      this.timeLimitedMessage = null

      logEvent(this.$analytics, 'troubleshooter_date_changed', {
        category: 'Timeline:endDateString',
        action: 'Click endDate in Timeline',
        label: 'Click endDate in Timeline',
        page_title: 'Oura user',
        page_location: window.location.toString().split('?')[0],
      })

      this.groupSelectionErrorMessage = null
      this.updateTimeWeightMultipler()
      this.updateMaxDays(true)
    }

    @Watch('validStartDate')
    protected onStartDateChanged() {
      this.timeLimitedMessage = null

      logEvent(this.$analytics, 'troubleshooter_date_changed', {
        category: `User:Timeline start Date`,
        action: 'Click startDate in Timeline',
        label: `Click startDate in Timeline`,
        page_title: 'Oura user',
        page_location: window.location.toString().split('?')[0],
      })

      this.groupSelectionErrorMessage = null
      this.updateTimeWeightMultipler()
      this.updateMaxDays(true)
    }

    private updateTimelineFields() {
      this.timelineFields = this.availableGraphs
      if (this.timelineFields) {
        this.comboboxFields = this.timelineFields.flat()
      }
    }

    private refreshConfigFromLocalStorage() {
      if (localStorage[this.localstorageFilterKey]) {
        const config = JSON.parse(localStorage[this.localstorageFilterKey])

        this.filters = [...(config.filters || [])]

        if (config.timeRange && !Array.isArray(config.timeRange)) {
          this.startDate = this.getDateString(Date.now(), config.timeRange || defaultTimelineStartOffset, 'start')

          const findQuickTime: any = this.quickTimeFields?.find(
            (time: any) => time.value && time.value === config.timeRange,
          )

          if (findQuickTime) {
            this.quickSelect.push(findQuickTime)
          }
        }
      }
      this.chips = this.comboboxFields.filter((field: any) => this.filters.includes(field.value))
    }

    private updateAllowedGraphs() {
      // if (this.v2) {
      const allowedGraphs: string[] = []
      for (const curGroup of this.availableGraphs) {
        for (const curGraph of curGroup) {
          if (
            curGraph.allowed &&
            curGraph.value &&
            this.filters.includes(curGraph.value) &&
            !allowedGraphs.includes(curGraph.value)
          ) {
            allowedGraphs.push(curGraph.value)
          }
        }
      }
      this.allowedGraphs = allowedGraphs
      // }
    }

    private get availableGraphs() {
      return this.genericGraphStore.availableGraphs
    }

    public get preDefinedGroups(): GraphGroups | null {
      return this.genericGraphStore?.preDefinedGroups || null
    }

    public preDefinedIsSelected(groupId: string | number): boolean {
      return this.selectedPredefinedGroups.includes(groupId.toString())
    }

    public async mounted() {
      this.endDate = this.$dayjs().format('YYYY-MM-DD')
      this.startDate = this.$dayjs().subtract(-defaultTimelineStartOffset, 'day').format('YYYY-MM-DD')

      await this.genericGraphStore.getGraphOptions({
        uuid: this.uuid,
      })

      this.updateTimelineFields()

      if (localStorage['OuraTimeLineView']) {
        this.view = parseInt(localStorage['OuraTimeLineView'])
      }

      this.setDefaultFilters()

      this.maxDays = graphMaxDays
      this.earliestStartDate = graphDefaultEarliestStartDate
    }

    public checkAccess(item: AvailableGraphs) {
      return item.allowed
    }

    public setDefaultFilters() {
      this.filters = []
      this.chips = []
      this.refreshConfigFromLocalStorage()
    }

    public getColorForItem(item: AvailableGraphs) {
      if (/*this.v2 && */ !this.checkAccess(item)) {
        return 'grey'
      }
      return this.activeChips.includes(item.name) ? this.findValue(item.name)?.color || '' : 'grey'
    }

    public removeChip(item: any) {
      const findValue = this.findValue(item)?.value
      this.filters = this.filters.filter((field: any) => field !== findValue)
      // Disabling this didn't break anything:
      // this.menuProps.disabled = this.chips.length == this.maxChipsLength
      this.chips = this.comboboxFields.filter((field: any) => this.filters.includes(field.value))
    }

    private handleSelectedGraphWithFixedFilterLimit(selected: GraphGroupItem) {
      const newFilters = this.filters.concat(selected.graphs)
      if (newFilters.length <= this.maxChipsLength) {
        this.filters = newFilters
      } else {
        this.groupSelectionErrorMessage = `Group selection failed, no more than ${this.maxChipsLength} filters can be selected`
      }
    }

    private handleSelectedGraphWithWeightLimit(selected: GraphGroupItem) {
      if (this.filterWeights) {
        const newFilters = this.filters.concat(selected.graphs)
        const newWeight = calculateTotalWeight(newFilters, this.filterWeights, this.currentTimeWeightMultipler)
        if (newWeight > this.maxTotalWeight) {
          this.groupSelectionErrorMessage = 'Group selection failed, filters weight limit would be exceed.'
          return
        }
        this.filters = this.filters.concat(selected.graphs)
      }
    }

    public selectPreDefined(groupId: string | number, selected: GraphGroupItem) {
      this.groupSelectionErrorMessage = null
      // This selection logic could be moved to helper function(s) and tested properly
      const selectedIndex = this.selectedPredefinedGroups.indexOf(groupId.toString())
      if (selectedIndex >= 0) {
        this.filters = this.filters.filter(function (el) {
          return !selected.graphs.includes(el)
        })
      } else {
        if (this.$featureEnabled('troubleshooterWeights')) {
          this.handleSelectedGraphWithWeightLimit(selected)
        } else {
          this.handleSelectedGraphWithFixedFilterLimit(selected)
        }
      }
      this.chips = this.comboboxFields.filter((field: any) => this.filters.includes(field.value))
    }

    public selectQuickTime(selected: { name: string; key: string; value: number }) {
      let filters: string[] = this.filters
      const selectExisted = this.quickSelect.some((field) => field.value === selected.value)

      if (!selectExisted) {
        this.timeLimitedMessage = null
        this.endDate = this.getDateString(Date.now(), 0, 'end')
        this.startDate = this.getDateString(Date.now(), selected.value, 'start')

        this.quickSelect = [...this.quickSelect.filter((field: any) => field.key !== selected.key), selected]
      } else {
        this.endDate = this.getDateString(Date.now(), 0, 'end')
        this.startDate = this.getDateString(Date.now(), defaultTimelineStartOffset, 'start')
      }

      if (!this.sensitiveDataVisible) {
        filters = filters.filter((field: string) => !allowSensitiveDataFields.includes(field))
      }

      this.filters = [...new Set(filters)]

      this.chips = this.comboboxFields.filter((field: any) => filters.includes(field.value))
    }

    public setView(view: number) {
      this.view = view
    }

    public saveFilters() {
      this.snackbar = true

      localStorage['OuraTimeLineView'] = this.view

      const quickTime = this.quickSelect.find((select: any) => select.key && select.key === 'time')

      localStorage[this.localstorageFilterKey] = JSON.stringify({
        timeRange: quickTime ? quickTime.value : '',
        filters: this.filters,
      })
    }

    public clearFilters() {
      this.groupSelectionErrorMessage = null
      this.selectedPredefinedGroups = []
      this.chips = []
      this.filters = []
      this.quickSelect = []
      this.menuProps.disabled = false
      this.maxDays = graphMaxDays
      this.earliestStartDate = graphDefaultEarliestStartDate

      localStorage['OuraTimeLineFilters'] = JSON.stringify({
        timeRange: '',
        filters: [],
      })
      localStorage.removeItem(this.localstorageFilterKey)
    }

    public selectFilter(item: any) {
      this.groupSelectionErrorMessage = null
      this.timeLimitedMessage = null
      const checkField = this.chips.some((field: any) => field.value === item.value)

      if (checkField) {
        this.chips = this.chips.filter((field: any) => field.value !== item.value)

        this.filters = this.filters.filter((field: any) => field !== item.value)
      } else {
        this.chips.push(item)

        this.filters.push(item.value)
        this.onFiltersChanged(this.filters, [])
      }
    }

    public toggleChip(field: AvailableGraphs) {
      if (this.filters.includes(field.value)) {
        this.filters = this.filters.filter((chip: string) => chip !== field.value)
      } else {
        if (this.canAddSingleFilter(field)) {
          this.filters.push(field.value)
          this.onFiltersChanged(this.filters, [])
        }
      }
    }

    public comboboxSelect(allSelectedGraphs: AvailableGraphs[] | any) {
      const graphToBeAdded = allSelectedGraphs[allSelectedGraphs.length - 1]

      if (this.canAddSingleFilter(graphToBeAdded)) {
        this.filters = allSelectedGraphs.map((graph: AvailableGraphs) => graph.value)
      } else {
        this.menuProps.disabled = true
      }
    }

    private findValue(name: string) {
      return this.comboboxFields.find((field: { name: string; value: string }) => field.name === name)
    }

    private updateTimeWeightMultipler() {
      if (!this.$featureEnabled('troubleshooterWeights')) {
        return
      }
      const { numberOfDays, weightTimeMultiplier } = calculateTimeWeightMultiplier(
        new Date(this.validStartDate),
        new Date(this.validEndDate),
        graphSingleDayWeight,
      )

      this.selectedDays = numberOfDays
      this.currentTimeWeightMultipler = weightTimeMultiplier
      this.updateCurrentTotalWeight()
    }
  }

  export default toNative(DataTroubleshooter)
</script>

<style lang="scss" scoped>
  :deep(.v-input--switch) {
    transform: scale(0.8);
  }

  :deep(.v-list-item) {
    min-height: 30px;
  }

  :deep(.v-list-item--disabled) {
    opacity: 0.3;
    border-bottom: 0 !important;
  }

  :deep(.v-list-subheader) {
    color: #e91e63 !important;
    font-size: 0.7rem;
  }

  :deep(.v-selection-control .v-label) {
    width: auto;
  }

  .left-menu {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    padding-right: 20px;
  }

  .selected-group {
    color: #e91e63;
    font-size: 17px;
  }

  .tooltip {
    font-size: small;
    max-width: 30rem;
  }
</style>
