<template>
  <div class="text-mono">
    <pre v-if="!data" />
    <pre v-else-if="dataAsString.length > 5_000_000">{{ dataAsString }}</pre>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <pre v-else-if="dataAsString.length > 1_000_000" v-html="dataWithSyntaxHighlight" />
    <JSONItem v-else :data="{ value: data }" />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  @Component
  class JSONView extends Vue {
    @Prop({ default: {} }) public data!: any

    public get dataAsString(): string {
      return JSON.stringify(this.data, null, 2).trim()
    }

    public get dataWithSyntaxHighlight() {
      let json = this.dataAsString
      json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
      return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
        (match) => {
          let elementType = 'number'
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              elementType = 'key'
            } else {
              elementType = 'string'
            }
          } else if (/true|false/.test(match)) {
            elementType = 'boolean'
          } else if (/null/.test(match)) {
            elementType = 'null'
          }
          const elementClass = elementType === 'key' ? '' : 'text-primary'
          return '<span class="' + elementClass + '">' + match + '</span>'
        },
      )
    }
  }

  export default toNative(JSONView)
</script>
