/**
 * Validate ring serial number.
 *
 * For now we just check the length.
 *
 * @param  serial   Ring serial number
 * @return boolean
 */
function isRingSerialValid(serial: string): boolean {
  return serial.length >= 3 && serial.length <= 16
}

/**
 * Validate ring serial number. If invalid, throw Error
 **
 * @param  serial   Ring serial number
 * @throws Error    If serial is not valid
 */
export function validateRingSerial(serial: string) {
  if (!isRingSerialValid(serial)) {
    throw new Error('Invalid ring serial number: ' + serial)
  }
}
