import { DataWaits } from '#types'

/**
 * Gives yes/no answer to question "are we still waiting for some data?"
 *
 * @param dataWaits      Store's dataWaits
 * @param requestSources Limit which request sources to check. Defaults to all.
 */
export function waitingForData(dataWaits: DataWaits, requestSources: string[] = []): boolean {
  if (requestSources.length == 0) {
    return !Object.values(dataWaits).every((v) => !v)
  } else {
    for (const key of requestSources) {
      if (key in dataWaits && dataWaits[key]) {
        return true
      }
    }
  }
  return false
}
