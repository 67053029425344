<template>
  <div>
    <div
      v-if="!accountInfo"
      class="d-flex ml-1 flex-no-wrap flex-column flex-sm-row align-center text-overline"
      style="word-break: break-all"
    >
      <v-icon
        class="mr-2"
        color="pink"
        :icon="member && member.emailVerified ? 'mdi-email-check-outline' : 'mdi-alert-decagram-outline'"
      />

      <div class="text-wrap mr-3">
        {{
          (member && member.email) ||
          (rights.includes('allowPersonalDataAccess')
            ? 'No email set for the account'
            : 'No permission to see the email')
        }}
      </div>

      <div v-if="member && member.email" class="caption text-no-wrap">
        ({{
          member.emails && uniqueEmailAddresses === 1
            ? 'Only registered email'
            : member.emails && uniqueEmailAddresses > 1
              ? `${uniqueEmailAddresses - 1} older email${uniqueEmailAddresses > 2 ? 's' : ''}`
              : ''
        }})
      </div>
    </div>

    <v-card v-else class="mb-8">
      <v-card-title>
        <p class="text-overline">Account info</p>
      </v-card-title>
      <v-card-text class="mt-n4">
        <v-list v-if="accountInfo">
          <p class="text-subtitle-1 ml-4 mb-0">Emails</p>
          <v-list-item v-for="(item, emailIndex) in member.emails" :key="`email${emailIndex}`">
            <v-list-item-title ref="emails">
              {{ item.email || 'Email address deleted' }}

              <span v-if="item.email && item.currentEmail && !item.deleted" class="mr-3">(current)</span>
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-icon
                    v-bind="props"
                    icon="mdi-content-copy"
                    color="grey"
                    @click="useClipboard.copy(item.email || '')"
                  />
                </template>
                {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
              </v-tooltip>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ emailStatusMessage(item) }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-divider v-if="member.events.length > 0" class="mt-4 pt-4" />
          <div v-if="member.events.length > 0">
            <p class="text-subtitle-1 ml-4 mb-2">Events</p>
            <v-list-item v-for="(item, eventIndex) in member.events" :key="`event${eventIndex}`">
              <div>{{ eventMessage(item) }}</div>
            </v-list-item>
          </div>
        </v-list>
        <p class="text-subtitle-1 mt-2 ml-4 mb-0">
          Country of Residence:
          <span :class="countryOfResidence.class">{{ countryOfResidence.text }}</span>
        </p>
        <v-divider v-if="member.isVerificationBypassed" class="mb-2 pb-2" />
        <div v-if="member.isVerificationBypassed" class="d-flex align-end pa-4" align="center">
          <v-icon color="blue" icon="mdi-alert" />
          <p class="mb-0 ml-3">Email verification bypassing is on for this account</p>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
  import { uniqBy } from 'lodash-es'

  import { useClipboard } from '@vueuse/core'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { findCountryByIsoAlpha2Code } from '#utils/country/list'

  import { AppStore, UserStore } from '#stores'

  import { Member, MemberEmail, MemberEvent } from '#types'

  @Component
  class AccountEmails extends mixins(DateTime) {
    @Prop() public member!: Member
    @Prop() public accountInfo!: boolean

    public uniqueEmailAddresses = 0

    public useClipboard = useClipboard()

    protected readonly appStore = new AppStore()
    protected readonly userStore = new UserStore()

    public get rights() {
      return this.appStore.rights
    }

    public get countryOfResidence(): { text: string; class: string } {
      if (!this.userStore.sensitiveDataVisible) {
        return { text: 'enable sensitive data to view', class: 'text-grey' }
      }
      const countryName = findCountryByIsoAlpha2Code(this.member.countryOfResidence ?? '')?.standardCountryName

      if (!countryName) {
        return { text: 'unknown', class: 'text-grey' }
      }

      return { text: countryName, class: '' }
    }

    @Watch('member')
    protected onMemberChanged() {
      this.countUniqueEmailAddresses()
    }

    public mounted() {
      this.countUniqueEmailAddresses()
    }

    public emailStatusMessage(email: MemberEmail): string {
      return (
        (email.createdAt
          ? 'Created at ' + this.formatDateTime(email.createdAt, 'HH:mm Do MMM YYYY')
          : 'No creation date found') +
        (email.deleted
          ? ' - Removed at ' + this.formatDateTime(email.deletedAt, 'HH:mm Do MMM YYYY')
          : email.verifiedAt
            ? ' - Verified at ' + this.formatDateTime(email.verifiedAt, 'HH:mm Do MMM YYYY')
            : email.verified
              ? ' - Verified'
              : email.verificationSent
                ? ' - Verification sent ' + this.formatDateTime(email.verificationSent, 'HH:mm Do MMM YYYY')
                : ' - Unverified')
      )
    }

    private passwordResetSourceMessage(event: MemberEvent): string {
      const baseMessage = 'Password reset requested through '
      switch (event.eventType) {
        case 'fwpwd_web':
          return baseMessage + 'web'
        case 'fwpwd_mobile':
          return baseMessage + 'mobile'
        default:
          return baseMessage + 'chat'
      }
    }

    public eventMessage(event: MemberEvent): string {
      let messages: string[] = []
      switch (event.eventType) {
        case 'fwpwd_web':
        case 'fwpwd_mobile':
          messages.push(
            this.passwordResetSourceMessage(event) +
              ' at ' +
              this.formatDateTime(event.occurredAt, 'HH:mm Do MMM YYYY'),
          )
          break
        case 'pwreset':
          messages.push('Password changed at ' + this.formatDateTime(event.occurredAt, 'HH:mm Do MMM YYYY'))
          break
      }
      return messages.join('. ')
    }

    private countUniqueEmailAddresses() {
      if (this.member?.emails) {
        this.uniqueEmailAddresses = uniqBy(this.member.emails, 'email').length
      } else {
        this.uniqueEmailAddresses = 0
      }
    }
  }

  export default toNative(AccountEmails)
</script>

<style lang="scss" scoped>
  .v-list-item--density-default.v-list-item--one-line {
    min-height: 33px;
  }
</style>
