import { AxiosError, AxiosResponse } from 'axios'

import { Store } from 'pinia-class-component'

import { extractRingDetailsFromRingDetailsResponse } from '#utils/ring/extract'
import { validateRingSerial } from '#utils/ring/validate'

import { BaseStore } from '#stores/base'

import {
  ConnectedAccount,
  MarkReturnedRequest,
  MarkReturnedResponse,
  Ring,
  RingDetails,
  RingDetailsResponse,
  RingSearchRequest,
  RingSearchResponse,
  RingSearchResponseSingleRing,
  WarrantyData,
} from '#types'

@Store()
export class RingsStore extends BaseStore {
  public dataWait = false

  public page: any = {
    cur: 1,
    prev: '',
    next: '',
  }

  public ring: RingDetails | null = null

  public activeRing: Ring | null = null

  public errorMarkReturned: string | null = null

  public ringSearchResult: RingSearchResponseSingleRing[] = []

  public connectedAccounts: ConnectedAccount[] | null = null

  public warrantyData: WarrantyData | null = null
  public warrantyDataRingReplacedBy: WarrantyData | null = null
  public warrantyDataRingReplacementFor: WarrantyData | null = null

  public async getRingDetails(serial: string) {
    this.ring = null
    this.dataWait = true
    const serialParsed = serial.split(',')[0]
    validateRingSerial(serialParsed)

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/rings/${serialParsed}/details` },
      'getRingDetails',
    )
      .then((value: AxiosResponse | null) => {
        return value
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    const ringDetailsResponse: RingDetailsResponse | undefined = response?.data
    if (ringDetailsResponse) {
      this.ring = extractRingDetailsFromRingDetailsResponse(ringDetailsResponse)
    }
    this.dataWait = false
  }

  public async getWarrantyData(params: { serial: string; initial: boolean; fetchLinkedWarrantyData?: boolean }) {
    const serial = params.serial
    const fetchLinkedWarrantyData = params.fetchLinkedWarrantyData ?? true

    if (params.initial) {
      this.dataWait = true
      this.warrantyData = null
    }

    const serialParsed = serial.split(',')[0]
    validateRingSerial(serialParsed)

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/rings/${serialParsed}/warranty-details` },
      'getWarrantyData',
    )
      .then((value: AxiosResponse | null) => {
        return value
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    if (response?.data) {
      if (params.initial) {
        this.warrantyData = response?.data
      }
      if (fetchLinkedWarrantyData) {
        const replacesSerial = response?.data?.ringReturn?.replaces
        const replacedBySerial = response?.data?.ringReturn?.replacedBy
        if (replacesSerial) {
          const linkedResponse = await this.getWarrantyData({
            serial: replacesSerial,
            initial: false,
            fetchLinkedWarrantyData: false,
          })
          this.warrantyDataRingReplacementFor = linkedResponse?.data ?? null
        }
        if (replacedBySerial) {
          const linkedResponse = await this.getWarrantyData({
            serial: replacedBySerial,
            initial: false,
            fetchLinkedWarrantyData: false,
          })
          this.warrantyDataRingReplacedBy = linkedResponse?.data ?? null
        }
      }
    }
    if (params.initial) {
      this.dataWait = false
    }
    return response
  }

  public async getConnectedAccounts(serial: string) {
    this.connectedAccounts = null
    this.dataWait = true
    const serialParsed = serial.split(',')[0]
    validateRingSerial(serialParsed)

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/rings/${serialParsed}/connected-accounts` },
      'getConnectedAccounts',
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    if (response?.data?.accounts) {
      this.connectedAccounts = response?.data?.accounts
    }
    this.dataWait = false
  }

  public async markDeviceReturned(params: { request: MarkReturnedRequest }): Promise<boolean> {
    this.errorMarkReturned = null

    const response = await this.makeRequest(
      { method: 'post', url: '/api/v1/rings/mark-returned', data: params.request },
      'markDeviceReturned',
    ).catch((_axiosError: AxiosError) => {
      const device = params.request.components.reduce(
        (acc, cur, index) => acc + cur.type + `${index < params.request.components.length - 1 ? ', ' : ''}`,
        '',
      )
      this.errorMarkReturned = `Unable to mark ${device} as returned.`
      return null
    })

    if (response && response?.status === 200 && response?.data) {
      const res: MarkReturnedResponse = response.data
      if (res.responseFromNetsuite?.error) {
        this.errorMarkReturned = res.responseFromNetsuite.error
      }
    }
    return !!response
  }

  public async search(search: RingSearchRequest) {
    this.dataWait = true

    this.ringSearchResult = []

    const response = await this.makeRequest(
      { method: 'get', url: '/api/v1/rings/search', params: search },
      'search',
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    if (response?.status == 200 && response?.data) {
      const searchResponse: RingSearchResponse = response.data
      this.ringSearchResult = searchResponse?.contents
    }

    this.dataWait = false
  }

  public async setActiveRing(ring: Ring | null) {
    this.activeRing = ring
  }
}
