<template>
  <v-container>
    <v-tooltip location="bottom">
      <template #activator="{ props }">
        <v-btn variant="plain" icon grey class="mt-n9" v-bind="props" @click.stop="settingsPage = true">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>
      <span>Sidecar settings</span>
    </v-tooltip>
    <v-dialog
      :model-value="settingsPage"
      width="300px"
      @click:outside="settingsPage = false"
      @keydown:esc="settingsPage = false"
    >
      <v-card>
        <div class="pa-4">
          <div class="text-overline">Sidecar height</div>
          <v-layout>
            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-btn variant="outlined" color="black" class="mw-1" v-bind="props" @click="adjustWindowSize(-50)">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </template>
              <span>Decrease window height</span>
            </v-tooltip>
            <span class="d-flex align-center justify-center border border-dark px-5">
              {{ pageHeight }}
            </span>
            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-btn variant="outlined" color="black" v-bind="props" @click="adjustWindowSize(50)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Increase window height</span>
            </v-tooltip>
          </v-layout>
        </div>
        <div class="pa-4">
          <div class="text-overline">User settings</div>
          <v-sheet class="px-3">
            <v-switch
              class="reversed mt-2 mb-2"
              color="primary"
              :model-value="toggleMacro"
              inset
              label="Macro suggestion notification"
              @change="toggleMacroSuggestion()"
            />
          </v-sheet>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  @Component
  class SidecarSettings extends Vue {
    public size: number = 0
    public settingsPage = false
    public pageHeight: number = 800
    public toggleMacro = false

    public darwinUrl = import.meta.env.VITE_DARWIN_URL

    public get toggleMacroNotification(): boolean {
      return localStorage.getItem('toggleMacroNotification') == 'true'
    }

    public mounted() {
      if (this.$zafClient) {
        this.pageHeight = localStorage.getItem('sidecarHeightSetting')
          ? Number(localStorage.getItem('sidecarHeightSetting'))
          : this.pageHeight
        this.adjustWindowSize()
        this.toggleMacro = this.toggleMacroNotification
      }
    }

    public adjustWindowSize(size: number = 0) {
      this.pageHeight = this.pageHeight + size
      if (this.pageHeight < 300) {
        this.$zafClient.invoke('notify', ' Cannot resize any smaller!', 'error', 9000)
        this.pageHeight = 300
      }
      if (this.$zafClient) {
        this.$zafClient.invoke('resize', { width: '100%', height: this.pageHeight + 'px' })
        localStorage.setItem('sidecarHeightSetting', this.pageHeight.toString())
      }
    }

    public toggleMacroSuggestion() {
      this.toggleMacro = !this.toggleMacro
      localStorage.setItem('toggleMacroNotification', this.toggleMacro.toString())
    }
  }

  export default toNative(SidecarSettings)
</script>
<style lang="scss" scoped>
  :deep(.v-overlay__content) {
    top: 0px;
  }
</style>
