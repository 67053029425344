import { PlotlyFigure } from '#types'

export const EMPTY_GRAPH: PlotlyFigure = {
  data: [],
  layout: {
    annotations: [
      {
        text: 'No data found',
        xref: 'paper',
        yref: 'paper',
        showarrow: false,
        font: {
          size: 26,
        },
      },
    ],
  },
}

/**
 * Combines multiple plotly graphs to single one
 * @param plotData object with multiple graph's data
 * @param axisData object for mapping graph to correct axis
 * @param startDateString
 * @param endDateString
 * @param graphMode
 */
export function combinePlotData(
  plotData: any,
  axisData: any,
  startDateString: string,
  endDateString: string,
  graphMode: string,
): { layout: any; data: any[] } {
  const data: any = {}
  let combinedPlotData: { layout: any; data: any[] } = { layout: {}, data: [] }
  const annotations: any[] = []
  const availableAxisMap: any = {}
  const addedLegendGroups: string[] = []
  const addedAxes: string[] = []
  let yAxisNumber = 0

  for (const key in plotData) {
    if (!plotData[key]?.length) {
      continue
    }

    data[key] = plotData[key].reduce((a: any, b: any) => {
      // Save annotations to a list
      if (b.layout.annotations) {
        annotations.push(...b.layout.annotations)
      }

      // Skip if is undefined
      if (!a) {
        return b
      }

      // Check that both have data
      if (a.data.length == 0 || !a.data[0].x) {
        return b
      } else if (b.data.length == 0 || !b.data[0].x) {
        return a
      }

      // Combine data
      if (a.data[0].mode === 'lines') {
        a.data[0].x.push(null)
      }

      a.data = [...a.data, ...b.data]

      if (a.data[0].mode === 'lines') {
        a.data[0].x.push(null)
      }

      // Remove default y-axes
      if (a?.layout?.yaxis) {
        delete a.layout.yaxis
      }
      if (b?.layout?.yaxis) {
        delete b.layout.yaxis
      }

      return a
    }, null)

    // Store the used axes to availableAxisMap
    if (data[key].data.length) {
      if (!availableAxisMap[axisData[key]['name']]) {
        if (yAxisNumber > 0) {
          availableAxisMap[axisData[key]['name']] = 'y' + (yAxisNumber + 1)
        } else {
          // Set the first added axis to be the default one.
          availableAxisMap[axisData[key]['name']] = 'default'
        }
      }

      // Add yaxis to the layout if not already added
      if (!addedAxes.includes(axisData[key]['name'])) {
        const yAxisKey = yAxisNumber == 0 ? 'yaxis' : 'yaxis' + (yAxisNumber + 1)
        combinedPlotData.layout[yAxisKey] = { ...axisData['common'], ...axisData[key]['axis'] }
        if (yAxisNumber % 2 == 0) {
          combinedPlotData.layout[yAxisKey]['side'] = 'left'
        } else {
          combinedPlotData.layout[yAxisKey]['side'] = 'right'
        }
        if (yAxisNumber > 0) {
          combinedPlotData.layout[yAxisKey]['overlaying'] = 'y'
        }

        if (yAxisNumber > 1) {
          // Set the position of the y-axis when there's more than 2 y-axes
          combinedPlotData.layout[yAxisKey]['position'] = yAxisNumber / 10 + 1
        }

        addedAxes.push(axisData[key]['name'])
        yAxisNumber++
      }

      for (const trace in data[key].data) {
        // --- LEGENDS AND LEGEND GROUPS ---
        // Remove legend from all traces except the first one with given name
        if (addedLegendGroups.includes(data[key].data[trace].name)) {
          data[key].data[trace].showlegend = false
        } else {
          addedLegendGroups.push(data[key].data[trace].name)
        }

        // Add legendgroup to all traces, so they can be controlled from a single legend
        data[key].data[trace].legendgroup = data[key].data[trace].name

        // --- PERFORMANCE ---
        // Change scatter to scattergl to improve performance when graphMode is WebGL
        if (graphMode == 'WebGL' && data[key].data[trace].type == 'scatter') {
          data[key].data[trace].type = 'scattergl'
        }

        // --- AXES ---
        if (availableAxisMap[axisData[key]['name']]) {
          data[key].data[trace].yaxis = availableAxisMap[axisData[key]['name']]
        }
      }

      const hoverModes = ['hoverclosest', 'hovercompare', 'togglehover', 'togglespikelines']
      combinedPlotData.layout['modebar'] = { add: hoverModes, activecolor: 'red' }

      // Combine data and layout
      combinedPlotData = {
        layout: { ...data[key].layout, ...combinedPlotData.layout },
        data: [...combinedPlotData.data, ...data[key].data],
      }

      // Set bar offset groups to avoid overlapping
      let barOffsetGroup = 1
      for (const val of combinedPlotData.data) {
        if (val.type && val.type == 'bar') {
          val['offsetgroup'] = barOffsetGroup
          barOffsetGroup++
        }
      }

      //setting spikes to false on creation, only needed for xaxis
      combinedPlotData.layout.xaxis.showspikes = false
      combinedPlotData.layout.annotations = annotations

      // Set fixed x-axis to always show the selected date-range
      const startDate = new Date(startDateString).setHours(0, 0, 0)
      const endDate = new Date(endDateString).setHours(23, 59, 59)
      combinedPlotData['layout']['xaxis']['autorange'] = false
      combinedPlotData['layout']['xaxis']['range'] = [startDate, endDate]
    }
  }

  // Return empty graph with a message in case there's no data
  if (combinedPlotData.data.length == 0) {
    return EMPTY_GRAPH
  }

  return combinedPlotData
}

/**
 * Get the corresponding y-axis for each graph
 * @param graphOptions
 * @param graphs
 */
export function getAxisData(graphOptions: { categories: any; yaxes: any }, graphs: string[]) {
  const graphOptionsFlattened: any = {}

  Object.keys(graphOptions.categories).forEach((key) => {
    const value = graphOptions.categories[key]
    Object.assign(graphOptionsFlattened, value)
  })

  const axisOptions: any = { common: {} }

  for (const graph of graphs) {
    const axis = graphOptionsFlattened[graph]?.yaxis
    axisOptions[graph] = {
      axis: graphOptions.yaxes[axis],
      name: axis,
    }
  }
  // Add common graph data
  if (Object.keys(graphOptions.yaxes).includes('common')) {
    axisOptions.common = graphOptions.yaxes.common
  }
  return axisOptions
}
