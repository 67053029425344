import { appSearchMaxLength, appSearchMinLength } from '#views/apps/constants'

export function validateAppSearch(search: string): { isValid: boolean; errors: string[] } {
  const errors: string[] = []
  let isValid = true

  // safe search characters
  const searchRegex = new RegExp("^[a-zA-Z0-9.+_'()@\\- ]+$")

  if (!searchRegex.test(search)) {
    isValid = false
    errors.push('Search contains invalid characters')
  }

  // search length too short
  if (search.length > 0 && search.length < appSearchMinLength) {
    isValid = false
    errors.push(`Search must be at least ${appSearchMinLength} characters`)
  }

  // search length too long
  if (search.length > appSearchMaxLength) {
    isValid = false
    errors.push(`Search must be at most ${appSearchMaxLength} characters`)
  }
  return { isValid, errors }
}
