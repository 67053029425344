import { UserStore } from './user'

import { AxiosError, AxiosResponse } from 'axios'

import { Store } from 'pinia-class-component'

import { extractAssaModels } from '#utils/data-browser/extract'

import { BaseStore } from '#stores/base'

import { AssaModelsResponse } from '#types'

@Store()
export class DataBrowserStore extends BaseStore {
  public dataWait = false

  public assaDataError = ''

  public assaData: any = {}
  public assaModels: string[] = []

  public async getModels() {
    this.dataWait = true
    const response = await this.makeRequest({ method: 'get', url: `/api/v1/assa/models` }, 'getAssaModels')
      .then((value: AxiosResponse | null) => {
        return value
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    const assaModelsResponse: AssaModelsResponse | undefined = response?.data
    if (assaModelsResponse) {
      this.assaModels = extractAssaModels(assaModelsResponse)
    }
    this.dataWait = false
  }

  public async getData(model: string, params: { userUuids: string; timestampFrom: string; timestampTo: string }) {
    this.dataWait = true
    this.assaData = {}
    this.assaDataError = ''
    const userStore = new UserStore()

    params.timestampFrom = params.timestampFrom + 'T00:00:00'
    params.timestampTo = params.timestampTo + 'T23:59:59'
    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/assa/models/${model}`, params },
      'getAssaDataBrowserData',
      params['userUuids'],
      userStore.user?.uuid,
    )
      .then((value: AxiosResponse | null) => {
        return value
      })
      .catch((axiosError: AxiosError) => {
        this.assaDataError = axiosError.message
        return null
      })

    this.assaData = response?.data || {}
    this.dataWait = false
  }
}
