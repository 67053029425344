<template>
  <v-card>
    <v-card-title>
      <p class="text-overline">Contact details</p>
    </v-card-title>
    <v-row v-if="contactDetailsError">
      <v-alert tile type="error" class="mt-2 mb-4">
        {{ contactDetailsError.userMessage }}
      </v-alert>
    </v-row>
    <div v-if="!showContacts || contactDetailsError" class="d-flex align-center justify-center">
      <v-tooltip bottom :disabled="canSeeContacts">
        <template #activator="{ props }">
          <div v-bind="props">
            <v-btn
              class="mt-2"
              variant="outlined"
              color="primary"
              size="small"
              :disabled="!canSeeContacts"
              @click="toggleContacts()"
            >
              View contact details
            </v-btn>
          </div>
        </template>
        <span>This action requires personal data rights</span>
      </v-tooltip>
    </div>
    <v-card-text>
      <v-row v-if="showContacts && canSeeContacts">
        <v-col v-for="contactType in ['billToContact', 'shipToContact']" :key="contactType" cols="6">
          <v-skeleton-loader v-if="dataWait" class="mt-n6" :min-height="180" type="article" />
          <ContactDetails
            v-else
            :contact-type="contactType"
            :contact-details="contactDetails[contactType]"
            :member="member"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { AppStore, SubscriptionStore } from '#stores'

  @Component
  class AddressInformation extends Vue {
    @Prop() public member!: any

    public subscriptionStore = new SubscriptionStore()
    public appStore = new AppStore()

    public showContacts = false

    public get dataWait(): boolean {
      return this.subscriptionStore.waitingForGetContacts()
    }

    public get rights() {
      return this.appStore.activeRights || []
    }

    public get canSeeContacts(): boolean {
      return this.rights.includes('allowPersonalDataAccess')
    }

    public get contactDetailsError() {
      return this.subscriptionStore.contactDetailsError
    }

    public get contactDetails() {
      return this.subscriptionStore.contactDetails as any
    }

    public toggleContacts() {
      this.showContacts = !this.showContacts
      if (this.showContacts) {
        this.subscriptionStore.getContactDetails({ userId: this.member?.uuid }).then(() => {
          if (this.contactDetailsError) {
            this.showContacts = false
          }
        })
      }
    }
  }

  export default toNative(AddressInformation)
</script>
<style lang="css" scoped>
  :deep(.v-data-table-header.v-data-table-header-mobile) {
    display: none;
  }
</style>
