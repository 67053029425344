<template>
  <v-dialog v-if="logs" :model-value="logsDialog" scrollable width="1000" @click:outside="emitClose()">
    <v-card>
      <v-card-title class="headline">Error Logs</v-card-title>

      <v-card-text class="pa-0">
        <v-list class="pa-0" style="height: 80vh">
          <v-list-item
            v-for="(event, index) in logs"
            :key="index"
            class="fill-width"
            style="border-top: 1px solid #cfcfcf"
          >
            <div style="min-width: 200px">
              <span v-if="event && event.timestamp">
                {{ $dayjs(event.timestamp).format('DD.MM.YYYY - HH:mm:ss.SSS') }}
              </span>
            </div>
            <div>{{ event.message }}</div>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions class="px-4">
        <v-spacer />
        <v-btn variant="text" class="mr-2" color="red" @click="emitClose()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { TasksStore } from '#stores'

  @Component
  class TaskErrorLogs extends Vue {
    @Prop() public logsDialog!: boolean

    public tasksStore = new TasksStore()

    public headers = [
      {
        text: 'Timestamp',
        value: 'timestamp',
      },
      {
        text: 'Message',
        value: 'message',
      },
    ]

    @Emit('close')
    public emitClose() {
      return
    }

    public get logs() {
      return this.tasksStore.logs.logs
    }

    public get more() {
      return this.tasksStore.logs.more
    }
  }

  export default toNative(TaskErrorLogs)
</script>
