<template>
  <div v-if="member && activeRing" class="pa-4">
    <v-row align="center">
      <v-col class="d-flex" cols="12" sm="6">
        <v-select
          v-model="activeRing.macAddress"
          :items="ringsForSelect"
          item-title="text"
          label="Choose a ring"
          @update:model-value="setActiveRingByMac($event)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { getDeviceNameByType } from '@jouzen/outo-toolkit-vuetify'

  import { RingsStore } from '#stores'

  import { Member, Ring, VuetifySelectItem } from '#types'

  /**
   * RingSelector is used to choose member's active ring for Darwin UI. Active ring is stored to rings-store via
   * rings/setActiveRing action. This should be the source of truth in the whole app about which member's ring
   * is currently active and be always read via rings/activeRing getter. Active ring is only about which ring
   * details we show on Darwin's UI, and should not be confused with member's latest/current ring they are using.
   * NOTE! Currently rings-store activeRing information is only being used on sidecar.
   *
   * TODO:
   * - Move ring selector from Info.vue to here. Separate components for dropdown & visual ring & future selectors?
   * - Start using rings-store activeRing everywhere (UserView etc). Now it's only used on SidecarView.vue
   */

  @Component({})
  class RingSelector extends Vue {
    @Prop() public member!: Member

    public ringsStore = new RingsStore()

    public get activeRing(): Ring | null {
      return this.ringsStore.activeRing
    }

    @Watch('member')
    protected onMemberChange(_val: any, _oldVal: any) {
      this.setNewestRingAsActive()
    }

    public mounted() {
      this.setNewestRingAsActive()
    }

    /**
     * Human readable title for single select item (ring)
     *
     * @param ring       Ring
     * @param ringIndex  Ring index
     */
    public selectItemTitle(ring: Ring, ringIndex: number) {
      let ringCurrentText = ''
      // First item on the ring list is always the current ring
      if (ringIndex == 0) {
        ringCurrentText = ' (current)'
      }
      const marketingName = getDeviceNameByType(ring.ringSerialInfo?.generation ?? 'unknown')
      return marketingName + ' / ' + ring.serialNumber + '' + ringCurrentText
    }

    /**
     * Return list of member rings to be used on select (dropdown) UI component
     */
    public get ringsForSelect(): VuetifySelectItem[] {
      const ringsForSelection: VuetifySelectItem[] = []
      for (let i = 0; i < this.member.rings.length; i += 1) {
        const ring = this.member.rings[i]
        ringsForSelection.push({ text: this.selectItemTitle(ring, i), value: ring.macAddress, disabled: false })
      }
      return ringsForSelection
    }

    /**
     * Set active ring by its MAC address
     *
     * @param macAddress  Ring MAC address
     */
    public setActiveRingByMac(macAddress: string) {
      const chosenRing = this.member.rings.find((r) => r.macAddress == macAddress)
      if (chosenRing) {
        this.ringsStore.setActiveRing({ ...chosenRing })
      }
    }

    /**
     * Set member's newest ring as active ring
     *
     * @private
     */
    private setNewestRingAsActive() {
      if (this.member?.rings && this.member.rings[0]) {
        this.ringsStore.setActiveRing({ ...this.member.rings[0] })
      }
    }
  }

  export default toNative(RingSelector)
</script>
