<template>
  <div v-if="!reproductiveHormoneAnswerPermission">
    <v-alert colored-border type="info" class="mb-8">
      <p>You do not have access rights to view sensitive health data.</p>
    </v-alert>
  </div>
  <div v-else>
    <v-card>
      <v-card-title>
        <p class="text-overline">Reproductive Hormone Answers</p>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :items="disabled ? [] : reproductiveHormoneAnswer ? [reproductiveHormoneAnswer] : []"
          :headers="headers"
          :no-data-text="disabled ? 'Consent has not been confirmed' : 'No answers available'"
          hide-default-footer
          hide-default-page-select
        >
          <template #item="{ item }">
            <tr>
              <td>{{ item.day }}</td>
              <td>
                <v-icon :class="getIconInfo(item.fertilityTreatments).class">
                  {{ getIconInfo(item.fertilityTreatments).icon }}
                </v-icon>
              </td>
              <td>{{ capitalize(item.hormonalContraception) }}</td>
              <td>
                <v-icon :class="getIconInfo(item.hormoneReplacementTherapy).class">
                  {{ getIconInfo(item.hormoneReplacementTherapy).icon }}
                </v-icon>
              </td>
              <td>
                <v-icon :class="getIconInfo(item.other).class">
                  {{ getIconInfo(item.other).icon }}
                </v-icon>
              </td>
              <td>
                <v-icon :class="getIconInfo(item.preferNotToAnswer).class">
                  {{ getIconInfo(item.preferNotToAnswer).icon }}
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { reproductiveHormoneAnswerHeaders } from '#views/members/constants'

  import { getIconAndClass } from '#utils/user/cycleInsights'

  import { AppStore, UserStore } from '#stores'

  import { ReproductiveHormoneAnswer } from '#types'

  @Component
  class CycleQAInsights extends mixins(DateTime) {
    @Prop() public user!: any
    @Prop() public ring!: any

    @Prop() public disabled!: boolean

    protected readonly appStore = new AppStore()
    protected readonly userStore = new UserStore()

    public headers = reproductiveHormoneAnswerHeaders

    public get reproductiveHormoneAnswer(): ReproductiveHormoneAnswer | null {
      return this.userStore.reproductiveHormoneAnswer || null
    }

    public get rights() {
      return this.appStore.activeRights
    }

    public get access() {
      return this.userStore.user?.ownerAccess || this.userStore.user?.sharedAccess || this.userStore.user?.supportAccess
    }

    public get sensitiveDataVisible() {
      return this.userStore.sensitiveDataVisible
    }

    public get reproductiveHormoneAnswerPermission() {
      return !!(this.rights.includes('allowSensitiveDataAccess') || this.access) && this.sensitiveDataVisible
    }

    public mounted() {
      if (this.reproductiveHormoneAnswerPermission) {
        this.userStore.getReproductiveHormoneAnswer(this.user.uuid)
      }
    }

    public getIconInfo(value: boolean | null): { icon: string; class: string } {
      return getIconAndClass(value)
    }

    public capitalize(value: string | null): string {
      if (value) {
        return value.charAt(0).toUpperCase() + value.slice(1)
      }
      return 'Not answered'
    }
  }

  export default toNative(CycleQAInsights)
</script>
