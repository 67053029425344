import { AxiosError, AxiosResponse } from 'axios'

import { Store } from 'pinia-class-component'

import { BaseStore } from '#stores/base'

@Store()
export class AdminStore extends BaseStore {
  public dataWait = false

  public accounts: any[] = []

  public labels: { name: string; createdAt: string; createdBy: string }[] = []

  public async listSharedAccounts() {
    const urlRegex = /(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&/=]+)?/
    const regex = new RegExp(urlRegex)
    this.dataWait = true

    const response = await this.makeRequest(
      { method: 'get', url: '/api/v1/admin/shared-account' },
      'listSharedAccounts',
    ).catch((_axiosError: AxiosError) => {
      // ignore for now
      return null
    })

    this.accounts = response?.data?.contents
    if (this.accounts) {
      for (const i of this.accounts) {
        const res = i.consentInfo?.match(regex)
        if (res) {
          i.url = 'https://' + res[0]
        } else {
          i.url = null
        }
      }
    }

    this.dataWait = false
  }

  public async adminLabels() {
    this.dataWait = true

    const labels = await this.makeRequest(
      {
        method: 'get',
        url: '/api/v1/admin/labels',
      },
      'adminLabels',
    )
      .then((resp: AxiosResponse | null) => {
        return resp?.data?.labels
      })
      .catch((_axiosError: AxiosError) => {
        //ignore for now
        return null
      })
    if (labels) {
      for (const i of labels) {
        if (i.name.includes('research:id')) {
          this.labels.push(i)
        }
      }
    }
    this.dataWait = false
  }

  public async addSharedAccount(data: any): Promise<number | undefined> {
    const payload = {
      email: data.addEmail,
      consentInfo: data.addConsent,
    }
    this.dataWait = true

    const response = await this.makeRequest(
      { method: 'post', url: '/api/v1/admin/shared-account', data: payload },
      'addSharedAccount',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.dataWait = false
    await this.listSharedAccounts()
    return response?.status
  }

  public async updateSharedAccount(data: any): Promise<number | undefined> {
    this.dataWait = true
    // Request data should be typed
    const payload = {
      type: data.type,
      email: data.email,
      consentInfo: data.consentInfo,
    }

    const response = await this.makeRequest(
      { method: 'put', url: '/api/v1/admin/shared-account', data: payload },
      'updateSharedAccount',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })

    this.dataWait = false
    await this.listSharedAccounts()
    return response?.status
  }

  public async removeSharedAccount(email: string): Promise<number | undefined> {
    this.dataWait = true
    const payload = {
      email: email,
    }
    const response = await this.makeRequest(
      { method: 'delete', url: '/api/v1/admin/shared-account', data: payload },
      'removeSharedAccount',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })

    this.dataWait = false
    await this.listSharedAccounts()
    return response?.status
  }
}
