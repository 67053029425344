<template>
  <v-card class="pa-4" style="overflow: hidden; width: 100%">
    <v-card-title class="pa-0">
      <div class="text-overline">Ring lifetime</div>
      <v-spacer />
      <div ref="menu" class="menu" />
    </v-card-title>
    <div ref="chart" class="view mt-n5" />
  </v-card>
</template>

<script lang="ts">
  import * as am4plugins_regression from '@amcharts/amcharts4/plugins/regression'

  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import * as am4core from '@amcharts/amcharts4/core'

  import * as am4charts from '@amcharts/amcharts4/charts'

  import am4themes_animated from '@amcharts/amcharts4/themes/animated'

  @Component
  class RingLifetime extends Vue {
    @Prop() public data!: any
    @Prop() public ring!: any

    public declare $refs: {
      menu: HTMLElement
      chart: HTMLElement
    }

    private chart: any

    @Watch('data')
    protected onDataChanged(_val: string, _oldVal: string) {
      this.updateData()
    }

    public mounted() {
      this.updateData()
    }

    public beforeDestroy() {
      if (this.chart) {
        this.chart.dispose()
      }
    }

    public updateData() {
      am4core.useTheme(am4themes_animated)

      // Create chart instance
      let chart: any = am4core.create(this.$refs.chart, am4charts.XYChart)

      // Create axes (dateAxis and valueAxis)
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis())
      dateAxis.renderer.minGridDistance = 60
      dateAxis.title.text = 'Date'

      const valueAxis = new am4charts.ValueAxis()
      valueAxis.min = 0
      valueAxis.max = 250
      valueAxis.strictMinMax = true
      valueAxis.title.text = 'Hours'

      chart.yAxes.push(valueAxis)

      // Create series
      let series: any = chart.series.push(new am4charts.LineSeries())
      series.dataFields.valueY = 'value'
      series.dataFields.dateX = 'timestamp'
      series.tooltipText = '{value}'
      series.name = '[bold]Battery life estimate in hours'

      series.tooltip.pointerOrientation = 'vertical'

      // Add cursor
      chart.cursor = new am4charts.XYCursor()
      chart.cursor.snapToSeries = series
      chart.cursor.xAxis = dateAxis

      let regseries = chart.series.push(new am4charts.LineSeries())
      regseries.dataFields.valueY = 'value'
      regseries.dataFields.dateX = 'timestamp'
      regseries.strokeWidth = 2

      let reg = regseries.plugins.push(new am4plugins_regression.Regression())

      reg.events.on('processed', function (ev: any) {
        regseries.name = '[bold]Trend line for Hours R²:[/] ' + ev.target.result.r2
      })

      // Create value axis range
      let range = valueAxis.axisRanges.create()
      range.value = 100
      range.grid.stroke = am4core.color('#666666')
      range.grid.strokeWidth = 1
      range.grid.strokeOpacity = 0.8

      // only show line at 72 hours on the graph for gen2 rings
      if (this.ring?.hardwareType == 'gen2') {
        let range2 = valueAxis.axisRanges.create()
        range2.value = 72
        range2.grid.stroke = am4core.color('#396478')
        range2.label.text = '72'
        range2.grid.strokeWidth = 1
        range2.grid.strokeOpacity = 0.5
      }

      // Add legend
      chart.legend = new am4charts.Legend()
      chart.legend.position = 'top'

      chart.responsive.enabled = true

      this.chart = chart

      this.data.forEach((element: any) => {
        element.value = Math.round(element.value / (60 * 60))
      })

      this.chart.data = this.data
    }
  }

  export default toNative(RingLifetime)
</script>

<style lang="scss" scoped>
  .view {
    width: 100%;
    min-height: 280px;
  }

  .menu {
    position: relative;
    min-width: 40px;
    min-height: 40px;
    word-break: normal;
    font-size: 16px;
    margin-top: -2px;
  }
</style>
