import { AxiosError, AxiosResponse } from 'axios'

import { Store } from 'pinia-class-component'

import { ouraTestingExternalId } from '#views/teams/constants'

import { BaseStore } from '#stores/base'

import {
  CreateOrganizationRequest,
  CreateSubscriptionData,
  DeleteSubscriptionData,
  TeamsOrgSubscription,
  TeamsOrganization,
  TeamsPlan,
} from '#types'

const REQUEST_SOURCE_CREATE_ACCOUNT_SEND_EMAIL = 'createAccountSendEmail'
const REQUEST_SOURCE_CREATE_ACCOUNT = 'createAccount'

@Store()
export class TeamsStore extends BaseStore {
  public dataWait = false

  public createAccErr = ''

  public plans: TeamsPlan[] = []

  public organizations: TeamsOrganization[] = []
  public organizationSubscriptions: TeamsOrgSubscription[] = []

  public get filteredOrganizations() {
    return this.organizations.filter((org: TeamsOrganization) => org.externalId !== ouraTestingExternalId)
  }

  public async listPlans() {
    this.dataWait = true
    if (!this.plans?.length) {
      const response = await this.makeRequest(
        { method: 'get', url: '/api/v1/teams/subscription/plans' },
        'listPlans',
      ).catch((_axiosError: AxiosError) => {
        //ignore for now
        return null
      })
      this.plans = response?.data?.plans
    }
    this.dataWait = false
  }

  public async listOrganizations() {
    this.dataWait = true
    const response = await this.makeRequest({ method: 'get', url: `/api/v1/teams` }, 'listOrganizations').catch(
      (_axiosError: AxiosError) => {
        //ignore for now
        return null
      },
    )
    this.organizations = response?.data?.teams || []
    this.dataWait = false
    return response?.data?.teams || []
  }

  public async saveOrganization(organization: TeamsOrganization) {
    const payload = {
      name: organization.name,
      permissions: organization.permissions,
      external_id: organization.externalId,
      account_manager_email: organization.accountManagerEmail || null,
    }
    this.dataWait = true
    const response = await this.makeRequest(
      {
        method: 'put',
        url: `/api/v1/teams/${organization.uid}`,
        data: payload,
      },
      'saveOrganization',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.dataWait = false
    await this.listOrganizations()
    return response
  }

  public async createOrganization({
    organization,
    subscription,
  }: {
    organization: TeamsOrganization
    subscription: CreateSubscriptionData
  }) {
    this.dataWait = true
    // HRM / health_private organization uses default template version without subscription start time
    const emailTemplateVersion = organization.type === 'health_private' ? undefined : 'V2'
    const subscriptionValidFrom = organization.type === 'health_private' ? undefined : subscription.startDate

    const payload: CreateOrganizationRequest = {
      name: organization.name,
      type: organization.type,
      permissions: organization.permissions,
      admin_email: organization.adminEmail!.toLowerCase(),
      external_id: organization.externalId,
      account_manager_email: organization.accountManagerEmail || null,
      email_template_version: emailTemplateVersion,
      subscription_valid_from: subscriptionValidFrom,
    }

    this.dataWait = true

    const response = await this.makeRequest(
      {
        method: 'post',
        url: '/api/v1/teams',
        data: payload,
      },
      'createOrganization',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })

    const organizationId = response?.data?.uid

    // Create subscription for the newly created organization uid
    if (subscription && organizationId) {
      await this.createSubscription({
        ...subscription,
        organizationID: organizationId,
      })
    }

    this.dataWait = false
    await this.listOrganizations()
    return response
  }

  public async listOrganizationSubscriptions(orgID: string) {
    this.dataWait = true
    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/teams/${orgID}/subscriptions` },
      'listOrganizationSubscriptions',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.dataWait = false
    const subscriptions: TeamsOrgSubscription[] = response?.data?.subscriptions || []
    const subsReverse = subscriptions.reverse()
    this.organizationSubscriptions = subsReverse.map((sub) => ({
      ...sub,
      options: sub.options.reverse(),
    }))
  }

  public async setSubscriptionEndDate({
    orgID,
    subscriptionID,
    endDate,
  }: {
    orgID: string
    subscriptionID: string
    endDate: string
  }) {
    this.dataWait = true
    const response = await this.makeRequest(
      {
        method: 'put',
        url: `/api/v1/teams/${orgID}/subscriptions/${subscriptionID}`,
        data: { valid_to: endDate },
      },
      'setSubscriptionEndDate',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.dataWait = false
    await this.listOrganizations()
    await this.listOrganizationSubscriptions(orgID)
    return response
  }

  public async setSubscriptionOptions({
    orgID,
    subscriptionID,
    seatCount,
    blackoutMode,
  }: {
    orgID: string
    subscriptionID: string
    seatCount: number | null | undefined
    blackoutMode: boolean | null | undefined
  }) {
    const payload = {
      options: {
        version: 1,
        blackout_mode: typeof blackoutMode === 'boolean' ? blackoutMode : undefined,
        participant_seat_count: typeof seatCount === 'number' ? seatCount : undefined,
      },
    }
    this.dataWait = true
    const response = await this.makeRequest(
      {
        method: 'put',
        url: `/api/v1/teams/${orgID}/subscriptions/${subscriptionID}`,
        data: payload,
      },
      'setSubscriptionOptions',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.dataWait = false
    await this.listOrganizationSubscriptions(orgID)
    return response
  }

  public async createSubscription(subscription: CreateSubscriptionData) {
    const payload = {
      plan_uid: subscription.planID,
      status: subscription.status,
      valid_from: subscription.startDate?.replace('Z', '+00:00') ?? undefined,
      valid_to: subscription.endDate?.replace('Z', '+00:00') ?? undefined,
      options: {
        version: 1,
        blackout_mode: typeof subscription.blackoutMode === 'boolean' ? subscription.blackoutMode : undefined,
        participant_seat_count: typeof subscription.seatCount === 'number' ? subscription.seatCount : undefined,
      },
    }
    this.dataWait = true
    const response = await this.makeRequest(
      {
        method: 'post',
        url: `/api/v1/teams/${subscription.organizationID}/subscriptions`,
        data: payload,
      },
      'createSubscription',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.dataWait = false
    await this.listOrganizations()
    await this.listOrganizationSubscriptions(subscription.organizationID)
    return response
  }

  public async deleteSubscription({ orgID, subscriptionID }: DeleteSubscriptionData) {
    this.dataWait = true
    const response = await this.makeRequest(
      {
        method: 'delete',
        url: `/api/v1/teams/${orgID}/subscriptions/${subscriptionID}`,
      },
      'deleteSubscription',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.dataWait = false
    await this.listOrganizations()
    await this.listOrganizationSubscriptions(orgID)
    return response
  }

  public async deleteOrganization(uuid: string) {
    this.dataWait = true
    const response = await this.makeRequest(
      {
        method: 'put',
        url: `/api/v1/teams/${uuid}/deactivate`,
      },
      'deleteOrganization',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.dataWait = false
    await this.listOrganizations()
    return response
  }

  /**
   * Create new Oura account
   *
   * @param   email     Email address
   * @returns boolean   True if account was created, false if there was error
   */
  public async createAccount(email: string): Promise<boolean> {
    this.createAccErr = ''
    this.dataWait = true
    const createdUserId: string | undefined = await this.makeRequest(
      {
        method: 'post',
        url: '/api/v1/users',
        data: { email: email },
      },
      REQUEST_SOURCE_CREATE_ACCOUNT,
    )
      .then((value: AxiosResponse | null) => {
        return value?.data?.uid
      })
      .catch((axiosError: AxiosError) => {
        const requestError = this.handleRequestError(axiosError, REQUEST_SOURCE_CREATE_ACCOUNT)
        if (requestError.userMessage) {
          this.createAccErr = requestError.userMessage
        }
        return null
      })

    if (createdUserId) {
      await this.makeRequest(
        {
          method: 'put',
          url: `/api/v1/users/${createdUserId}/send-instructions-email`,
          data: { locale: 'en' },
        },
        REQUEST_SOURCE_CREATE_ACCOUNT_SEND_EMAIL,
      ).catch((axiosError: AxiosError) => {
        const requestError = this.handleRequestError(axiosError, REQUEST_SOURCE_CREATE_ACCOUNT_SEND_EMAIL)
        if (requestError.userMessage) {
          this.createAccErr = requestError.userMessage
        }
      })
    }

    this.dataWait = false
    return !!createdUserId
  }
}
