import { GraphFilterWeights, GraphOptions } from '#types'

/**
 * Extract filter weights from graph options
 *
 * @param graphOptions - Graph options
 * @returns Weight of all available filters
 */
export function extractFilterWeightsFromGraphOptions(graphOptions: GraphOptions): GraphFilterWeights {
  const graphFilterWeights: GraphFilterWeights = {}
  for (const category in graphOptions.categories) {
    for (const filterId in graphOptions.categories[category]) {
      graphFilterWeights[filterId] = graphOptions.categories[category][filterId].weight
    }
  }
  return graphFilterWeights
}

/**
 * Calculate total weight of filters
 *
 * @param filters - Selected filters
 * @param filterWeights - Weight of all available filters
 * @param timeMultiplier - Time multiplier
 * @returns Total weight
 */
export function calculateTotalWeight(
  filters: string[],
  filterWeights: GraphFilterWeights,
  timeMultiplier: number = 1,
): number {
  if (timeMultiplier <= 0) {
    throw new Error('timeMultiplier must be > 0')
  }
  let totalWeight: number = 0
  filters.forEach((filter: string) => {
    totalWeight += filterWeights[filter] | 0 // Prevent getting NaN from this if the filter weight is not found for the filter
  })
  return totalWeight * timeMultiplier
}

/**
 * Calculates time weight multiplier
 *
 * @param startDate - Start date
 * @param endDate - End date
 * @param singleDayWeightMultiplier - Single day weight multiplier
 * @returns Number of full days between two timestamps, weight time multiplier
 */
export function calculateTimeWeightMultiplier(
  startDate: Date,
  endDate: Date,
  singleDayWeightMultiplier: number,
): { numberOfDays: number; weightTimeMultiplier: number } {
  if (singleDayWeightMultiplier <= 0) {
    throw new Error('singleDayWeightMultiplier must be > 0')
  }
  if (startDate > endDate) {
    throw new Error('End date must be later than start date')
  }

  let numberOfDays: number = 0
  if (startDate < endDate) {
    numberOfDays = Math.ceil((endDate.getTime() - startDate.getTime()) / 1000 / 86400)
  } else {
    numberOfDays = 1
  }
  const weightTimeMultiplier = numberOfDays * singleDayWeightMultiplier
  return { numberOfDays: numberOfDays, weightTimeMultiplier: weightTimeMultiplier }
}

/**
 * Calculate weight date limits
 *
 * Returns useful information such as:
 * - Maximum days that can be shown so we don't exceed weight limit
 * - Earliest possible start date so we don't exceed weight limit
 * - Does start date need to be adjusted from current
 *
 * @param singleDayWeight - Weight of single day's filters
 * @param maxTotalWeight - Maximum total allowed weight
 * @param currentStartDate - Currently selected start date
 * @param currentEndDate - Currently selected end date
 */
export function calculateWeightDateLimits(
  singleDayWeight: number,
  maxTotalWeight: number,
  currentStartDate: Date,
  currentEndDate: Date,
) {
  if (singleDayWeight <= 0) {
    throw new Error('singleDayWeight must be > 0')
  }
  if (maxTotalWeight <= 0) {
    throw new Error('maxTotalWeight must be > 0')
  }
  if (currentStartDate > currentEndDate) {
    throw new Error('End date must be later than start date')
  }
  const maxDays = Math.floor(maxTotalWeight / singleDayWeight)
  const earliestStartDateMs = currentEndDate.getTime() - maxDays * 86400 * 1000
  const earliestStartDateStr = new Date(earliestStartDateMs).toISOString().substring(0, 10)
  const earliestStartDate = new Date(earliestStartDateMs)
  let startDateNeedsToBeAdjusted = false
  if (currentStartDate < earliestStartDate) {
    startDateNeedsToBeAdjusted = true
  }

  return {
    maxDays: maxDays,
    earliestStartDateString: earliestStartDateStr,
    startDateNeedsToBeAdjusted: startDateNeedsToBeAdjusted,
  }
}
