<template>
  <v-dialog :model-value="true" width="500">
    <v-card>
      <v-card-title>Add shared account</v-card-title>

      <v-card-text>
        <v-form v-model="emailValid">
          <v-text-field v-model="addEmail" label="Email address" hide-details="auto" :rules="emailRules" />

          <v-textarea
            v-model="addConsent"
            label="Consent information and URL"
            hide-details="auto"
            class="mt-4"
            :rules="consentInfoRules"
          />
        </v-form>
      </v-card-text>

      <v-alert v-if="!!error" tile class="px-5 v-sheet--tile" text="error" type="error" color="red" />

      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancelAddAccount()">Cancel</v-btn>
        <v-btn color="blue" :disabled="!emailValid" @click="addAccount()">Add</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Vue, toNative } from 'vue-facing-decorator'

  import { getEmailValidationErrorMessage, isEmailValid } from '#utils/user/emailValidation'

  import { AdminStore } from '#stores'

  @Component
  class ShareAccount extends Vue {
    public error = ''
    public addEmail = ''
    public addConsent = ''

    public emailValid = false

    public adminStore = new AdminStore()

    public emailRules = [(v: string) => isEmailValid(v, this.env) || getEmailValidationErrorMessage(this.env)]

    public consentInfoRules = [(v: string) => v.length > 5 || 'Consent Info is required']

    private env = import.meta.env.VITE_APP_ENV

    @Emit('close')
    public close() {
      return
    }

    public addAccount() {
      this.error = ''

      this.adminStore
        .addSharedAccount({
          addEmail: this.addEmail,
          addConsent: this.addConsent,
        })
        .then((res) => {
          if (res !== 200) {
            this.error = 'Unable to add account with given email'
          } else {
            this.addEmail = ''
            this.addConsent = ''

            this.close()
          }
        })
    }

    public cancelAddAccount() {
      this.addEmail = ''
      this.addConsent = ''

      this.close()
    }
  }
  export default toNative(ShareAccount)
</script>
