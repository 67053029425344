import { GraphGroups } from '#types'

export function selectedPredefinedGroups(allPredefinedGroups: GraphGroups, selectedFilters: string[]) {
  const selectedGroupIds: string[] = []
  Object.entries(allPredefinedGroups).forEach(([key, graphGroupItem]) => {
    const missing = graphGroupItem.graphs.filter((item) => selectedFilters.indexOf(item) < 0)
    if (missing.length === 0) {
      selectedGroupIds.push(key)
    }
  })
  return selectedGroupIds
}
