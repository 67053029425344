/**
 * Format file size to human readable format. Doesn't work with negative numbers (returns NaN) or files larger than 999 PB (returns files size incorrectly)
 *
 * @param fileSizeInBytes
 * @returns File size in human readable format
 */
export function formatFileSize(fileSizeInBytes: number): string {
  if (fileSizeInBytes == 0) {
    return '0 B'
  }
  const bytesPerKB = 1024
  const powerOfTen = Math.floor(Math.log(fileSizeInBytes) / Math.log(bytesPerKB))
  const prefix = powerOfTen > 0 ? 'KMGTP'.charAt(powerOfTen - 1) : ''
  const formattedNumber = (fileSizeInBytes / Math.pow(bytesPerKB, powerOfTen)).toFixed(0)
  const formattedFileSize = `${formattedNumber} ${prefix}B`
  return formattedFileSize
}
