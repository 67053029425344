<template>
  <v-banner
    v-show="noRingMessage"
    single-line
    color="error"
    elevation="2"
    density="compact"
    icon="mdi-close"
    class="pt-3"
    :text="noRingMessage"
  />
</template>

<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  @Component
  class NoRingBanner extends Vue {
    public noRingMessage: string = 'Member does not have a ring'
  }

  export default toNative(NoRingBanner)
</script>
