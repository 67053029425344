import { GraphCacheWindows, GraphOptions } from '#types'

/**
 * Extract cache windows from graph options
 *
 * @param graphOptions - Graph options
 * @returns Cache windows of all available filters
 */
export function extractCacheWindowsFromGraphOptions(graphOptions: GraphOptions): GraphCacheWindows {
  const graphCacheWindows: GraphCacheWindows = {}
  for (const category in graphOptions.categories) {
    for (const filterId in graphOptions.categories[category]) {
      graphCacheWindows[filterId] = graphOptions.categories[category][filterId].cacheWindow
    }
  }
  return graphCacheWindows
}
