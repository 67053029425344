import { getDaysBetween } from '#utils/datetime/calculate'
import { formatDateTime } from '#utils/datetime/format'

import { SimilarSubscriptionActions, SubscriptionHistoryEvent } from '#types'

export function extractSimilarActionsFromSubscriptionHistoryEvents(
  subscriptionHistoryEvents: SubscriptionHistoryEvent[],
): SimilarSubscriptionActions {
  const similarActions: SimilarSubscriptionActions = {
    trialAlreadyExtended: false,
    trialAlreadyExtendedMessage: null,
    compensationAlreadyGiven: false,
    compensationAlreadyGivenMessage: null,
  }
  const maxAgeDays = 30
  const dateNow = new Date()

  for (const historyEvent of subscriptionHistoryEvents) {
    if (historyEvent.createdAt) {
      const daysBetween = getDaysBetween(new Date(historyEvent.createdAt), dateNow)
      if (daysBetween > maxAgeDays) {
        continue
      }
    }

    let createdAtFormatted = ''
    let issueStartDateFormatted = ''
    let reason = ''

    if (historyEvent.type == 'SUBSCRIPTION_TRIAL_EXTENDED' || historyEvent.type == 'SUBSCRIPTION_COMPENSATION') {
      if (historyEvent.reason) {
        reason = historyEvent.reason
        if (historyEvent?.escalationReason) {
          reason = `${reason} (${historyEvent.escalationReason})`
        }
      }
      createdAtFormatted = formatDateTime(historyEvent.createdAt, 'DD MMM YYYY') ?? ''
      issueStartDateFormatted = formatDateTime(historyEvent.issueStartDate, 'DD MMM YYYY') ?? ''
    }

    if (historyEvent.type == 'SUBSCRIPTION_TRIAL_EXTENDED') {
      similarActions.trialAlreadyExtended = true
      similarActions.trialAlreadyExtendedMessage = `This subscription has already been given trial extension on ${createdAtFormatted} for issues that started on ${issueStartDateFormatted}. Reason: ${reason}.`
    }

    if (historyEvent.type == 'SUBSCRIPTION_COMPENSATION') {
      similarActions.compensationAlreadyGiven = true
      const compensationLengthFormatted = historyEvent?.compensationLengthInMonths
        ? ` of ${historyEvent.compensationLengthInMonths} months`
        : ''
      similarActions.compensationAlreadyGivenMessage = `This subscription has already been compensated${compensationLengthFormatted} on ${createdAtFormatted} for issues that started on ${issueStartDateFormatted}. Reason: ${reason}.`
    }
  }
  return similarActions
}
