import { AxiosError } from 'axios'

import { Store } from 'pinia-class-component'

import { BaseStore } from '#stores/base'

import { SampleFilter } from '#types'

@Store()
export class SamplesStore extends BaseStore {
  public dataWait = false

  public filters: SampleFilter[] = []

  public async getSampleFilters(serial: string) {
    this.filters = []
    this.dataWait = true

    // {serial.split(',')[0]} strips out extra data from ring serial numbers, eg 2H3A2223000034,13
    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/rings/${serial.split(',')[0]}/sample-filters`, params: { tagName: 'darwin' } },
      'getSampleFilters',
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    if (response?.data?.filters) {
      this.filters = response?.data?.filters
    }
    this.dataWait = false
  }
}
