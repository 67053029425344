<template>
  <div class="pb-0">
    <v-row class="pa-0">
      <v-col cols="12" sm="12" md="7">
        <v-text-field
          v-model="textField"
          autofocus
          label="Search Darwin users"
          append-inner-icon="mdi-magnify"
          :placeholder="'Type to filter ' + filtersName + '...'"
        />
      </v-col>

      <v-col class="d-flex" cols="12" sm="12" md="5">
        <v-spacer />

        <v-select
          v-model="filtersSelected"
          :items="allFilters"
          :label="'Add ' + filtersType + ' filters'"
          :item-title="'text'"
          placeholder="All filters applied"
          solo
          multiple
          hide-selected
          @update:model-value="addFilter()"
        >
          <template #selection="{ index }">
            <span v-if="index === 0">Add {{ filtersType }} filters</span>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row v-if="filtersSelected?.length" class="pa-1 mx-n2 mt-n1 justify-start mb-2">
      <template v-for="filter in filtersSelected" :key="filter">
        <v-chip closable class="ma-1" @click:close="removeFilter(filter)">
          <span>{{ filter }}</span>
        </v-chip>
      </template>
    </v-row>
  </div>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  @Component
  class FilterBar extends Vue {
    @Prop() public filter!: string
    @Prop() public allFilters!: any[]
    @Prop() public filtersName!: string
    @Prop() public filtersType!: string

    public textField: string = ''

    @Emit('change')
    public emitChange() {
      return false
    }

    @Emit('filter')
    public emitFilter(val: string) {
      return val
    }

    @Emit('filters')
    public emitFilters(filters: any) {
      return filters
    }

    public filtersSelected: string[] = []

    @Watch('filters')
    protected onFiltersChange(val: string[]) {
      this.filtersSelected = val
    }

    @Watch('textField')
    protected onTextFieldChange(val: string) {
      this.emitFilter(val)
    }

    public textFilterChange(val: string) {
      this.emitFilter(val)
      this.emitChange()
    }

    public addFilter() {
      this.emitFilters(this.filtersSelected)
      this.emitChange()
    }

    public removeFilter(filter: string) {
      this.filtersSelected = [...this.filtersSelected.filter((item: string) => item !== filter)]
      this.emitFilters(this.filtersSelected)
      this.emitChange()
    }
  }

  export default toNative(FilterBar)
</script>

<style lang="scss" scoped>
  :deep(.theme--light.v-btn-toggle.v-item-group) {
    background: #f8f8fb;
  }
</style>
