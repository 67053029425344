<template>
  <v-row>
    <v-col cols="12" md="9">
      <div class="text-h5 font-weight-light">Bulk account batch jobs creation / management</div>

      <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
        Bulk account batches allows creation of multiple Oura member accounts at once
      </div>
    </v-col>

    <v-col md="3" cols="12" class="text-right">
      <v-btn color="primary" @click="batchDialog = true">Create new batch</v-btn>
    </v-col>
  </v-row>

  <v-row class="mt-4">
    <v-col class="d-flex" cols="12" sm="12" md="7">
      <v-text-field
        v-model="filter"
        autofocus
        label="Search bulk account batches"
        append-inner-icon="mdi-magnify"
        placeholder="Type to filter bulk account batches..."
      />
    </v-col>
  </v-row>

  <v-sheet class="mt-8">
    <v-data-table
      :items="accounts"
      :headers="headers"
      :loading="dataWait"
      :items-per-page="100"
      :search="filter"
      :sort-by="[{ key: 'createdAt', order: 'desc' }]"
      hide-default-footer
    >
      <template #item="{ item }">
        <tr>
          <td>{{ item.parameters.batch_create_user_job_comment }}</td>
          <td>
            <span v-if="item.createdAt" class="text-no-wrap">
              {{ formatDateTime(item.createdAt, 'DD MMM YYYY') }}
            </span>
          </td>
          <td>{{ item.initiatingUserEmail }}</td>
          <td>{{ item.info?.user_counts || 0 }}</td>
          <td>
            <span v-if="item.parameters">
              {{
                item.parameters.batch_create_user_job_bypass_email_verification === 'false' ? 'List added' : 'Generated'
              }}
            </span>
          </td>
          <td>
            <span v-if="item.parameters">
              {{ item.parameters.batch_create_user_job_send_email === 'true' ? 'Email' : 'Link' }}
            </span>
          </td>
          <td>
            <div
              :class="{
                'text-red': item.status === 'FAILED',
                'text-green': item.status === 'SUCCEEDED',
              }"
            >
              {{ item.status }}
            </div>
          </td>
          <td>
            <span v-if="item.status === 'FAILED'">
              <p
                class="text-blue ma-0 pa-0 text-decoration-underline"
                @click.stop="loadLogsByTaskId(item.parameters.environment, item.uid)"
              >
                logs
              </p>
            </span>
          </td>
          <td>
            <div v-if="item.status === 'SUCCEEDED'">
              <div>
                <v-menu offset-y bottom left>
                  <template #activator="{ props }">
                    <v-btn
                      variant="text"
                      class="align-self-center mr-n4"
                      v-bind="props"
                      @click.stop="getResultsFilesByTaskId(item.parameters.environment, item.uid)"
                    >
                      output
                      <v-icon color="primary">mdi-menu-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list class="grey lighten-3 text-center">
                    <v-progress-circular v-if="filesWait" indeterminate color="primary" />
                    <template v-if="files">
                      <v-list-item
                        v-for="file in files"
                        :key="file.key"
                        @click.stop="downloadFile(item.parameters.environment, file.key)"
                      >
                        {{ file.key.split('/').pop() }}
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <div v-if="item.status === 'PENDING'">not ready</div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-sheet>

  <CreateAccounts v-if="batchDialog" @close="batchDialog = false" />

  <TaskErrorLogs :logs-dialog="logsDialog" @load-logs="loadLogsByTaskId(env, taskId)" @close="logsDialog = false" />

  <router-view />
</template>

<script lang="ts">
  import { Component, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { bulkHeaders } from '#views/admin/constants'

  import { FilesStore, TasksStore } from '#stores'

  @Component
  class BulkAccounts extends mixins(DateTime) {
    public filesStore = new FilesStore()
    public tasksStore = new TasksStore()

    public filter = ''

    public taskId = ''
    public env = ''

    public logsDialog = false
    public batchDialog = false

    public headers = bulkHeaders

    private interval: number | undefined = undefined

    public get files() {
      return this.tasksStore.files || []
    }

    public get accounts() {
      return this.tasksStore.bulkUsers || []
    }

    public get filesWait() {
      return this.tasksStore.filesWait || false
    }

    public get resultsFileParams() {
      return this.tasksStore.resultsFileParams || null
    }

    public get logFileParams() {
      return this.tasksStore.logFileParams || null
    }

    public get dataWait() {
      return this.tasksStore.dataWait
    }

    @Watch('resultsFileParams')
    protected onResultsFileParamsRetrieved(_val: string, _oldVal: string) {
      if (this.resultsFileParams) {
        this.tasksStore.getResultsFiles({
          env: this.resultsFileParams.env,
          jobId: this.resultsFileParams.jobId,
        })
      }
    }

    @Watch('logFileParams')
    protected onLogFileParamsRetrieved(_val: string, _oldVal: string) {
      if (this.logFileParams) {
        this.tasksStore.getLogs(this.logFileParams.jobId)
      }
    }

    public mounted() {
      this.interval = setInterval(this.pollTasks, 60000)

      this.pollTasks(true)
    }

    public beforeDestroy() {
      clearInterval(this.interval)
    }

    public downloadFile(env: string, key: string) {
      this.filesStore.downloadFile({
        env: env,
        key: key,
        bucket: 'results',
        anonymizeJzlog: false,
      })
    }

    public async getResultsFilesByTaskId(env: any, taskId: string) {
      await this.tasksStore.getTaskDetails({ taskId: taskId, env: env, fileType: 'results' })
    }

    public async loadLogsByTaskId(env: any, taskId: string) {
      this.taskId = taskId
      this.env = env
      this.logsDialog = true
      await this.tasksStore.getTaskDetails({ taskId: taskId, env: env, fileType: 'log' })
    }

    private pollTasks(initial: boolean) {
      this.tasksStore.listBulkUsers(initial)
    }
  }

  export default toNative(BulkAccounts)
</script>

<style lang="scss" scoped>
  :deep(.expired) {
    color: red;
  }

  :deep(.v-data-table) {
    tr:hover {
      cursor: pointer;
    }

    .v-data-footer__select {
      visibility: hidden;
    }
  }
</style>
