<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <v-row>
    <v-col cols="12" md="9">
      <div class="text-h5 font-weight-light">Permissions and login info of Darwin users</div>

      <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
        Permissions and login info for all Darwin users from the last login
      </div>
    </v-col>

    <v-col cols="12" md="3" class="text-right">
      <v-btn text="Download as CSV" color="primary" @click="downloadAsCSV()" />
    </v-col>
  </v-row>

  <FilterBar
    v-if="showFilters"
    class="mt-4"
    :all-filters="allFiltersRights"
    :filters-name="'users'"
    :filters-type="'rights/roles'"
    @filter="updateUserFilter($event)"
    @filters="updatePermissionFilters($event)"
  />

  <v-sheet class="mt-8">
    <div v-if="!groups.includes('groupDarwinAdmins')" class="pa-8">
      You don't have the access right to see the user audit details
    </div>

    <v-data-table
      v-else
      :headers="headers"
      :items="filteredUsers"
      :items-per-page="500"
      :loading="dataWait"
      :sort="'lastActive'"
      @click:row="showAuditLogs"
    >
      <template #[`item.lastActive`]="{ item }">
        <div class="text-no-wrap">
          {{ $dayjs(item.lastActive).utc().format('HH:mm - DD MM YYYY [UTC]') }}
        </div>
      </template>

      <template #[`item.email`]="{ item }">
        <div :id="item.id" class="text-no-wrap">
          {{ item.email }}
        </div>
      </template>
      <template #[`item.gdprTrainingExpiration`]="{ item }">
        <div
          class="text-no-wrap"
          :class="item.gdprTrainingExpiration ? getGDPRExpirationWarning(item.gdprTrainingExpiration.toDate()) : ''"
        >
          {{
            item.gdprTrainingExpiration
              ? $dayjs(item.gdprTrainingExpiration.toDate()).utc().format('HH:mm - DD MM YYYY [UTC]')
              : ''
          }}
        </div>
      </template>
    </v-data-table>
  </v-sheet>

  <v-dialog v-model="dialog" scrollable width="1200">
    <v-card>
      <v-card-title class="headline lighten-2">
        <span>User: {{ email }}</span>
        <v-spacer />
        <v-btn variant="plain" icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-row>
        <v-col v-if="userSavingFailed" cols="12">
          <v-alert type="error">Saving user failed. Please try again.</v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="7">
          <v-menu
            v-model="gdprTrainingDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ props }">
              <v-text-field
                :model-value="gdprTrainingDateFormatted"
                label="GDPR training completed"
                prepend-icon="mdi-calendar"
                hint="Date when the GDPR training was completed"
                hide-details="auto"
                v-bind="props"
                @update:focused="updateGdprTrainingExpiration($event)"
              />
            </template>
            <v-date-picker
              no-title
              hide-actions
              :model-value="gdprTrainingDateString ? new Date(gdprTrainingDateString) : undefined"
              @update:model-value="
                (gdprTrainingDateString = $dayjs($event).format('YYYY-MM-DD')), (gdprTrainingDate = false)
              "
            />
          </v-menu>
        </v-col>
        <v-col cols="5">
          <v-btn class="mt-4 ml-3 success" @click="setGDPRTrainingDate()">Save</v-btn>
        </v-col>
      </v-row>
      <v-card-text v-if="!auditEvents.length">There is not audit logs for this user</v-card-text>
      <AuditEvents v-else :filtered-logs="auditEvents" :items-per-page="10" :show-filters="false" />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { allFiltersRights, auditUsersHeaders } from '#views/audit/constants'

  import { AppStore, AuditStore } from '#stores'

  @Component
  class AuditUsers extends mixins(DateTime) {
    @Prop() public showFilters: boolean = true
    @Prop() public userFilterPrefilled: string = ''

    public userFilter: string = ''

    public email!: string

    public permissionFilters: any = null
    public dialog: boolean = false
    public firestoreUser: any = null
    public userSavingFailed = false
    public filteredUsers: any[] = []
    public gdprTrainingDate: boolean = false
    public gdprTrainingDateString = ''
    public gdprTrainingDateFormatted = ''

    public allFiltersRights = allFiltersRights
    public headers = auditUsersHeaders

    public auditStore = new AuditStore()
    public appStore = new AppStore()

    public get groups() {
      return this.appStore.groups || []
    }

    public get auditUsers() {
      return this.auditStore.auditUsers || []
    }

    public get auditEvents() {
      return this.auditStore.auditEvents || []
    }

    public get dataWait() {
      return this.auditStore.dataWait
    }

    @Emit('email')
    public emitEmail(user: any) {
      return this.filteredUsers[user.item?.index].email
    }

    @Emit('firestoreUser')
    public emitUser(user: any) {
      return this.filteredUsers[user.item?.index]
    }

    @Watch('gdprTrainingDate')
    private gdprTrainingDateChange() {
      this.gdprTrainingDateFormatted = this.formatDate(this.gdprTrainingDateString)
    }

    @Watch('firestoreUser')
    private firestoreUserChange() {
      if (this.firestoreUser?.gdprTrainingExpiration) {
        const currentExpiration = this.firestoreUser?.gdprTrainingExpiration.toDate()
        const trainingDateString = new Date(
          currentExpiration.setFullYear(currentExpiration.getFullYear() - 1),
        ).toISOString()

        this.gdprTrainingDateString = trainingDateString.substring(0, 10)
        this.gdprTrainingDateFormatted = this.formatDate(trainingDateString.split('T')[0])
      } else {
        this.gdprTrainingDateFormatted = this.formatDate(this.gdprTrainingDateString)
      }
    }

    @Watch('users')
    protected onUSersChanged() {
      this.filteredUsers = this.filterUsers()
    }

    @Watch('userFilter')
    protected onUserFilterChanged() {
      this.filteredUsers = this.filterUsers()
    }

    @Watch('permissionFilter')
    protected onPermissionFiltersChanged() {
      this.filteredUsers = this.filterUsers()
    }

    public async mounted() {
      if (this.userFilterPrefilled) {
        this.userFilter = this.userFilterPrefilled
      }
      this.gdprTrainingDateString = this.getDateString(Date.now(), 0, 'start')
      this.gdprTrainingDateFormatted = this.formatDate(this.gdprTrainingDateString)
      await this.auditStore.getAuditUsers()
      this.filteredUsers = this.filterUsers()
    }

    public updateUserFilter(value: string) {
      this.userFilter = value
    }

    public updatePermissionFilters(value: any) {
      this.permissionFilters = value
    }

    public updateGdprTrainingExpiration(value: any) {
      this.gdprTrainingDateString = value ? '' : this.parseDate(this.gdprTrainingDateFormatted)
    }

    public filterUsers() {
      return this.auditUsers.filter((user: any) => {
        const emailMatch = this.userFilter ? user.email?.toLowerCase().includes(this.userFilter?.toLowerCase()) : true

        const rightsMatch = this.permissionFilters?.length
          ? user.rights?.some((r: string) => this.permissionFilters.includes(r))
          : true

        return emailMatch && rightsMatch
      })
    }

    public async showAuditLogs(_event: any, user: any) {
      await this.auditStore.auditEventsByEmail(user.item.email)

      this.dialog = true
      this.email = user.item.email
    }

    public getGDPRExpirationWarning(expirationDate: Date): string {
      const currentDate: Date = new Date()
      if (expirationDate < currentDate) {
        return 'expiration-error'
      } else if (new Date(expirationDate.setMonth(expirationDate.getMonth() - 1)) < currentDate) {
        return 'expiration-warning'
      }
      return ''
    }

    public downloadAsCSV() {
      const orgCSV = this.filteredUsers.map((user: any) => {
        return {
          'Last login': this.$dayjs(user.lastActive).format('HH:mm - DD MM YYYY [UTC]'),
          'User email': user.email,
          'Oura Apps Admin': user.rolesOuraAppsAdmin,
          'Oura Teams Admin': user.rolesOuraTeamsAdmin,
          'Oura Users Admin': user.rolesOuraUsersAdmin,
          'Bulk Accounts Rights': user.rolesBulkAccountsAdmin,
          'Data Deletion Rights': user.allowDataDeletionAccess,
          'Data Download Rights': user.allowDataDownloadAccess,
          'Ring History Rights': user.allowRingHistoryAccess,
          'Account Edit Rights': user.allowAccountEditAccess,
          'Partial Search Rights': user.allowPartialSearchAccess,
          'Personal Data Rights': user.allowPersonalDataAccess,
          'Sensitive Data Rights': user.allowSensitiveDataAccess,
        }
      })

      let csvContent = 'data:text/csv;charset=utf-8,'
      csvContent += [Object.keys(orgCSV[0]).join(';'), ...orgCSV.map((org: any) => Object.values(org).join(';'))]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '')

      const data = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', data)
      link.setAttribute('download', 'audit_users.csv')
      link.click()
    }

    public async setGDPRTrainingDate() {
      if (this.firestoreUser) {
        let expirationDate: Date | null = null

        if (this.gdprTrainingDateString) {
          expirationDate = new Date(this.gdprTrainingDateString)

          // Add one year to training completion to get expiration date
          expirationDate.setFullYear(expirationDate.getFullYear() + 1)
        }

        try {
          this.auditStore.updateGdprTrainingExpiration({
            firestoreUser: this.firestoreUser,
            expirationDate,
          })

          this.dialog = false
          this.userSavingFailed = false
        } catch (_error) {
          this.userSavingFailed = true
        }
      }
    }
  }

  export default toNative(AuditUsers)
</script>

<style lang="scss" scoped>
  :deep(.v-data-table) {
    .v-data-footer__select,
    .v-data-footer__pagination {
      display: none;
    }
  }

  .gdpr_training_edit {
    font-size: 18px;
    cursor: pointer;

    :hover {
      color: green;
    }
  }
  .expiration-error {
    color: #e91e63;
  }
  .expiration-warning {
    color: #ff9800;
  }
</style>
