<template>
  <div class="pl-4">
    <button
      :class="['text-left', { 'w-100': !isOnOneLine }, 'cursor-default', { collapsableRow: !isValue && !isOnOneLine }]"
      @click="isCollapsed = !isCollapsed"
    >
      <template v-if="data.key">
        <span>{{ data.key }}</span>
        <span class="text-gray">:</span>
      </template>

      <span v-if="isValue" class="text-primary">&nbsp;{{ String(data.value) }}</span>
      <template v-if="!isValue">
        <span v-if="isCollapsed" class="text-gray">{{ isArray ? '[ ... ]' : '{ ... }' }}</span>
        <span v-else class="text-gray">{{ isArray ? '[' : '{' }}</span>
      </template>
    </button>

    <span v-if="!isCollapsed && !isValue">
      <JSONItem v-for="childItem in childItems" :key="childItem.key || childItem.value" :data="childItem" />
      <span class="text-gray">{{ isArray ? ']' : '}' }}</span>
    </span>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  type JSONElementType = 'value' | 'object' | 'array'

  interface JSONElement {
    key?: string
    value: any | number | string | boolean
  }

  @Component
  class JSONItem extends Vue {
    @Prop({ default: {} }) public data!: JSONElement

    public isCollapsed = false

    public get type(): JSONElementType {
      const item = this.data.value
      const isObject = item && typeof item === 'object'
      const isArray = Array.isArray(item)
      if (isObject && isArray) {
        return 'array'
      } else if (isObject) {
        return 'object'
      } else {
        return 'value'
      }
    }

    public get childItems(): JSONElement[] {
      if (this.type === 'value') {
        return []
      } else if (this.type === 'array') {
        return (this.data.value as any[]).map((item) => ({
          value: item,
        }))
      } else {
        return Object.entries(this.data.value).map(([key, value]) => ({ key, value }))
      }
    }

    public get isOnOneLine() {
      return this.childItems.length < 1
    }

    public get isValue() {
      return this.type === 'value'
    }

    public get isArray() {
      return this.type === 'array'
    }
  }

  export default toNative(JSONItem)
</script>

<style scoped>
  .collapsableRow:hover {
    background-color: #fce4ec;
    cursor: pointer !important;
  }
  .text-gray {
    color: gray;
  }
</style>
