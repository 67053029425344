<template>
  <div class="pt-8">
    <v-card>
      <v-card-title>
        <p class="overline">Internal debug</p>
      </v-card-title>
      <v-table>
        <thead>
          <tr>
            <th class="text-left">Test</th>
            <th align="right" class="text-right">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in actions" :key="item.name">
            <td>
              <p class="overline">{{ item.name }}</p>
              <i>{{ item.description }}</i>
            </td>
            <td align="right">
              <v-btn :disabled="dataWait" color="success" @click="item.callback">Test</v-btn>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
  </div>
</template>

<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { InternalDebugStore } from '#stores'

  @Component
  class InternalDebugView extends Vue {
    public actions = [
      {
        name: 'Test Sentry error',
        description: 'Sends a request to /testing/sentry-error with invalid input',
        callback: this.testSentryInvalidInput,
      },
      {
        name: 'Test Sentry success',
        description: 'Sends a request to /testing/sentry-error with valid input',
        callback: this.testSentryValidInput,
      },
    ]

    public internalDebugStore = new InternalDebugStore()

    public get dataWait(): boolean {
      return this.internalDebugStore.dataWait
    }

    public async testSentryInvalidInput() {
      await this.internalDebugStore.testSentryErrorEndpoint({ input: 'invalid' })
    }

    public async testSentryValidInput() {
      await this.internalDebugStore.testSentryErrorEndpoint({ input: 'valid' })
    }
  }
  export default toNative(InternalDebugView)
</script>
<style scoped></style>
