export function getDaysBetween(from: Date, to: Date): number {
  return Math.floor((to.valueOf() - from.valueOf()) / 86400000)
}

/**
 * Converts a duration in total seconds into a formatted string of hours and minutes.
 *
 * @param {number} totalSeconds - The duration in total seconds, e.g., 45000.
 * @returns {string} - The formatted duration, e.g., "12h 30m".
 */
export function convertTotalSecondsToHoursMinutes(totalSeconds: number): string {
  const positiveSeconds = Math.abs(totalSeconds)
  const hours = Math.floor(positiveSeconds / 3600)
  const minutes = Math.floor((positiveSeconds % 3600) / 60)
  return `${hours}h ${minutes}m`
}
