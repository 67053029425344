<template>
  <v-dialog
    v-model="resetDialog"
    width="500"
    class="backdrop"
    @click:outside="closeDialog('click-outside')"
    @keydown="closeDialog($event.keyCode)"
  >
    <v-card>
      <v-card-title class="headline grey lighten-2 pb-n4 mb-n4" primary-title>
        {{ headerText }}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-progress-circular
            v-show="dataWait"
            indeterminate
            color="#e91e63"
            size="70"
            width="8"
            style="position: absolute; top: 50%; left: 45%"
          />
          <v-row>
            <div style="position: sticky; top: 60px; width: 100%">
              <v-sheet min-height="50">
                <v-alert v-if="notificationText" :type="notificationType">{{ notificationText }}</v-alert>
              </v-sheet>
            </div>
          </v-row>
          <v-row>
            <slot name="content">
              <v-col>
                <p>{{ contentText }}</p>
                <br />
                <br />
                <p class="red--text">This action can't be undone.</p>

                <v-checkbox
                  v-model="resetCheckBoxValue"
                  :disabled="dataWait || resetSuccess"
                  label="I understand and want to proceed"
                />
              </v-col>
            </slot>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider />

      <slot name="buttons">
        <v-card-actions>
          <div v-if="!resetSuccess">
            <v-btn variant="text" :disabled="dataWait" @click="close()">Cancel</v-btn>
            <v-btn :disabled="dataWait || !resetCheckBoxValue" variant="text" color="primary" @click="confirm()">
              Confirm
            </v-btn>
          </div>
          <div v-else><v-btn variant="text" :disabled="dataWait" @click="close()">Close</v-btn></div>
        </v-card-actions>
      </slot>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { SubscriptionStore } from '#stores'

  import { Actions, Member } from '#types'

  @Component
  export class ResetDialog extends Vue {
    @Prop() public member!: Member
    public subscriptionStore = new SubscriptionStore()
    public action: Actions | null = null
    public headerText = 'Confirm'
    public contentText = ''
    public notificationText = ''
    public resetDialog = false
    public resetCheckBoxValue = false
    public resetSuccess = false
    public notificationType: 'warning' | 'error' | 'success' | 'info' = 'info'

    /**
     * @param action
     * @param headerText    Dialogs header text
     * @param contentText   Main text of the dialog
     */

    public async open(action: Actions, headerText: string, contentText: string) {
      this.action = action
      this.headerText = headerText
      this.contentText = contentText

      await this.reset()
      this.resetDialog = true
    }

    public get dataWait(): boolean {
      switch (this.action) {
        case 'resetDunning':
          return this.subscriptionStore.waitingForResetDunning()
        case 'resetSubscription':
          return this.subscriptionStore.waitingForResetSubscription()
      }
      return false
    }

    public async confirm() {
      switch (this.action) {
        case 'resetDunning':
          await this.resetDunning()
          break
        case 'resetSubscription':
          await this.resetSubscription()
          break
      }
    }

    public async resetDunning() {
      this.notificationType = 'info'
      this.notificationText = 'Resetting dunning history'

      const response = await this.subscriptionStore.resetDunning({
        userId: this.member.uuid,
      })

      if (response?.status === 204) {
        this.notificationText = 'Success'
        this.notificationType = 'success'
        this.resetSuccess = true
      } else if (this.subscriptionStore.getRequestError('resetDunning')) {
        const requestError = this.subscriptionStore.getRequestError('resetDunning')!
        this.notificationType = 'error'
        this.notificationText = 'Something went wrong. Please try again.'
        if (requestError.userMessage) {
          this.notificationText = requestError.userMessage
        }
      }
    }

    public async resetSubscription() {
      this.notificationType = 'info'
      this.notificationText = 'Resetting subscriptions'
      const response = await this.subscriptionStore.resetSubscriptions({
        userId: this.member.uuid,
      })
      if (response?.status === 204) {
        await this.subscriptionStore.getSubscriptions({
          userId: this.member.uuid,
          initial: true,
        })
        this.notificationText = 'Success'
        this.notificationType = 'success'
        this.resetSuccess = true
      } else if (this.subscriptionStore.getRequestError('resetSubscription')) {
        const requestError = this.subscriptionStore.getRequestError('resetSubscription')!
        this.notificationType = 'error'
        this.notificationText = 'Something went wrong. Please try again.'
        if (requestError.userMessage) {
          this.notificationText = requestError.userMessage
        }
      }
    }

    public async reset() {
      this.notificationText = ''
      this.resetCheckBoxValue = false
      this.resetSuccess = false
    }

    public async close() {
      this.resetDialog = false
    }
    public closeDialog(event: string | number) {
      if (event == 'click-outside' || event == 27) {
        this.close()
      }
    }
  }

  export default toNative(ResetDialog)
</script>
