import { AxiosError } from 'axios'

import { Store } from 'pinia-class-component'

import { collection, doc, getDocs, getFirestore, query, setDoc } from 'firebase/firestore'

import { BaseStore } from '#stores/base'

import { AuditLog, AuditLogOpts, AuditUser } from '#types'

@Store()
export class AuditStore extends BaseStore {
  public dataWait = false

  public auditPage = 1

  public auditNext: string | null = null
  public auditPrev: string | null = null

  public auditLogs: AuditLog[] | Partial<AuditLog>[] = []

  public auditUsers: AuditUser[] | null = null
  public auditEvents: AuditLog[] | null = null

  public setAuditLogs(data: AuditLogOpts) {
    const logs = this.auditLogs.slice(0, (data.page - 1) * 100).concat(data.logs ?? [])

    if (this.auditNext) {
      logs.push({})
    }

    this.auditLogs = logs
  }

  public async getAuditLogs(logs: AuditLogOpts) {
    let page = logs.page
    let url = `/api/v1/admin/audit-logs?pageSize=100`

    // We should provide query parameters for Axios as an object, not assemble query string manually
    if (!page) {
      page = this.auditPage || 1
    }

    if (page > this.auditPage) {
      url = this.auditNext || url
    } else if (page < this.auditPage) {
      url = this.auditPrev || url
    }

    if (logs.filters?.length) {
      url += `&event_type=${logs.filters.join('&event_type=')}`
    }
    if (logs.search?.length) {
      url += `&search=${logs.search}`
    }

    this.dataWait = true
    const response = await this.makeRequest({ method: 'get', url: url }, 'getAuditLogs').catch(
      (_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      },
    )

    this.dataWait = false

    if (response?.data) {
      this.auditPage = page
      this.auditNext = response?.data?.pagination?.next
      this.auditPrev = response?.data?.pagination?.previous
      this.setAuditLogs({ page, logs: response?.data?.contents })
    }
  }

  public async getAuditUsers() {
    this.dataWait = true
    this.auditUsers = null
    try {
      const db = await getDocs(query(collection(getFirestore(), 'users')))
      const users = db.docs.reduce((acc: any, cur: any) => {
        const data: { [key: string]: string } = {
          id: cur.id,
          ...cur.data(),
          lastActive: cur.data().lastActive ? cur.data().lastActive.toMillis() : Date.now(),
        }

        cur.data().rights.forEach((e: string) => {
          data[e] = 'X'
        })

        acc.push(data)

        return acc
      }, [])
      this.auditUsers = users
    } catch (ex) {
      console.error('Fetching audit users from Firebase failed, exception:', ex)
    }
    this.dataWait = false
  }

  public async auditEventsByEmail(email: string) {
    this.dataWait = true
    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/admin/audit-logs?authenticated_email=${email}` },
      'auditEventsByEmail',
    ).catch((_axiosError: AxiosError) => {
      return null
      // ignoring for now, should be handled properly
    })

    this.dataWait = false
    this.auditEvents = response?.data?.contents
  }

  public async updateGdprTrainingExpiration(data: { firestoreUser: any; expirationDate: Date | null }) {
    const { firestoreUser, expirationDate } = data
    const docRef = doc(getFirestore(), 'users', firestoreUser?.id)
    await setDoc(
      docRef,
      {
        gdprTrainingExpiration: expirationDate ? new Date(expirationDate) : expirationDate,
      },
      { merge: true },
    )
    await this.getAuditUsers()
  }
}
