import { Refund, RefundStatus, RefundsStatusInfo } from '#types'

export function getRefundsStatusInfo(refunds: Refund[]): RefundsStatusInfo | undefined {
  let processedCount = 0
  let processingCount = 0
  let errorCount = 0
  let canceledOrErrorCount = 0

  for (const refund of refunds) {
    switch (refund.status) {
      case RefundStatus.Processed:
        processedCount++
        break
      case RefundStatus.Processing:
        processingCount++
        break
      case RefundStatus.Canceled:
        canceledOrErrorCount++
        break
      case RefundStatus.Error:
        canceledOrErrorCount++
        errorCount++
        break
      default:
        break
    }
  }
  if (processedCount === refunds.length) {
    return { status: RefundStatus.Processed, class: 'green--text' }
  } else if (processingCount > 0 && canceledOrErrorCount === 0) {
    return { status: RefundStatus.Processing, class: 'grey--text' }
  } else if (canceledOrErrorCount > 0) {
    const status = errorCount > 0 ? RefundStatus.Error : RefundStatus.Canceled
    const classStyle = errorCount > 0 ? 'red--text' : 'grey--text'
    return { status: status, class: classStyle }
  }
  return undefined
}

export function getRefundStatusClass(status: RefundStatus): string {
  switch (status) {
    case RefundStatus.Processed:
      return 'green--text'
    case RefundStatus.Processing:
    case RefundStatus.Canceled:
      return 'grey--text'
    case RefundStatus.Error:
      return 'red--text'
    default:
      return ''
  }
}
