<template>
  <v-banner
    v-if="$featureEnabled('ringLastSyncBanner')"
    single-line
    :color="lastSyncBanner.alertColor"
    density="compact"
    :icon="lastSyncBanner.clockIcon"
    class="pt-3"
    :text="syncMessage"
  >
    <template #actions>
      <v-tooltip location="top">
        <template #activator="{ props }">
          <v-icon color="grey" v-bind="props" icon="mdi-content-copy" @click="useClipboard.copy(syncMessage || '')" />
        </template>
        {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
      </v-tooltip>
    </template>
  </v-banner>
</template>

<script lang="ts">
  import { useClipboard } from '@vueuse/core'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { extractLastSyncBanner } from '#utils/user/diagnosticBanner'

  import { DisplayTimezone, Ring, RingHardwareSyncInfo } from '#types'

  @Component
  class LastSyncInfo extends mixins(DateTime) {
    @Prop() public ring!: Ring

    public useClipboard = useClipboard()

    public get syncMessage(): string {
      return this.lastSyncBanner.lastSyncDate
        ? `${this.lastSyncBanner.syncMessage}`
        : 'Last sync date cannot be determined at this time'
    }

    public get lastSyncBanner(): RingHardwareSyncInfo {
      return extractLastSyncBanner(this.ring.lastSyncedAt, this.displayTimezone)
    }

    public get displayTimezone(): DisplayTimezone {
      return this.prefsStore.displayTimezone
    }

    @Watch('displayTimezone')
    protected onTimezoneSettingChanged() {
      this.getTimezone()
    }
  }

  export default toNative(LastSyncInfo)
</script>
