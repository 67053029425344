import dayjs, { Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { DisplayTimezone, Nullable } from '#types'

dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(timezone)

/**
 * Formats datetime string to custom format. Returns null if input is invalid, otherwise formatted string.
 *
 * Parameter date can be anything that dayjs accepts. If timezone is not set, we expect it to be UTC.
 *
 * Gotchas / good to know:
 * - If you provide just date (2024-05-15) it's time will be 00:00 in UTC. So if you also provide negative targetTimezone,
 * the outputted date will be 2024-05-14.
 *
 * Parameter format accepts any Day.js format (https://day.js.org/docs/en/display/format)
 * Parameter targetTimezone is minutes from UTC. For example 180 is '+03:00', -480 is '-08:00' and null/0 is UTC.
 *
 * @param date            Input timestamp
 * @param format          Dayjs format
 * @param targetTimezone  Target timezone in minutes from UTC
 */
export function formatDateTime(
  date: Nullable<string | number | Date | Dayjs | null | undefined>,
  format: string,
  targetTimezone?: Nullable<number>,
): Nullable<string> {
  if (!date) {
    return null
  }
  const dateParsed: dayjs.Dayjs = dayjs.utc(date)
  if (targetTimezone) {
    return dateParsed.add(targetTimezone, 'minute').format(format).toString()
  }
  return dateParsed.format(format).toString()
}

/**
 * Get timezone UTC offset
 *
 * Output is always in format hours:minutes from UTC with + or - sign on front.
 * Example return values: +00:00, +03:00, -07:00, +05:45
 *
 * @param timezoneMinutes Timezone offset, minutes from UTC
 * @return                Timezone offset, hours:minutes from UTC
 */
export function getTimezoneUTCOffset(timezoneMinutes: number): string {
  const prefix = timezoneMinutes >= 0 ? '+' : '-'
  return prefix + dayjs.utc(Math.abs(timezoneMinutes) * 60 * 1000).format('HH:mm')
}

/**
 * Get timezone offset in minutes from offset string
 *
 * Output is always number, minutes from UTC
 * Example return values: -180, 0, 60, 360
 *
 * @param timezoneOffset  Timezone offset, hours:minutes from UTC
 * @return                Timezone offset, minutes from UTC
 */
export function getTimezoneMinutesFromUTCOffset(timezoneOffset: string): Nullable<number> {
  if ((timezoneOffset[0] !== '+' && timezoneOffset[0] !== '-') || timezoneOffset.length !== 6) {
    return null
  }
  const plusTimezone = timezoneOffset[0] == '+'
  const hours = parseInt(timezoneOffset.slice(1, 3))
  const minutes = parseInt(timezoneOffset.slice(4, 6))
  if (Number.isNaN(hours) || Number.isNaN(minutes)) {
    return null
  }
  const offset = hours * 60 + minutes
  if (plusTimezone) {
    return Math.abs(offset)
  } else {
    return -Math.abs(offset)
  }
}

/**
 * Get timezone offset in minutes from full timestamp
 *
 * Output is always number, minutes from UTC
 * Example return values: -180, 0, 60, 360
 *
 * @param timestamp       Full timestamp with timezone information in ISO8601/RFC3339 format
 * @return                Timezone offset, minutes from UTC. Null if input is invalid.
 */
export function getTimezoneMinutesFromTimestamp(timestamp: string): Nullable<number> {
  if (timestamp.length >= 25) {
    return getTimezoneMinutesFromUTCOffset(timestamp.slice(-6))
  }
  return null
}

function displayTimezoneSuffix(displayTimezone: DisplayTimezone): Nullable<string> {
  if (displayTimezone !== DisplayTimezone.MEMBER) {
    return displayTimezone
  }
  return null
}

/**
 * Modifies a datetime based on the displayTimezone parameter.
 * If the input datetime includes timezone information, it will be used in the modification.
 *
 * @param datetime - The datetime string, e.g., "2024-09-04T06:19:00+03:00".
 * @param displayTimezone - The target timezone for display, options: "UTC" or "MEMBER".
 * @param format - The desired output format, e.g., "HH:mm" or "DD MMM HH:mm". Defaults to "HH:mm".
 * @returns - The formatted datetime string adjusted to the specified timezone.
 */
export function formatDateTimeWithDisplayTimezone(
  datetime: Nullable<string>,
  displayTimezone: DisplayTimezone,
  format: Nullable<string> = 'HH:mm',
): Nullable<string> {
  const effectiveFormat = format ?? 'HH:mm'
  let formattedDatetime: Nullable<string> = null
  const tzSuffix = displayTimezoneSuffix(displayTimezone)
  let targetTimezoneMinutes: Nullable<number> = null
  if (displayTimezone === DisplayTimezone.UTC) {
    targetTimezoneMinutes = 0
  } else if (datetime) {
    targetTimezoneMinutes = getTimezoneMinutesFromTimestamp(datetime)
  }

  formattedDatetime = formatDateTime(datetime, effectiveFormat, targetTimezoneMinutes)
  if (tzSuffix) {
    if (formattedDatetime) {
      formattedDatetime += ' ' + tzSuffix
    }
  }

  return formattedDatetime
}
