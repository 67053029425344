import { BaseStore } from './base'

import { validate as uuidValidate } from 'uuid'

import { AxiosError, AxiosResponse } from 'axios'

import { Store } from 'pinia-class-component'

import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore'

import { userSearchFirstPage, userSearchMaxResults } from '#views/members/constants'

import { replaceRingName } from '#utils/ring/filter'
import { extractMemberFromUserCloudManagementResponse } from '#utils/user/extract'
import {
  DeletedUsersSearchActionParameters,
  SearchActionParameters,
  UserSearchResponse,
  UserSearchResponseSingleUser,
} from '#utils/user/search'

import {
  BrazeUser,
  Chronotype,
  Member,
  ReproductiveHormoneAnswer,
  UserAccessResponse,
  UserCloudManagementResponse,
  UserDeletionTicketSingleResponse,
  UserDeletionTicketsResponse,
  UserEmailsResponse,
  UserRingsResponse,
  UserZendeskTicketContents,
  UserZendeskTicketResponse,
} from '#types'

export enum ACCOUNT_NAME {
  SEARCH_RESULT_USERS = 'searchResultUsers',
  PERSONAL_ACCOUNTS = 'personalAccounts',
  SUPPORT_ACCOUNTS = 'supportAccounts',
  SHARED_ACCOUNTS = 'sharedAccounts',
}

const ACCOUNT: any = {
  [ACCOUNT_NAME.SEARCH_RESULT_USERS]: 'Search results',
  [ACCOUNT_NAME.PERSONAL_ACCOUNTS]: 'Your own personal accounts',
  [ACCOUNT_NAME.SUPPORT_ACCOUNTS]: 'Added personal / support accounts',
  [ACCOUNT_NAME.SHARED_ACCOUNTS]: 'Accounts shared with every Ouranian',
}

const REQUEST_SOURCE_CHANGE_USER_EMAIL = 'changeUserEmail'

@Store()
export class UserStore extends BaseStore {
  public timezone = 0

  public consent = false

  public dataWait = false

  public searchUserError = ''

  public user: Member | null = null

  public sensitiveDataVisible = false

  public hideRingNames: string[] = []

  public chronotype: Chronotype | null = null

  public ringAuthKey: string | null = null

  public userAccessLevels: any = undefined

  public brazeUsers: BrazeUser[] | null = null

  public usersNext: string | null = null
  public usersPrev: string | null = null
  public usersPage: number = userSearchFirstPage

  public searchUserCount: number | null = null
  public searchUserMaxResults: number | null = null

  public sharedAccounts: UserSearchResponseSingleUser[] = []
  public supportAccounts: UserSearchResponseSingleUser[] = []
  public personalAccounts: UserSearchResponseSingleUser[] = []
  public searchResultUsers: UserSearchResponseSingleUser[] = []

  public userZDTickets: UserZendeskTicketResponse | null = null

  public reproductiveHormoneAnswer: ReproductiveHormoneAnswer | null = null

  public get sharedUsers() {
    let sharedUsers: UserSearchResponseSingleUser[] = []
    if (this.personalAccounts) {
      sharedUsers = sharedUsers.concat(this.personalAccounts)
    }
    if (this.supportAccounts) {
      sharedUsers = sharedUsers.concat(this.supportAccounts)
    }
    if (this.sharedAccounts) {
      sharedUsers = sharedUsers.concat(this.sharedAccounts)
    }
    return sharedUsers
  }

  public get openUserZdTickets(): { count: number; contents: UserZendeskTicketContents[] } {
    const openTickets = this.userZDTickets?.contents.filter((ticket) => ticket.state.toLowerCase() !== 'closed')
    return {
      count: openTickets?.length ?? 0,
      contents: openTickets ?? [],
    }
  }

  public setAccountData(data: { users: UserSearchResponseSingleUser[]; key: ACCOUNT_NAME }) {
    const { users, key } = data
    const userData = users || []

    userData.forEach((user) => {
      user.Group = ACCOUNT[key]
    })

    this[key] = userData
  }

  public setUser(user: any | Member) {
    if (user && typeof user === 'object') {
      if (!user.rings) {
        user.rings = []
      }

      this.user = user
    }
  }

  public async getUser(params: { user: string; agentReason: string; hideRingNames: string[] }) {
    this.user = null
    this.dataWait = true
    this.hideRingNames = params.hideRingNames

    if (!params.user) {
      throw new Error('User ID cannot be empty.')
    }

    if (!params.agentReason) {
      throw new Error('Agent reason cannot be empty.')
    }

    const userDetailsUrl = `/api/v1/users/${params.user}`
    const userAccessUrl = `/api/v1/users/${params.user}/user-access`
    const userEmailsUrl = `/api/v1/users/${params.user}/emails`
    const userRingsUrl = `/api/v1/users/${params.user}/rings`
    const userDeletionTicketsUrl = `/api/v1/users/${params.user}/deletion-tickets`
    let userResponse: AxiosResponse | null = null
    let userAccessLevelResponse: AxiosResponse | null = null
    let userEmailsResponse: AxiosResponse | null = null
    let userRingsResponse: AxiosResponse | null = null
    let userDeletionTicketsResponse: AxiosResponse | null = null
    userResponse = await this.makeRequest(
      { method: 'get', url: userDetailsUrl, params: { agent_reason: params.agentReason } },
      'getUser',
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })
    userAccessLevelResponse = await this.makeRequest({ method: 'get', url: userAccessUrl }, 'getUserAccess').catch(
      (_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      },
    )
    userEmailsResponse = await this.makeRequest({ method: 'get', url: userEmailsUrl }, 'getUserEmails').catch(
      (_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      },
    )
    userRingsResponse = await this.makeRequest({ method: 'get', url: userRingsUrl }, 'getUserRings').catch(
      (_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      },
    )
    userDeletionTicketsResponse = await this.makeRequest(
      { method: 'get', url: userDeletionTicketsUrl },
      'getUserDeletionTickets',
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    const user: UserCloudManagementResponse = userResponse?.data
    this.timezone = user?.timeZone ?? 0
    const userAccess: UserAccessResponse = userAccessLevelResponse?.data
    const userEmails: UserEmailsResponse = userEmailsResponse?.data
    const userRings: UserRingsResponse = userRingsResponse?.data
    const userDeletionTickets: UserDeletionTicketsResponse = userDeletionTicketsResponse?.data

    if (user && userAccess && userEmails && userRings && userDeletionTickets) {
      const userData = extractMemberFromUserCloudManagementResponse(
        user,
        userAccess,
        userEmails,
        userRings,
        userDeletionTickets,
      )
      if (params.hideRingNames) {
        userData.rings = replaceRingName(userData.rings, params.hideRingNames)
      }
      this.setUser(userData)
    }

    this.dataWait = false
  }

  public search(searchParams: SearchActionParameters): Promise<UserSearchResponse | undefined> {
    return this.searchFunction(searchParams, '/api/v1/users/search')
  }

  public searchDeleted(searchParams: DeletedUsersSearchActionParameters): Promise<UserSearchResponse | undefined> {
    return this.searchFunction(searchParams, '/api/v1/users/deleted')
  }

  public async resetSearch() {
    this.searchResultUsers = []
    this.searchUserCount = 0
    this.searchUserMaxResults = userSearchMaxResults
  }

  public async getPersonalAccounts() {
    this.dataWait = true
    const response = await this.makeRequest(
      { method: 'get', url: '/api/v1/users/personal?useDeprecatedSearch=false' },
      'getPersonalAccounts',
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })
    this.dataWait = false
    this.setAccountData({ users: response?.data?.contents || [], key: ACCOUNT_NAME.PERSONAL_ACCOUNTS })
  }

  public async getSupportAccounts() {
    this.dataWait = true
    const response = await this.makeRequest(
      { method: 'get', url: '/api/v1/users/support?useDeprecatedSearch=false' },
      'getSupportAccounts',
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })
    this.dataWait = false
    this.setAccountData({ users: response?.data?.contents || [], key: ACCOUNT_NAME.SUPPORT_ACCOUNTS })
  }

  public async getSharedAccounts() {
    this.dataWait = true
    const response = await this.makeRequest(
      { method: 'get', url: '/api/v1/users/shared?useDeprecatedSearch=false' },
      'getSharedAccounts',
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })
    this.dataWait = false
    this.setAccountData({ users: response?.data?.contents || [], key: ACCOUNT_NAME.SHARED_ACCOUNTS })
  }

  public async createDeleteTicket(params: { user: string; status: string }) {
    this.dataWait = true
    const response = await this.makeRequest(
      { method: 'post', url: `/api/v1/users/${params.user}/delete-ticket`, data: { status: params.status } },
      'createDeleteTicket',
    )

    if (response?.status === 200) {
      await this.getUser({
        user: params.user,
        agentReason: localStorage.getItem('agentReason') ?? '',
        hideRingNames: this.hideRingNames,
      })
    }
    this.dataWait = false

    return response
  }

  public async removeDeleteTicket(user: string) {
    this.dataWait = true
    const response = await this.makeRequest(
      { method: 'delete', url: `/api/v1/users/${user}/delete-ticket` },
      'removeDeleteTicket',
    )
    this.dataWait = false
    const deleteTicketResponse: UserDeletionTicketSingleResponse[] | undefined = response?.data
    if (deleteTicketResponse?.length) {
      await this.getUser({
        user: deleteTicketResponse[0].userUid,
        agentReason: localStorage.getItem('agentReason') ?? '',
        hideRingNames: this.hideRingNames,
      })
    }
  }

  public async changeUserEmail(data: any) {
    const payload = {
      new_email: data.email,
      send_old_email: true,
      send_new_email: true,
    }
    this.dataWait = true
    const response = await this.makeRequest(
      { method: 'post', url: `/api/v1/users/${data.uuid}/change-email`, data: payload },
      REQUEST_SOURCE_CHANGE_USER_EMAIL,
    ).catch((axiosError: AxiosError) => {
      this.handleRequestError(axiosError, REQUEST_SOURCE_CHANGE_USER_EMAIL)
    })
    this.dataWait = false

    if (response?.status === 200) {
      await this.getUser({
        user: data.uuid,
        agentReason: localStorage.getItem('agentReason') ?? '',
        hideRingNames: this.hideRingNames,
      })

      // If user wants to send verification email, we basically just call this.getUser() twice in a row? Does the
      // verification checkbox even work?
      if (data.verify) {
        await this.getUser({
          user: data.uuid,
          agentReason: localStorage.getItem('agentReason') ?? '',
          hideRingNames: this.hideRingNames,
        })
      }
    }

    return response
  }

  public async sendVerificationEmail(uuid: string) {
    await this.makeRequest(
      { method: 'post', url: `/api/v1/users/${uuid}/verify-email`, data: {} },
      'sendVerificationEmail',
    )
    const notify = new CustomEvent('notify', {
      detail: { verificationSent: true },
    })

    document.dispatchEvent(notify)
  }

  public async getBrazeUsers(user: string) {
    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/admin/braze-account?searchEmail=${encodeURIComponent(user)}` },
      'getBrazeUsers',
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })
    this.brazeUsers = response?.data?.users || null
  }

  public async deleteBrazeUsers(users: string[]) {
    let response: AxiosResponse | null = null

    for (const user of users) {
      response = await this.makeRequest(
        { method: 'delete', url: `/api/v1/admin/braze-account?brazeId=${user}` },
        'deleteBrazeUsers',
      )
    }

    this.brazeUsers = null

    // We iterate through multiple users but return response of only the latest one. Not sure if this is intentional?
    return response?.data
  }

  public async getUserZDTickets(uuid: string) {
    this.dataWait = true
    this.userZDTickets = null

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${uuid}/zendesk-tickets` },
      'getUserZDTickets',
    )
      .then((value: AxiosResponse | null) => {
        return value
      })
      .catch((_axiosError: AxiosError) => {
        // Ignore for now
        return null
      })

    this.userZDTickets = response?.data
    this.dataWait = false
  }

  public async getUserAccessLevels(uuid: string) {
    this.dataWait = true
    this.userAccessLevels = null

    if (uuid) {
      if (!uuidValidate(uuid)) {
        throw new Error('User ID is invalid')
      }
      const response = await this.makeRequest(
        { method: 'get', url: `/api/v1/users/${uuid}/user-access` },
        'getUserAccessLevels',
      ).catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })
      this.userAccessLevels = response?.data
    }
    this.dataWait = false
    return this.userAccessLevels
  }

  public async getRingAuthKey(params: { uuid: string; serial: string }) {
    this.ringAuthKey = null
    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${params.uuid}/ring-auth-key/${params.serial}` },
      'getRingAuthKey',
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    if (response?.data?.authKey) {
      // Decode b64 and hex encode the key.
      // This is done to ensure it's in a format that can be pasted straight to ORT
      const key =
        '0x' +
        window
          .atob(response.data.authKey)
          .split('')
          .map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
          .join('')

      this.ringAuthKey = key
    }
  }

  public async getChronotype(uuid: string) {
    this.dataWait = true
    this.chronotype = null
    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${uuid}/chronotype` },
      'getChronotype',
      uuid,
      this.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    if (response?.data?.chronotype) {
      this.chronotype = response?.data?.chronotype
    }
    this.dataWait = false
  }

  public async getReproductiveHormoneAnswer(uuid: string) {
    this.dataWait = true
    this.reproductiveHormoneAnswer = null
    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${uuid}/reproductive-hormone-answer` },
      'getReproductiveHormoneAnswer',
      uuid,
      this.user?.uuid,
    )
      .then((value: AxiosResponse | null) => {
        return value
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    this.reproductiveHormoneAnswer = response?.data
    this.dataWait = false
  }

  public async updateWarrantyNotes(params: { netsuiteId: string; warrantyNotes: string }) {
    await setDoc(doc(getFirestore(), `/warranty/${params.netsuiteId}`), {
      notes: params.warrantyNotes,
    })
  }

  public async setSubscribeAndWarrantyNotes(params: { netsuiteId: string }): Promise<string> {
    let warrantyNotes = ''

    const docRef = await doc(getFirestore(), `/warranty/${params.netsuiteId}`)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      warrantyNotes = docSnap.data().notes
    }
    return warrantyNotes
  }

  /**
   * Perform member search. Returns list of members matching search parameters.
   *
   * @param     searchParams    Search parameters
   * @param     url             Search endpoint URL
   * @returns   {Promise}       undefined if request times out
   */
  public async searchFunction(
    searchParams: SearchActionParameters | DeletedUsersSearchActionParameters,
    url: string,
  ): Promise<UserSearchResponse | undefined> {
    this.setUser(null)
    this.dataWait = true
    this.searchUserCount = 0
    this.searchUserMaxResults = userSearchMaxResults
    this.searchUserError = ''

    const response: UserSearchResponse | undefined = await this.makeRequest(
      { method: 'get', url: url, params: searchParams },
      'searchFunction',
    )
      .then((value: AxiosResponse | null) => {
        return value?.data
      })
      .catch((axiosError: AxiosError) => {
        const requestError = this.handleRequestError(axiosError, 'searchFunction')
        if (requestError.userMessage) {
          this.searchUserError = requestError.userMessage
        }
        return null
      })
    if (response) {
      this.setAccountData({ users: response?.contents ?? [], key: ACCOUNT_NAME.SEARCH_RESULT_USERS })

      this.searchUserCount = response?.count ?? 0
      this.searchUserMaxResults = response?.maxResults ?? userSearchMaxResults
    }
    this.dataWait = false

    return response
  }

  /*
   * Sets sensitive data showing mode and logs / stores it.
   */
  public async setSensitiveDataVisible(value: boolean, uuid?: string) {
    sessionStorage.OuraSensitiveDataVisible = value.toString()

    if (value) {
      await this.makeRequest(
        {
          method: 'post',
          url: `/api/v1/users/${uuid}/log-sensitive-data-view`,
        },
        'logSensitiveDataView',
      )

      this.sensitiveDataVisible = value
    } else {
      this.sensitiveDataVisible = value
    }
  }
}
