export const appsHeaders = [
  {
    title: 'Name',
    key: 'name',
  },

  {
    title: 'UID',
    key: 'uid',
  },
  {
    title: 'Client ID',
    key: 'clientId',
  },
  {
    title: 'Status',
    key: 'status',
  },
  {
    title: 'Users',
    key: 'userCount',
  },
  {
    title: 'Created Date',
    key: 'createdAt',
  },
]

export const appsStatuses = [
  {
    text: 'Development',
    value: 'dev',
  },
  {
    text: 'In review',
    value: 'in_review',
  },
  {
    text: 'Production',
    value: 'production',
  },
  {
    text: 'Changes requested',
    value: 'changes_req',
  },
]

export const appSearchMinLength = 3
export const appSearchMaxLength = 255
