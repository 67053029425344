<template>
  <v-container v-if="returnLabelData">
    <div style="display: none">
      <div ref="printArea" class="pa-4">
        <v-table dense class="print">
          <tbody>
            <tr>
              <td>Return number</td>
              <td>{{ returnLabelData.returnNumber }}</td>
            </tr>
            <tr>
              <td>Return processing date:</td>
              <td>
                {{ processingDateFormatted }}
              </td>
            </tr>
            <tr>
              <td>Return authorization:</td>
              <td>{{ returnLabelData.returnAuthorizationId }}</td>
            </tr>
            <tr>
              <td>Warranty reason:</td>
              <td>{{ returnLabelData.warrantyReason }}</td>
            </tr>
            <tr>
              <td>{{ returnLabelData.deviceType }} serial:</td>
              <td>{{ returnLabelData.serial }}</td>
            </tr>
            <tr>
              <td>Manufacturing info:</td>
              <td>
                {{ returnLabelData.manufacturingWeek }} / {{ returnLabelData.manufacturingYear }} /
                {{ returnLabelData.factory }}
              </td>
            </tr>
            <tr>
              <td>Ring size / model:</td>
              <td>
                {{ returnLabelData.ringSize }} /
                {{ returnLabelData.hwMajor }}
              </td>
            </tr>
            <tr>
              <td>Set Information:</td>
              <td>
                {{ returnLabelData.setInformation }}
              </td>
            </tr>
            <tr>
              <td>Factory reset</td>
              <td>
                <div v-if="true">
                  {{ returnLabelData.factoryResetStatus }}
                  <p>
                    Reason:
                    {{ returnLabelData.factoryResetReason }}
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td>Warranty notes:</td>
              <td>{{ returnLabelData.warrantyNotes }}</td>
            </tr>
          </tbody>
        </v-table>
      </div>
    </div>
  </v-container>
</template>

<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { ReturnLabelData } from '#types'

  @Component({})
  export class PrintReturnLabel extends mixins(DateTime) {
    @Prop() public returnLabelData!: ReturnLabelData | null

    public declare $refs: {
      printArea: HTMLElement
    }

    public get processingDateFormatted(): string {
      return this.formatDateTime(this.returnLabelData?.returnProcessingDate, 'DD MMM YYYY HH:MM')
    }

    public openPrintPreview() {
      setTimeout(() => {
        const printWindow: any = window.open('', '', 'width=900,height=600')

        if (printWindow) {
          printWindow?.document.write(this.$refs.printArea.innerHTML)

          document.head.querySelectorAll('link, style').forEach((htmlElement) => {
            printWindow?.document.head.appendChild(htmlElement.cloneNode(true))
          })

          let sheet = printWindow.document.createElement('style')

          sheet.innerHTML = '@page {margin: 0.5cm 0.5cm 0cm 0.5cm;}'
          printWindow.document.body.appendChild(sheet)

          printWindow.document.documentElement.style['font-size'] = '10px'

          printWindow?.document.close()
          printWindow?.focus()

          printWindow.onload = () => {
            printWindow?.print()
            printWindow?.close()
          }
        }
      }, 100)
    }
  }

  export default toNative(PrintReturnLabel)
</script>

<style lang="scss" scoped>
  .print {
    td {
      padding: 0 !important;
      height: 1.4rem !important;
      border: 1px solid rgba(88, 87, 87, 0.25) !important;
      font-size: 1rem !important;
      line-height: 1rem !important;
    }
  }

  :deep(.v-dialog) {
    .v-card {
      box-shadow: none !important;
    }
  }
</style>
