import Papa from 'papaparse'

import dayjs from 'dayjs'

import { AuditLog } from '#types'

// convert log data to csv format
export function generateCSVData(data: AuditLog[]) {
  const csvHeader = [
    'Index',
    'Created at',
    'Event type',
    'Email',
    'Oura user ID',
    'Oura user email',
    'Request source',
    'Organization ID',
    'Organization name',
    'Ticket',
    'Regexp',
    'Search envs',
    'Search flags',
    'Search phrase',
    'Right groups',
  ]

  const csvRows = data.map((item: AuditLog, index: number) => {
    const ouraUserID = item.ouraUserUid ?? ''
    const ouraUserEmail = item.ouraUserEmail ?? ''
    const requestSource = item.requestSource ?? ''
    const organizationID = item.organizationUid ?? ''
    const organizationName = item.organizationName ?? ''
    const rightGroups = item.userData?.groups?.map((group) => group).join(';') || ''
    const ticket = item.json?.ticket ?? ''
    const regexp = item.json?.regexp ?? ''
    const searchEnvs = item.json?.searchEnvs?.join(';') ?? ''
    const searchFlags = item.json?.searchFlags?.join(';') ?? ''
    const searchPhrase = item.json?.searchPhrase ?? ''

    return [
      index + 1,
      item.createdAt,
      item.eventType,
      item.email,
      ouraUserID,
      ouraUserEmail,
      requestSource,
      organizationID,
      organizationName,
      ticket,
      regexp,
      searchEnvs,
      searchFlags,
      searchPhrase,
      rightGroups,
    ]
  })

  csvRows.forEach((row) => {
    row[1] = dayjs(row[1]).format('HH:mm:ss - DD MMM YYYY') + ' UTC'
  })

  const csvData = Papa.unparse({
    fields: csvHeader,
    data: csvRows,
  })

  return csvData
}
