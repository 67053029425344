<template>
  <v-dialog v-model="open" width="950" @click:outside="closeDialogNewFeature()">
    <v-card>
      <v-card-title>New in Darwin!</v-card-title>

      <v-card-text class="pb-0">
        <div class="px-3">
          Member account details page has now new fresh look and improved usability. The main changes are:
          <br />
          <br />

          <ol class="px-5">
            <li class="py-2">
              The page has been split into three sections: 'Account information', 'Health information' and 'Raw data
              browser'. Most of the information can be still found under the 'Account information' with the familiar sub
              sections, but health related tooling has now its own 'Health information' section (requires special
              permissions to access). Also all the existing raw data related sub sections have been moved under the new
              'Raw data browser' section.
            </li>
            <li class="py-2">
              Timezone and sensitive data viewing toggle and HIPAA status has moved from the primary top toolbar to the
              secondary toolbar together with the account actions (requires special permissions to access).
            </li>
            <li class="py-2">
              The ring selector is now more compact and able to show all the members previous rings without scrolling.
            </li>
          </ol>

          <v-img class="mx-1 mt-8" src="/ui-refresh-screenshot.png" />
        </div>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn variant="text" @click="closeDialogNewFeature()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { PrefsStore } from '#stores'

  @Component
  class NewFeature extends Vue {
    public open = false

    public feature = 'memberDetailsRefresh'

    protected readonly prefsStore = new PrefsStore()

    public get newFeature() {
      return this.prefsStore.newFeature
    }

    public mounted() {
      if (this.newFeature !== this.feature) {
        this.open = true
      } else {
        this.$emit('close')
      }
    }

    public closeDialogNewFeature() {
      this.open = false

      this.prefsStore.updateNewFeature(this.feature)

      this.$emit('close')
    }
  }

  export default toNative(NewFeature)
</script>
