import { AxiosError, AxiosResponse } from 'axios'

import { omit } from 'lodash-es'

import { Store } from 'pinia-class-component'

import { BaseStore } from '#stores/base'
import { UserStore } from '#stores/user'

import { BatteryDiagnosticsResponse, CycleInsights, Diagnostic, Diagnostics } from '#types'

@Store()
export class DiagnosticsStore extends BaseStore {
  public dataWait = false

  public batteryLifeEstimate = ''

  public cycleInsights: CycleInsights | null = null
  public vo2MaxDiagnostics: Diagnostic | null = null
  public stressDiagnostics: Diagnostics | null = null
  public deviceDiagnostics: Diagnostics | null = null
  public versionDiagnostics: Diagnostics | null = null
  public signupFlowDiagnostics: Diagnostics | null = null
  public resilienceDiagnostics: Diagnostics | null = null
  public stressErrorDiagnostics: Diagnostics | null = null
  public oldRingInUseDiagnostic: Diagnostics | null = null
  public connectivityDiagnostics: Diagnostics | null = null
  public boughtFromPartnerDiagnostic: Diagnostic | null = null
  public ringReplacementsDiagnostics: Diagnostics | null = null
  public automaticActivityDiagnostic: Diagnostics | null = null
  public cardioVascularAgeDiagnostics: Diagnostics | null = null
  public multipleRingsInUseDiagnostic: Diagnostics | null = null
  public previouslyOwnedRingDiagnostics: Diagnostics | null = null
  public partnershipIntegrationDiagnotics: Diagnostics | null = null
  public batteryDiagnostics: BatteryDiagnosticsResponse | null = null

  public async getVersionDiagnostics(ringSerial: string) {
    this.versionDiagnostics = null
    this.dataWait = true

    this.makeRequest(
      {
        method: 'get',
        url: `/api/v1/rings/${ringSerial}/diagnostics/firmware-version`,
      },
      'getVersionDiagnostics',
    )
      .then((value: AxiosResponse | null) => {
        this.versionDiagnostics = value?.data
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    this.dataWait = false
  }

  public async getMultipleRingsInUseDiagnostic(uuid: string) {
    this.multipleRingsInUseDiagnostic = null
    this.dataWait = true

    this.makeRequest(
      { method: 'get', url: `/api/v1/users/${uuid}/diagnostics/multiple-rings-in-use` },
      'getMultipleRingsInUse',
    )
      .then((value: AxiosResponse | null) => {
        this.multipleRingsInUseDiagnostic = value?.data
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    this.dataWait = false
  }

  public async getDeviceDiagnostics(uuid: string) {
    this.deviceDiagnostics = null
    this.dataWait = true

    this.makeRequest({ method: 'get', url: `/api/v1/users/${uuid}/diagnostics/versions` }, 'getDeviceDiagnostics')
      .then((value: AxiosResponse | null) => {
        this.deviceDiagnostics = value?.data
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    this.dataWait = false
  }

  public async getCycleInsights(uuid: string) {
    this.cycleInsights = null
    this.dataWait = true

    this.makeRequest({ method: 'get', url: `/api/v1/users/${uuid}/diagnostics/cycle-insights` }, 'getCycleInsights')
      .then((value: AxiosResponse | null) => {
        const resData = value?.data
        if (resData?.extraInfo?.headers) {
          const headers = resData.extraInfo.headers
          resData.extraInfo.headers = this.headersKeyNameChange(headers)
        }
        this.cycleInsights = resData
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    this.dataWait = false
  }

  public async getBatteryDiagnostics(serial: string) {
    this.dataWait = true
    this.batteryDiagnostics = null

    this.makeRequest(
      {
        method: 'get',
        url: `/api/v1/rings/${serial.split(',')[0]}/diagnostics/battery`,
        params: { useAssa: true },
      },
      'getBatteryDiagnostics',
      null,
      null,
      false,
    )
      .then((value: AxiosResponse | null) => {
        const resData = value?.data
        if (resData?.extraInfo?.headers) {
          const headers = resData.extraInfo.headers
          resData.extraInfo.headers = this.headersKeyNameChange(headers)
        }
        this.batteryDiagnostics = resData
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    // Set here since sometimes the diagnostic is marked as ready before the processing is done
    // this sometimes leads to battery diagnostic not being visible.
    // Note: This is a bit hacky, but we're getting rid of this anyway when we move to Diagnostics v3
    this.updateDataWait({ source: 'getBatteryDiagnostics', wait: false })
    this.dataWait = false
  }

  private headersKeyNameChange(headers: { text: string; value: string }[] | { title: string; key: string }[]) {
    return headers.map((header: any) => {
      if (header?.title || header?.key) {
        return header
      }
      return Object.assign(
        {
          title: header.text,
          key: header.value,
        },
        omit(header, 'text', 'value'),
      )
    })
  }

  public async getStressDiagnostics(uuid: string) {
    this.stressDiagnostics = null
    this.dataWait = true

    this.makeRequest({ method: 'get', url: `/api/v1/users/${uuid}/diagnostics/stress-feature` }, 'getStressDiagnostics')
      .then((value: AxiosResponse | null) => {
        this.stressDiagnostics = value?.data
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    this.dataWait = false
  }

  public async getStressErrorDiagnostics(uuid: string) {
    this.stressErrorDiagnostics = null
    this.dataWait = true

    this.makeRequest(
      { method: 'get', url: `/api/v1/users/${uuid}/diagnostics/stress-error` },
      'getStressErrorDiagnostics',
    )
      .then((value: AxiosResponse | null) => {
        this.stressErrorDiagnostics = value?.data
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })
    this.dataWait = false
  }

  public async getSignupFlowDiagnostics(uuid: string) {
    this.signupFlowDiagnostics = null
    this.dataWait = true

    this.makeRequest(
      { method: 'get', url: `/api/v1/users/${uuid}/diagnostics/signup-flow` },
      'getSignupFlowDiagnostics',
    )
      .then((value: AxiosResponse | null) => {
        this.signupFlowDiagnostics = value?.data
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    this.dataWait = false
  }

  public async getAutomaticActivityDiagnostics(uuid: string) {
    this.automaticActivityDiagnostic = null
    this.dataWait = true

    this.makeRequest(
      { method: 'get', url: `/api/v1/users/${uuid}/diagnostics-feature-flag/activity/aad_2_0` },
      'getAutomaticActivityDiagnostics',
    )
      .then((value: AxiosResponse | null) => {
        this.automaticActivityDiagnostic = value?.data
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    this.dataWait = false
  }

  public async getResilienceDiagnostics(uuid: string) {
    this.resilienceDiagnostics = null
    this.dataWait = true

    await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${uuid}/diagnostics/resilience-feature` },
      'getResilienceDiagnostics',
    )
      .then((value: AxiosResponse | null) => {
        this.resilienceDiagnostics = value?.data
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    this.dataWait = false
  }

  public async getPartnershipIntegrationDiagnostic(uuid: string) {
    this.partnershipIntegrationDiagnotics = null
    this.dataWait = true

    await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${uuid}/diagnostics/partnership-integration` },
      'getPartnershipIntegrationDiagnostics',
    )
      .then((value: AxiosResponse | null) => {
        this.partnershipIntegrationDiagnotics = value?.data
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    this.dataWait = false
  }

  public async getConnectivityDiagnostics(serial: string) {
    this.connectivityDiagnostics = null
    this.dataWait = true

    await this.makeRequest(
      { method: 'get', url: `/api/v1/rings/${serial}/diagnostics/ring-connectivity-diagnostic` },
      'getConnectivityDiagnostics',
    )
      .then((value: AxiosResponse | null) => {
        this.connectivityDiagnostics = value?.data
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    this.dataWait = false
  }

  public async getRingReplacementsDiagnostics(uuid: string) {
    this.ringReplacementsDiagnostics = null
    this.dataWait = true

    await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${uuid}/diagnostics/ring-replacements` },
      'getRingReplacementsDiagnostics',
    )
      .then((value: AxiosResponse | null) => {
        this.ringReplacementsDiagnostics = value?.data
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    this.dataWait = false
  }

  public async getOldRingInUseDiagnostic(uuid: string) {
    this.oldRingInUseDiagnostic = null
    this.dataWait = true

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${uuid}/diagnostics/old-ring-in-use` },
      'getOldRingInUseDiagnostic',
    ).catch((_axiosError: AxiosError) => {
      // Ignore for now
      return null
    })
    this.oldRingInUseDiagnostic = response?.data
    this.dataWait = false
  }

  public async getPreviouslyOwnedRingDiagnostics(uuid: string, serial: string) {
    this.previouslyOwnedRingDiagnostics = null
    this.dataWait = true
    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${uuid}/rings/${serial}/diagnostics/pre-owned-ring` },
      'getPreviouslyOwnedRingDiagnostics',
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
    })
    this.dataWait = false
    if (response?.data) {
      this.previouslyOwnedRingDiagnostics = response?.data
    }
  }

  public async getCardioVascularAgeDiagnostics(uuid: string) {
    this.cardioVascularAgeDiagnostics = null
    this.dataWait = true
    const userStore = new UserStore()
    const sensitiveDataVisible = userStore.sensitiveDataVisible
    const params = {
      show_sensitive_data: sensitiveDataVisible ? 'true' : 'false',
    }

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${uuid}/diagnostics/cardio-vascular-age`, params: params },
      'getCardioVascularAgeDiagnostics',
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })
    this.dataWait = false
    if (response?.data) {
      this.cardioVascularAgeDiagnostics = response?.data
    }
  }

  public async getVo2MaxDiagnostics(uuid: string) {
    this.vo2MaxDiagnostics = null
    this.dataWait = true

    const userStore = new UserStore()
    const sensitiveDataVisible = userStore.sensitiveDataVisible
    const params = {
      show_sensitive_data: sensitiveDataVisible ? 'true' : 'false',
    }

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${uuid}/diagnostics/vo2max`, params: params },
      'getVo2MaxDiagnostics',
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })
    this.dataWait = false
    if (response?.data) {
      this.vo2MaxDiagnostics = response?.data
    }
  }

  public async getBoughtFromPartnerDiagnostic(ringSerial: string) {
    this.boughtFromPartnerDiagnostic = null
    this.dataWait = true

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/rings/${ringSerial}/diagnostics/bought-from-partner` },
      'getBoughtFromPartnerDiagnostic',
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    this.boughtFromPartnerDiagnostic = response?.data

    this.dataWait = false
  }
}
