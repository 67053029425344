import { Nullable } from '#types'

export function isEmailValid(email: string, appEnv: Nullable<string> = null): boolean {
  const emailPattern = /^[\w.+-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/
  const emailSandboxPattern = /@(ouraring\.com|ringtesting\.com)$/

  const validateEmail = emailPattern.test(email)
  const validateSandboxEmail = emailSandboxPattern.test(email)

  if (appEnv && ['preview', 'testing'].includes(appEnv)) {
    return validateEmail && validateSandboxEmail
  }
  return validateEmail
}

export function getEmailValidationErrorMessage(env: string): string {
  return `Invalid email.${['preview', 'testing'].includes(env) ? ' Domain should be either ouraring.com or ringtesting.com' : ''}`
}
