<template>
  <div>
    <v-card :loading="dataWait">
      <v-card-title>
        <div class="text-overline">Client config changes</div>
      </v-card-title>

      <v-card-text>
        <v-toolbar>
          <v-row align="center">
            <v-col class="d-flex flex-row align-start justify-center" cols="12">
              <div>
                <v-tooltip location="bottom">
                  <template #activator="{ props }">
                    <v-btn class="mt-1" variant="plain" :disabled="isLastElement" @click="arrowClick(-1)">
                      <v-icon v-bind="props">mdi-arrow-left-thick</v-icon>
                    </v-btn>
                  </template>
                  <span>Compare this event and previous</span>
                </v-tooltip>
              </div>

              <div class="mr-4">
                <v-tooltip v-if="!showStartDateConfigSelected()" location="bottom">
                  <template #activator="{ props }">
                    <v-btn
                      class="mt-1"
                      variant="plain"
                      :disabled="!startDateButtonEnabled"
                      @click="showStartDateConfig()"
                    >
                      <v-icon v-bind="props">mdi-unfold-more-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <span>Show full config</span>
                </v-tooltip>

                <v-tooltip v-else location="bottom">
                  <template #activator="{ props }">
                    <v-btn variant="plain" :disabled="!startDateButtonEnabled" @click="showDiff()">
                      <v-icon v-bind="props">mdi-unfold-less-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <span>Show difference between configs</span>
                </v-tooltip>
              </div>

              <v-select
                variant="outlined"
                rounded
                density="compact"
                :model-value="startDateSelected"
                :items="dropdownValuesForStartDate"
                :disabled="showEndDateConfigSelected()"
                label="Start date"
                style="text-align: right; min-width: 250px; max-width: 250px"
                @update:model-value="selectStartDate($event)"
              />
              <span class="ma-3">-</span>
              <v-select
                variant="outlined"
                rounded
                density="compact"
                :disabled="!startDateSelected || showStartDateConfigSelected()"
                :model-value="endDateSelected"
                :items="dropdownValuesForEndDate"
                min-width="200px"
                label="End date"
                style="text-align: right; min-width: 250px; max-width: 250px"
                @update:model-value="selectEndDate($event)"
              />

              <div class="ml-4">
                <v-tooltip v-if="!showEndDateConfigSelected()" location="bottom">
                  <template #activator="{ props }">
                    <v-btn class="mt-1" variant="plain" @click="showEndDateConfig()">
                      <v-icon v-bind="props">mdi-unfold-more-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <span>Show full config</span>
                </v-tooltip>

                <v-tooltip v-else location="bottom">
                  <template #activator="{ props }">
                    <v-btn class="mt-1" variant="plain" @click="showDiff()">
                      <v-icon v-bind="props">mdi-unfold-less-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <span>Show difference between configs</span>
                </v-tooltip>
              </div>

              <div>
                <v-tooltip location="bottom">
                  <template #activator="{ props }">
                    <v-btn class="mt-1" variant="plain" :disabled="isFirstElement" @click="arrowClick(1)">
                      <v-icon v-bind="props">mdi-arrow-right-thick</v-icon>
                    </v-btn>
                  </template>
                  <span>Compare this event and next</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-toolbar>

        <div style="position: relative">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-if="!dataWait" class="diff-container" v-html="htmlDiff" />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
  import { format } from 'util'

  import sortKeys from 'sort-keys'

  import * as Diff2Html from 'diff2html'
  import { ColorSchemeType } from 'diff2html/lib/types'

  import { createTwoFilesPatch } from 'diff'

  import { ThemeInstance, useTheme } from 'vuetify'

  import { Component, Prop, Setup, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { DateTime } from '#mixins/dateTime'

  import { ConfigStore } from '#stores'

  enum ConfigToShow {
    StartDateConfig, // Show full start date client config
    EndDateConfig, // Show full end date client config
    Difference, // Show difference between start and end date client config
  }

  @Component
  class ClientConfigDiff extends mixins(DateTime) {
    @Prop() public uuid!: string
    @Prop() public range!: boolean
    @Prop() public ringSerial!: string

    @Setup(() => useTheme())
    public readonly theme: ThemeInstance | undefined

    public configStore = new ConfigStore()

    public endDateSelected: string | null = null
    public startDateSelected: string | null = null
    public isFirstElement: boolean = false
    public isLastElement: boolean = false
    public configToShow: ConfigToShow = ConfigToShow.Difference

    public get configs() {
      return this.configStore.configs || []
    }

    public get configEventDates() {
      return this.configStore.configEventDates.map((event) => event.createdAt).reverse() || []
    }

    public get dropdownValues() {
      return this.configEventDates.map((val) => {
        return {
          title: this.formatDateTime(val, 'DD MMM YYYY HH:mm:ss'),
          value: val,
        }
      })
    }

    public get dropdownValuesForStartDate() {
      return this.dropdownValues.filter(
        (item) =>
          !this.endDateSelected ||
          this.$dayjs(item.value).isBefore(this.endDateSelected) ||
          item.value === this.endDateSelected,
      )
    }

    public get dropdownValuesForEndDate() {
      return this.dropdownValues.filter(
        (item) =>
          !this.startDateSelected ||
          this.$dayjs(item.value).isAfter(this.startDateSelected) ||
          item.value === this.startDateSelected,
      )
    }

    public get dataWait() {
      return this.configStore.dataWait || false
    }

    /**
     * Both start and end date has been selected and both configs are present
     */
    public bothConfigsArePresent(): boolean {
      return this.configs[0] && this.configs[1]
    }

    public get htmlDiff(): string {
      let diff: string | null = ''
      switch (this.configToShow) {
        case ConfigToShow.StartDateConfig:
          diff = this.getDiff(null, this.configs[0])
          break

        case ConfigToShow.EndDateConfig:
          if (this.bothConfigsArePresent()) {
            diff = this.getDiff(null, this.configs[1])
          } else {
            diff = this.getDiff(null, this.configs[0])
          }
          break

        case ConfigToShow.Difference:
        default:
          diff = this.getDiff(
            this.configs[1] ? this.configs[0] : null,
            this.configs[1] ? this.configs[1] : this.configs[0],
          )
          break
      }

      const colorScheme = this.theme?.global?.current?.value?.dark ? ColorSchemeType.DARK : ColorSchemeType.LIGHT
      return diff
        ? Diff2Html.html(diff, { drawFileList: false, colorScheme: colorScheme })
        : 'No changes found between the two given dates/times'
    }

    @Watch('uuid')
    protected onUUIDChanged(_val: string, _oldVal: string) {
      this.updateData()
    }

    public async mounted() {
      logEvent(this.$analytics, 'page_view', {
        page_title: 'Client Config',
        page_location: window.location.toString().split('?')[0],
      })
      await this.updateData()
      this.endDateSelected = this.configEventDates[0]
      this.setButtonDisableData()
    }

    public selectStartDate(startDate: string) {
      this.showDiff()
      this.startDateSelected = startDate
      this.updateDiff()
    }

    public selectEndDate(endDate: string) {
      this.endDateSelected = endDate
      this.updateDiff()
    }

    public changeConfigToShow(configToShow: ConfigToShow) {
      this.configToShow = configToShow
    }

    public get startDateButtonEnabled(): boolean {
      return !!this.startDateSelected
    }

    /**
     * Show start date client config
     */
    public showStartDateConfig() {
      this.changeConfigToShow(ConfigToShow.StartDateConfig)
    }

    /**
     * Show difference between start and end date client configs
     */
    public showDiff() {
      this.changeConfigToShow(ConfigToShow.Difference)
    }

    /**
     * Show end date client config
     */
    public showEndDateConfig() {
      this.changeConfigToShow(ConfigToShow.EndDateConfig)
    }

    public showEndDateConfigSelected(): boolean {
      return this.configToShow === ConfigToShow.EndDateConfig
    }

    public showStartDateConfigSelected(): boolean {
      return this.configToShow === ConfigToShow.StartDateConfig
    }

    public arrowClick(number: number) {
      this.showDiff()
      const positionStartDate = this.configEventDates.findIndex((time: any) => time === this.startDateSelected)
      const positionEndDate = this.configEventDates.findIndex((time: any) => time === this.endDateSelected)
      if (number === 1) {
        this.startDateSelected = this.endDateSelected

        this.endDateSelected = this.configEventDates[positionEndDate - 1]
      } else {
        this.endDateSelected = this.startDateSelected || this.endDateSelected

        this.startDateSelected = this.startDateSelected
          ? this.configEventDates[positionStartDate + 1]
          : this.configEventDates[positionEndDate + 1]
      }

      this.updateDiff()
    }

    private setButtonDisableData() {
      const positionStartDate = this.configEventDates.findIndex((time: any) => time === this.startDateSelected)
      const positionEndDate = this.configEventDates.findIndex((time: any) => time === this.endDateSelected)
      this.isFirstElement = positionStartDate === 0 || !this.configEventDates.length || positionEndDate === 0
      this.isLastElement = positionStartDate === this.configEventDates.length - 1
    }

    private getDiff(before: any, after: any) {
      // Get rid of setters & getters because sortKeys breaks otherwise
      after = typeof after == 'object' ? JSON.parse(JSON.stringify(after)) : after
      before = typeof before == 'object' ? JSON.parse(JSON.stringify(before)) : before

      const name =
        (before ? this.formatDateTime(before.timestamp, 'DD MMM YYYY HH:mm:ss') : '') +
        (before && after ? ' → ' : '') +
        (after ? this.formatDateTime(after.timestamp, 'DD MMM YYYY HH:mm:ss') : '')

      const stringify = (data: any) => (data ? JSON.stringify(sortKeys(data, { deep: true }), null, '  ') : '')
      const diff = createTwoFilesPatch(name, name, stringify(before), stringify(after))

      if (diff) {
        return format('diff --git a/%s b/%s\n%s', name, name, diff)
      }

      return null
    }

    public async updateDiff() {
      const start = this.startDateSelected ? new Date(this.startDateSelected) : null
      const end = this.endDateSelected ? new Date(this.endDateSelected) : null
      start?.setMilliseconds(start.getMilliseconds() - 1)
      end?.setMilliseconds(end.getMilliseconds() + 1)

      await this.configStore.clientConfigs({
        uuid: this.uuid,
        from: start ? start?.toISOString() : null,
        to: end ? end.toISOString() : null,
      })

      this.setButtonDisableData()
    }

    public async updateData() {
      if (this.uuid) {
        await this.configStore.getConfigEventDates({
          uuid: this.uuid,
        })
        await this.updateDiff()
      }
    }
  }

  export default toNative(ClientConfigDiff)
</script>

<style lang="scss" scoped>
  .diff-container {
    /** This fixes an issue where the line numbers of diffs would scroll. */
    position: relative;
  }
</style>
