import { AxiosError } from 'axios'

import { Store } from 'pinia-class-component'

import { BaseStore } from '#stores/base'

import { CreateBulkUserBody, CreateBulkUserParams, GetLogFileParams, GetResultsFilesParams, TaskDetails } from '#types'

const BULK_CRATE_USERS_JOB_NAME = 'bulk-create-users'

interface GetTaskDetailsParams {
  taskId: string
  env: string
  fileType: string
}

@Store()
export class TasksStore extends BaseStore {
  public dataWait = false
  public filesWait = false

  public logs: any = {
    logs: [],

    logsId: '',
    logsToken: '',

    more: false,
    logsWait: false,
  }

  public files: any[] = []

  public bulkUser: any = null
  public bulkUsers: any[] = []

  public logFileParams: GetLogFileParams | null = null

  public resultsFileParams: GetResultsFilesParams | null = null

  public async listBulkUsers(initial?: boolean) {
    if (initial) {
      this.dataWait = true
    }
    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/tasks/${BULK_CRATE_USERS_JOB_NAME}` },
      'listBulkUsers',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    if (initial) {
      this.dataWait = false
    }
    this.bulkUsers = response?.data || []
  }

  public async createBulkUser(params: CreateBulkUserParams) {
    const formData = new FormData()
    // We should validate data on client side before sending it to backend
    formData.append('file', params.emailFile)
    formData.append('prefix', `bulk_users`)
    formData.append('bucket', 'results')

    const emailUpload = await this.makeRequest(
      { method: 'post', url: '/api/v1/files', data: formData },
      'createBulkUserEmail',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })

    if (emailUpload?.data) {
      const bucketPath = emailUpload.data.path
      this.dataWait = true

      // We should validate data on client side before sending it to backend
      const payload: CreateBulkUserBody = {
        info: {
          user_counts: params.userCounts,
        },
        parameters: {
          batch_create_user_job_input_file: `s3://${bucketPath}`,
          batch_create_user_job_comment: params.name,
          batch_create_user_job_send_email: params.sendEmail + '',
          batch_create_user_job_bypass_email_verification: params.bypassEmailVerification + '',
          batch_create_user_job_label: params.label + '',
        },
      }

      const response = await this.makeRequest(
        { method: 'post', url: `/api/v1/tasks/${BULK_CRATE_USERS_JOB_NAME}`, data: payload },
        'createBulkUser',
      ).catch((_axiosError: AxiosError) => {
        //ignore for now
        return null
      })
      this.dataWait = false
      await this.listBulkUsers()
      return response
    }
    return emailUpload
  }

  public async getLogs(uid: string) {
    const { logsId, logsToken } = this.logs
    let url = `/api/v1/tasks/batch-jobs/${BULK_CRATE_USERS_JOB_NAME}/${uid}/logs?startFromHead=true`
    if (uid === logsId && logsToken) {
      url += `&continuationToken=${logsToken}`
    }
    this.logs.logsWait = true
    const response = await this.makeRequest({ method: 'get', url: url }, 'getLogs').catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.logs.logsWait = false

    if (logsId === uid) {
      this.logs.logs = [...this.logs.logs.concat(response?.data?.events || [])]
    } else if (response?.data?.events) {
      this.logs.logs = response?.data?.events
    }

    this.logs.more = !!response?.data?.nextForwardToken
    this.logs.logsId = uid
    this.logs.logsToken = response?.data?.nextForwardToken

    return response?.data
  }

  public async getResultsFiles(params: GetResultsFilesParams) {
    this.filesWait = true
    this.files = []
    const response = await this.makeRequest(
      {
        method: 'get',
        url: `/api/v1/files?bucket=results&search_prefix=bulk_user/results/${params.jobId}/&env=${params.env}`,
      },
      'getResultsFiles',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.filesWait = false
    this.files = response?.data?.contents || []
  }

  public async getTaskDetails(params: GetTaskDetailsParams): Promise<TaskDetails> {
    this.dataWait = true
    const response = await this.makeRequest(
      {
        method: 'get',
        url: `/api/v1/tasks/${BULK_CRATE_USERS_JOB_NAME}/${params.taskId}`,
      },
      'getTaskDetails',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.dataWait = false
    const taskDetails: TaskDetails = response?.data
    if (taskDetails?.batchJobs[0]?.uid) {
      switch (params.fileType) {
        case 'results':
          this.resultsFileParams = { jobId: taskDetails.batchJobs[0].uid, env: params.env }
          break
        case 'log':
          this.logFileParams = { jobId: taskDetails.batchJobs[0].uid }
          break
      }
    }
    return taskDetails
  }
}
