import { WarrantyStateStatus } from '#types'

export enum WarrantyState {
  InWarranty = 'In warranty',
  OutOfWarranty = 'Out of warranty',
  InEscWarranty = 'Warranty',
  Unknown = 'unknown',
}

export function getWarrantyInfo(
  warrantyStartDate?: string | null,
  warrantyEndDate?: string | null,
  escEndDate?: string | null,
): WarrantyStateStatus {
  const state = getState(warrantyStartDate!, warrantyEndDate!, escEndDate!)
  const warrantyState = getWarrantyState(state!)

  return { state, warrantyState }
}

export function getWarrantyState(state: WarrantyState): WarrantyStateStatus['warrantyState'] {
  switch (state) {
    case WarrantyState.InWarranty:
      return 'success'
    case WarrantyState.OutOfWarranty:
      return 'error'
    case WarrantyState.InEscWarranty:
      return 'warning'
    default:
      return 'not_enough_data'
  }
}

export function getState(warrantyStartDate: string | null, warrantyEndDate: string | null, escEndDate: string | null) {
  if (!warrantyStartDate || !(warrantyEndDate || escEndDate)) {
    return WarrantyState.Unknown
  }

  const startTimestamp = new Date(warrantyStartDate).getTime()
  const currentTime = Date.now()

  const warrantyEndTimestamp = warrantyEndDate ? new Date(warrantyEndDate).getTime() : null
  const warrantyEscEndDate = escEndDate ? new Date(escEndDate).getTime() : null

  // If the warranty hasn't started yet - return Unknown
  if (currentTime <= startTimestamp) {
    return WarrantyState.Unknown
  }

  if (warrantyEndTimestamp && currentTime <= warrantyEndTimestamp) {
    return WarrantyState.InWarranty
  }

  if (warrantyEscEndDate && currentTime <= warrantyEscEndDate) {
    return WarrantyState.InEscWarranty
  }

  return WarrantyState.OutOfWarranty
}
