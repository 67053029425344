<template>
  <v-dialog :model-value="isPDFVisible" width="880" @after-leave="isPDFVisible = false">
    <v-card>
      <v-card-title>PDF Document</v-card-title>
      <iframe v-if="pdf" title="pdf" :src="pdf + '#toolbar=0'" width="100%" height="700px" />
      <v-card-text v-else>PDF not found</v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Model, Prop, Vue, toNative } from 'vue-facing-decorator'

  @Component
  class PDFDialog extends Vue {
    @Prop({ default: false }) public pdfVisible!: boolean
    @Prop({ default: null }) public pdf!: boolean

    @Model
    public isPDFVisible!: boolean
  }

  export default toNative(PDFDialog)
</script>
