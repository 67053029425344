import {
  AvailableDiagnostic,
  AvailableDiagnosticResponseItem,
  Diagnostic,
  DiagnosticCategory,
  DiagnosticResponse,
} from '#types'

function createVersionFeatureFlagName(diagnosticId: string): string {
  let featureFlagName = 'diagnosticVersion' + diagnosticId[0].toUpperCase() + diagnosticId.slice(1) // capitalize first letter of diagnosticId
  featureFlagName = featureFlagName.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '')) // convert to camelCase
  return featureFlagName
}

export function extractAvailableDiagnostics(response: AvailableDiagnosticResponseItem[]): AvailableDiagnostic[] {
  const availableDiagnostics: AvailableDiagnostic[] = []

  for (const item of response) {
    availableDiagnostics.push({
      diagnosticId: item.name,
      category: DiagnosticCategory[item.category as keyof typeof DiagnosticCategory],
      versions: item.versions,
      versionFeatureFlagName: createVersionFeatureFlagName(item.name),
    })
  }

  return availableDiagnostics
}

export function extractDiagnostic(
  response: DiagnosticResponse,
  diagnosticId: string,
  macroId: string,
  isHidden: boolean,
): Diagnostic {
  return { ...response, diagnosticId: diagnosticId, macroId: macroId, isHidden: isHidden }
}
