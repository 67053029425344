export const allFiltersEvents = [
  {
    text: 'User searches',
    value: 'SEARCH_USER',
  },
  {
    text: 'User data views',
    value: 'VIEW_USER',
  },
  {
    text: 'Debug log searches',
    value: 'SEARCH_DEBUGLOG',
  },
  {
    text: 'User email changes',
    value: 'CHANGE_EMAIL',
  },
  {
    text: 'Verification emails',
    value: 'SEND_VERIFICATION',
  },

  {
    text: 'Added Oura account',
    value: 'SEND_ACCESS_REQUEST',
  },

  {
    text: 'Queuing user deletion',
    value: 'QUEUE_USER_DELETION',
  },
  {
    text: 'Canceling user deletion',
    value: 'CANCEL_USER_DELETION',
  },

  {
    text: 'Organization creations',
    value: 'CREATE_ORG',
  },
  {
    text: 'Organization edits',
    value: 'EDIT_ORG',
  },
  {
    text: 'Organization deletions',
    value: 'DELETE_ORG',
  },

  {
    text: 'Created shared account',
    value: 'CREATE_SHARED_ACCOUNT',
  },
  {
    text: 'Deleted shared account',
    value: 'DELETE_SHARED_ACCOUNT',
  },
  {
    text: 'Edited shared account',
    value: 'EDIT_SHARED_ACCOUNT',
  },

  {
    text: 'Created org subscription',
    value: 'CREATE_ORG_SUBSCRIPTION',
  },
  {
    text: 'Deleted org subscription',
    value: 'DELETE_ORG_SUBSCRIPTION',
  },
  {
    text: 'Modified org subscription',
    value: 'MODIFY_ORG_SUBSCRIPTION',
  },

  {
    text: 'Deleted user subscription',
    value: 'DELETE_USER_SUBSCRIPTION',
  },
  {
    text: 'Canceled user subscription',
    value: 'CANCEL_USER_SUBSCRIPTION',
  },
  {
    text: 'Modified user subscription',
    value: 'MODIFY_USER_SUBSCRIPTION',
  },
  {
    text: 'Refunded user payment',
    value: 'REFUND_USER_PAYMENT',
  },
]

export const allFiltersRights = [
  {
    text: 'Oura Apps Admin',
    value: 'rolesOuraAppsAdmin',
  },
  {
    text: 'Oura Teams Admin',
    value: 'rolesOuraTeamsAdmin',
  },
  {
    text: 'Oura Teams Viewer',
    value: 'rolesOuraTeamsViewer',
  },
  {
    text: 'Oura Users Admin',
    value: 'rolesOuraUsersAdmin',
  },
  {
    text: 'Bulk Accounts Admin',
    value: 'rolesBulkAccountsAdmin',
  },
  {
    text: 'Data Deletion Rights',
    value: 'allowDataDeletionAccess',
  },
  {
    text: 'Data Download Rights',
    value: 'allowDataDownloadAccess',
  },
  {
    text: 'Ring History Rights',
    value: 'allowRingHistoryAccess',
  },
  {
    text: 'Account Edit Rights',
    value: 'allowAccountEditAccess',
  },
  {
    text: 'Partial Search Rights',
    value: 'allowPartialSearchAccess',
  },
  {
    text: 'Personal Data Rights',
    value: 'allowPersonalDataAccess',
  },
  {
    text: 'Sensitive Data Rights',
    value: 'allowSensitiveDataAccess',
  },
  {
    text: 'Payment Admin',
    value: 'rolesPaymentAdmin',
  },
  {
    text: 'Payment Viewer',
    value: 'rolesPaymentViewer',
  },
]

export const auditEventsHeaders: any[] = [
  {
    title: '',
    key: 'data-table-expand',
  },
  {
    title: 'Created at',
    key: 'createdAt',
  },
  {
    title: 'Event type',
    key: 'eventType',
  },
  {
    title: 'Oura user (ID/email)',
    key: 'ouraUser',
  },
  {
    title: 'Organization data (ID/name)',
    key: 'organizationData',
  },
  {
    title: 'Darwin user',
    key: 'email',
    align: 'end',
  },
]

export const auditUsersHeaders: any[] = [
  {
    title: 'Last login',
    key: 'lastActive',
  },
  {
    title: 'User email',
    key: 'email',
  },
  {
    title: 'GDPR training expires',
    key: 'gdprTrainingExpiration',
  },
  {
    title: 'Oura Apps Admin',
    key: 'rolesOuraAppsAdmin',
  },
  {
    title: 'Oura Teams Admin',
    key: 'rolesOuraTeamsAdmin',
  },
  {
    title: 'Oura Users Admin',
    key: 'rolesOuraUsersAdmin',
  },
  {
    title: 'Bulk Accounts Admin',
    key: 'rolesBulkAccountsAdmin',
  },
  {
    title: 'Data Deletion Rights',
    key: 'allowDataDeletionAccess',
  },
  {
    title: 'Data Download Rights',
    key: 'allowDataDownloadAccess',
  },
  {
    title: 'Ring History Rights',
    key: 'allowRingHistoryAccess',
  },
  {
    title: 'Account Edit Rights',
    key: 'allowAccountEditAccess',
  },
  {
    title: 'Partial Search Rights',
    key: 'allowPartialSearchAccess',
  },
  {
    title: 'Personal Data Rights',
    key: 'allowPersonalDataAccess',
  },
  {
    title: 'Sensitive Data Rights',
    key: 'allowSensitiveDataAccess',
  },
  {
    title: 'Analytics ID access',
    key: 'allowAnalyticsIdAccess',
    align: 'end',
  },
]
