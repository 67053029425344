import { AxiosError } from 'axios'

import { Store } from 'pinia-class-component'

import { BaseStore } from '#stores/base'

import { Entitlement } from '#types'

@Store()
export class EntitlementsStore extends BaseStore {
  public dataWait = false

  public entitlements: Entitlement[] = []

  public async getEntitlements(data: { ringSerial: string }) {
    this.entitlements = []
    this.dataWait = true
    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/rings/${data.ringSerial}/entitlements` },
      'getEntitlements',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.entitlements = response?.data?.entitlements || []
    this.dataWait = false
  }
}
