import { AxiosError } from 'axios'

import { Store } from 'pinia-class-component'

import { OrderSearchType } from '#views/orders/constants'

import { BaseStore } from '#stores/base'

import { OrderRaw } from '#types'

@Store()
export class OrderStore extends BaseStore {
  public orders: OrderRaw[] = []

  public async getOrders(search: string, searchType: OrderSearchType) {
    this.orders = []

    const data = { search, searchType }

    const response = await this.makeRequest(
      {
        method: 'post',
        url: `/api/v1/orders/search`,
        data,
      },
      'getOrders',
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })
    if (response?.data?.orders) {
      for (const order of response.data.orders) {
        this.orders.push({
          object: order,
          json: JSON.stringify(order),
          jsonPretty: JSON.stringify(order, null, 2),
        })
      }
    }

    return response
  }
}
