import { getDaysBetween } from '#utils/datetime/calculate'

import { ConnectedAccount, ConnectedAccountChangeEvent } from '#types'

/**
 * Get account events from ring account change events in the correct timeline format
 */
export function getConnectedAccountChangeEvents(
  connectedAccounts: ConnectedAccount[] | null,
  userUuid: string | null,
): ConnectedAccountChangeEvent[] {
  const connectedAccountChangeEvents: ConnectedAccountChangeEvent[] = []
  if (!connectedAccounts) {
    return connectedAccountChangeEvents
  }
  connectedAccounts.forEach((account, index) => {
    const prev = connectedAccounts[index + 1]

    // Add account registration or switch event
    const registerAccountChangeEvent: ConnectedAccountChangeEvent = {
      message: ``,
      helpText: '',
      time: account.lastSeen,
      eventColor: '',
    }

    //  calculates the number of days that have passed since the last time an account was seen
    const daysSinceLastSeen = getDaysBetween(new Date(account.lastSeen), new Date())

    // Create register event or switch event. Event is switch if the user has been changed during the same day
    // change eventColor based on days since last seen
    if (prev && account.lastSeen == prev.lastSeen) {
      registerAccountChangeEvent.eventColor = daysSinceLastSeen < 30 ? 'pink' : 'grey'
      registerAccountChangeEvent.message = `Ring switched from account ${prev.userUuid} to account ${account.userUuid}`
    } else {
      registerAccountChangeEvent.eventColor = daysSinceLastSeen < 30 ? 'blue' : 'grey'
      registerAccountChangeEvent.message = `Ring registered to account ${account.userUuid}${account.userUuid === userUuid ? ' (currently viewed account)' : ''}`
    }

    // Set help text to indicate when the account was last seen
    switch (daysSinceLastSeen) {
      case 0:
        registerAccountChangeEvent.helpText = `Last seen on this account today`
        break
      case 1:
        registerAccountChangeEvent.helpText = `Last seen on this account yesterday`
        break
      default:
        registerAccountChangeEvent.helpText = `Last seen on this account ${daysSinceLastSeen} days ago`
        break
    }

    connectedAccountChangeEvents.push(registerAccountChangeEvent)
  })

  // sort events by last seen dates
  connectedAccountChangeEvents.sort((eventA, eventB) => {
    return new Date(eventB.time).getTime() - new Date(eventA.time).getTime()
  })

  return connectedAccountChangeEvents
}
