<template>
  <div>
    <v-card :loading="isLoading">
      <v-card-title>
        <div class="text-overline">Ring Entitlements</div>
      </v-card-title>

      <v-data-table
        no-data-text="No entitlements found"
        :headers="headers"
        :items="ringEntitlements"
        :items-per-page="10"
      />
    </v-card>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { entitlementHeaders } from '#views/devices/constants'

  import { EntitlementsStore } from '#stores'

  @Component({})
  class RingEntitlements extends mixins(DateTime) {
    @Prop() public ringSerial!: string

    public readonly headers = entitlementHeaders

    protected readonly entitlementsStore = new EntitlementsStore()

    public get isLoading() {
      return this.entitlementsStore.dataWait
    }

    public get ringEntitlements() {
      return this.entitlementsStore.entitlements
    }

    @Watch('ring')
    protected ringChanged() {
      this.updateEntitlements()
    }

    public mounted() {
      this.updateEntitlements()
    }

    private updateEntitlements() {
      if (this.ringSerial) {
        this.entitlementsStore.getEntitlements({
          ringSerial: this.ringSerial,
        })
      }
    }
  }

  export default toNative(RingEntitlements)
</script>
