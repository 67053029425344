import { ContactType } from '#types'

export function camelCaseToWords(str: string): string {
  str = str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase()
  })
  return str
}

/**
 * Return parsed version of the serial number.
 *
 * For example with input "Y1863288462,11" we return "Y1863288462"
 */
export function parseSerialNumber(serialNumber: string): string {
  return serialNumber.split(',')[0]
}

export const getHumanReadableAddressType = (contactType: ContactType) =>
  contactType === 'billToContact' ? 'billing' : 'shipping'
