export const passwordTypes = [
  {
    name: 'Send password reset email to users',
    value: 'email',
  },
  {
    name: 'Include password reset link in generated CSV file',
    value: 'generate',
  },
]

export const bulkHeaders: any[] = [
  {
    title: 'Name',
    key: 'parameters.batch_create_user_job_comment',
  },
  {
    title: 'Created',
    key: 'createdAt',
  },
  {
    title: 'Creator',
    key: 'initiatingUserEmail',
  },
  {
    title: 'User count',
    key: 'count',
  },
  {
    title: 'Email type',
    key: 'emailType',
  },
  {
    title: 'Passwords',
    key: 'pass',
  },
  {
    title: 'Status',
    key: 'status',
  },
  {
    title: 'Error logs',
    key: 'log',
  },
  {
    title: 'Result lists',
    key: 'list',
    align: 'end',
  },
]

export const accountsHeaders: any[] = [
  {
    title: 'Shared Account',
    key: 'email',
    align: 'start',
  },
  {
    title: 'Added By',
    key: 'creatorEmail',
    align: 'start',
  },
  {
    title: 'Added Date',
    key: 'createdAt',
    align: 'start',
  },
  {
    title: 'Consent Info',
    key: 'consentInfo',
    align: 'start',
  },
  {
    title: 'Actions',
    key: 'actions',
    align: 'end',
  },
]
