<template>
  <div v-if="user">
    <v-row class="mt-0 mb-6">
      <v-col cols="12" md="9">
        <div class="d-flex text-h5 font-weight-light">You need a consent from the member to access this area</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          Information available here should not be shared with anyone else than the member
        </div>

        <div class="mt-4">
          <!--
          <v-chip size="small" class="pa-4" text="Female" prepend-icon="mdi-gender-female" />
          <v-chip size="small" class="pa-4 ml-2" text="45 Years old" prepend-icon="mdi-cake" />
          <v-chip size="small" class="pa-4 ml-2" text="CV Age +2" prepend-icon="mdi-heart" />
          <v-chip size="small" class="pa-4 ml-2" text="Weight 98kg" prepend-icon="mdi-scale" />
          <v-chip size="small" class="pa-4 ml-2" text="Height 180cm" prepend-icon="mdi-tape-measure" />
          -->
        </div>
      </v-col>

      <v-col md="3" cols="12" class="d-flex">
        <v-spacer />

        <v-btn
          class="mt-2"
          text="Confirm consent"
          color="primary"
          :disabled="consent || !sensitiveDataVisible"
          @click="confirmConsent()"
        />
        <!-- <v-btn class="mt-2" text="Request consent" color="primary" /> -->
      </v-col>
    </v-row>

    <v-stepper class="mb-8" :model-value="step">
      <v-stepper-header>
        <router-link
          v-if="arrhythmiaToolEnabled"
          style="color: var(--v-theme-on-surface)"
          :to="'/users/' + user.uuid + '/health/arrhythmia'"
        >
          <v-stepper-item
            editable
            value="arrhythmia"
            icon="mdi-heart"
            edit-icon="mdi-battery"
            :disabled="consent"
            :color="step === 'arrhythmia' ? 'info' : ''"
            :to="'/users/' + user.uuid + '/health/arrhythmia'"
          >
            Arrhythmia tool
          </v-stepper-item>
        </router-link>
        <!--
        <router-link style="color: var(--v-theme-on-surface)" :to="'/users/' + user.uuid + '/health/insights'">
          <v-stepper-item
            editable
            value="insights"
            icon="mdi-lightbulb"
            edit-icon="mdi-head-question-outline"
            :color="step === 'insights' ? 'info' : ''"
            :to="'/users/' + user.uuid + '/health/insights'"
          >
            Insight messages
          </v-stepper-item>
        </router-link>

        <router-link style="color: var(--v-theme-on-surface)" :to="'/users/' + user.uuid + '/health/pregnancy'">
          <v-stepper-item
            editable
            value="pregnancy"
            icon="mdi-baby-carriage"
            edit-icon="mdi-head-question-outline"
            :color="step === 'pregnancy' ? 'info' : ''"
            :to="'/users/' + user.uuid + '/health/pregnancy'"
          >
            Fertile window
          </v-stepper-item>
        </router-link>
-->
        <router-link style="color: var(--v-theme-on-surface)" :to="'/users/' + user.uuid + '/health/reproductivity'">
          <v-stepper-item
            editable
            value="reproductivity"
            icon="mdi-eye"
            edit-icon="mdi-head-question-outline"
            :disabled="!consent"
            :color="step === 'reproductivity' ? 'info' : ''"
            :to="'/users/' + user.uuid + '/health/reproductivity'"
          >
            Reproductivity questionaire
          </v-stepper-item>
        </router-link>
      </v-stepper-header>
    </v-stepper>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { AppStore, UserStore } from '#stores'

  @Component
  class HealthParameters extends Vue {
    @Prop() public step!: string

    protected readonly appStore = new AppStore()

    protected readonly userStore = new UserStore()

    public get user() {
      return this.userStore.user
    }

    public get consent() {
      return this.userStore.consent
    }

    public get sensitiveDataVisible() {
      return this.userStore.sensitiveDataVisible
    }

    public get arrhythmiaToolEnabled() {
      return this.$featureEnabled('arrhythmiaTool') && this.appStore.activeRights.includes('allowArrhythmiaToolAccess')
    }

    public async confirmConsent() {
      const confirm = await this.$confirm(
        'Confirm member consent',
        'Have you got permission to access the users health information?<br><br>' +
          'This section goes into details of users health and therefore you need ' +
          'to be really careful that you have clear consent to access it. ' +
          'Also remember to be careful how you share the info to the member, ' +
          'we are not healthcare professionals nor is our ring a medical device!',
      )

      if (confirm) {
        this.userStore.consent = true
      }
    }
  }

  export default toNative(HealthParameters)
</script>
