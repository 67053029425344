import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { formatDateTime, getTimezoneMinutesFromTimestamp } from '#utils/datetime/format'

import { DisplayTimezone, Nullable, RingHardwareSyncInfo } from '#types'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(advancedFormat)

function displayTimezoneSuffix(displayTimezone: DisplayTimezone): Nullable<string> {
  return displayTimezone !== DisplayTimezone.MEMBER ? displayTimezone : null
}

export function extractLastSyncBanner(
  lastSyncedAt: string | null,
  displayTimezone: DisplayTimezone = DisplayTimezone.MEMBER,
): RingHardwareSyncInfo {
  if (!lastSyncedAt || !dayjs(lastSyncedAt).isValid()) {
    return {
      lastSyncDate: null,
      syncMessage: 'Account sync information is unavailable.',
      alertColor: 'grey',
      clockIcon: 'mdi-calendar-remove',
    }
  }
  const tzSuffix = displayTimezoneSuffix(displayTimezone)

  const timezoneOffset =
    (displayTimezone === DisplayTimezone.MEMBER ? getTimezoneMinutesFromTimestamp(lastSyncedAt) : 0) ?? 0
  const parsedDate = dayjs(lastSyncedAt)

  const formattedDate = formatDateTime(lastSyncedAt, 'HH:mm Do MMM YYYY', timezoneOffset)
  const diffDuration = dayjs.duration(dayjs().diff(parsedDate))
  const totalMinutes = diffDuration.asMinutes()

  if (totalMinutes < 1) {
    return {
      lastSyncDate: lastSyncedAt,
      syncMessage: `Account last synced to the cloud: ${formattedDate} ${tzSuffix ? `${tzSuffix} ` : ''}(just now)`,
      alertColor: 'green',
      clockIcon: 'mdi-calendar-check',
    }
  }

  const days = Math.floor(diffDuration.asDays())
  const hours = diffDuration.hours()
  const minutes = diffDuration.minutes()

  const relativeTimeText =
    days >= 2
      ? `${days} days ago`
      : days === 1
        ? '1 day ago'
        : hours > 0
          ? `${hours} ${hours === 1 ? 'hour' : 'hours'} ${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`
          : `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`

  return {
    lastSyncDate: lastSyncedAt,
    syncMessage: `Account last synced to the cloud: ${formattedDate} ${tzSuffix ? `${tzSuffix} ` : ''}(${relativeTimeText})`,
    alertColor: days > 1 ? 'yellow' : 'green',
    clockIcon: days > 1 ? 'mdi-calendar-alert' : 'mdi-calendar-check',
  }
}
