import { AxiosError } from 'axios'

import { Store } from 'pinia-class-component'

import { BaseStore } from '#stores/base'
import { UserStore } from '#stores/user'

const loadingData: any = {}

@Store()
export class TimelineStore extends BaseStore {
  public user = ''
  public ring = ''

  public data: any[] = []

  public dataWait = false
  public eventsWait = false
  public diagnosticsReady = false

  public fields: string[] = []

  public events: any[] = []
  public battery: any[] = []
  public summary: any[] = []
  public timeline: any[] = []
  public lifetime: any[] = []
  public features: any[] = []
  public messages: any[] = []

  public timeRange14Days: string[] = []
  public timeRangeCustom: string[] = []

  public init() {
    const range14Days = [
      new Date(Date.now() - 15 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10) + 'T00:00:00.000Z',
      new Date(Date.now()).toISOString().substring(0, 10) + 'T23:59:59.999Z',
    ]

    this.timeRangeCustom = range14Days

    this.timeRange14Days = range14Days
  }

  public setDiagnosticsReady() {
    this.diagnosticsReady = !loadingData.battery && !loadingData.summary && !loadingData.featureFlags
  }

  public setDataWait(dataWait: any) {
    loadingData[dataWait.api] = dataWait.value

    this.dataWait = Object.keys(loadingData).some((k) => loadingData[k])
  }

  public async getSummaryData(params: any) {
    const userStore = new UserStore()
    this.setDataWait({ api: 'summary', value: true })
    this.summary = []

    let [from, to] = this.timeRange14Days
    if (params.to && params.from) {
      to = new Date(params.to).toISOString().substring(0, 10) + 'T23:59:59.999Z'
      from = new Date(params.from).toISOString().substring(0, 10) + 'T00:00:00.000Z'
    }

    let path = `/api/v1/users/${params.uuid}/timeline?fields=summaryData&from=${from}&to=${to}`
    if (params.ring) {
      path += `&ringSerial=${params.ring.serialNumber}`
    }

    const response = await this.makeRequest(
      { method: 'get', url: path },
      'getSummaryData',
      params.uuid,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    if (response?.data?.contents) {
      this.summary = response?.data?.contents
    }
    this.setDataWait({ api: 'summary', value: false })
    this.setDiagnosticsReady()
  }

  public async getFeatureFlags(params: any) {
    const userStore = new UserStore()
    this.setDataWait({ api: 'featureFlags', value: true })
    this.features = []
    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${params.uuid}/feature-flags` },
      'getFeatureFlags',
      params.uuid,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    if (response?.data?.featureFlags) {
      const featureContents = response?.data?.featureFlags
      const features: any[] = []
      for (const featureObject of featureContents) {
        const featuresExtraInfo = featureObject.extraInfo ? featureObject.extraInfo.split(',') : []
        const extraInfo: any = {}
        featuresExtraInfo.forEach((feature: any) => {
          const [key, val]: string[] = feature.split(':')
          extraInfo[key.trim()] = val?.trim()
        })
        featureObject.extraInfo = Object.keys(extraInfo).length ? extraInfo : null
        features.push(featureObject)
      }
      this.features = features
    }

    this.setDataWait({ api: 'featureFlags', value: false })
    this.setDiagnosticsReady()
  }

  public async getLifetimeEvents(params: { uuid: string; ringSerial: string; registrationDate: string | undefined }) {
    const userStore = new UserStore()

    this.setDataWait({ api: 'lifetime', value: true })
    this.lifetime = []
    const to = new Date(Date.now()).toISOString().substring(0, 10) + 'T23:59:59.999Z'
    const from = new Date(params.registrationDate ?? Date.now()).toISOString()

    const response = await this.makeRequest(
      {
        method: 'get',
        url: `/api/v1/users/${params.uuid}/timeline?ringSerial=${params.ringSerial}&fields=batteryHealth&from=${from}&to=${to}`,
      },
      'getLifetimeEvents',
      params.uuid,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // Ignore for now
      return null
    })
    this.lifetime = response?.data?.contents || []
    this.setDataWait({ api: 'lifetime', value: false })
  }
}
