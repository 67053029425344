export const ringSearchHeaders: any[] = [
  {
    title: 'Netsuite sales order ID',
    key: 'netsuiteSalesOrderId',
  },
  {
    title: 'Ring serial',
    key: 'ringSerialNumber',
  },
  {
    title: 'Charger serial',
    key: 'chargerSerialNumber',
  },
  {
    title: 'Order status',
    key: 'orderStatus',
  },
  {
    title: 'Order timestamp',
    key: 'orderTimestamp',
  },
  {
    title: 'Return reason',
    key: 'returnReason',
  },
  {
    title: 'Return status',
    key: 'returnStatus',
    align: 'end',
  },
]

export const ringSearchMinLength = 2
export const ringSearchMaxLength = 255

export const ringSearchFilters = [
  {
    title: 'Pending warranty return',
    value: 'warrantyPending',
  },
  {
    title: 'Warranty return received',
    value: 'warrantyReceived',
  },
  {
    title: 'Warranty return case closed',
    value: 'warrantyClosed',
  },
]

export const ringSearchTypes = [
  {
    title: 'Ring serial',
    value: 'ringSerial',
  },
  {
    title: 'Charger serial',
    value: 'chargerSerial',
  },
  {
    title: 'Netsuite order ID',
    value: 'netsuiteOrderId',
  },
  {
    title: 'Netsuite return ID',
    value: 'netsuiteReturnId',
  },
]

export const ringSearchTypeName = {
  ringSerial: 'ring serial',
  chargerSerial: 'charger serial',
  netsuiteOrderId: 'Netsuite order ID',
  netsuiteReturnId: 'Netsuite return ID',
}

export const entitlementHeaders: any = [
  {
    key: 'ringSerialNumber',
    title: 'Ring serial',
  },
  {
    key: 'entitlementGroup',
    title: 'Group',
  },
  {
    key: 'numberOfActivations',
    title: 'Number of activations',
  },
  {
    key: 'maxNumberOfActivations',
    title: 'Max activations',
  },
  {
    key: 'contractStartDate',
    title: 'Contract start date',
  },
  {
    key: 'contractEndDate',
    title: 'Contract end date',
  },
  {
    key: 'voided',
    title: 'Voided',
  },
  {
    key: 'type',
    title: 'type',
  },
]
