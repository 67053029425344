<template>
  <v-app-bar>
    <v-tabs style="max-width: 380px">
      <v-tab to="users">Darwin Users Info</v-tab>
      <v-tab v-if="rights.includes('rolesOuraUsersAdmin')" to="events">Audit Event Logs</v-tab>
    </v-tabs>
  </v-app-bar>

  <v-container>
    <v-window :model-value="tab">
      <v-window-item value="users">
        <AuditUsers :show-filters="true" />
      </v-window-item>

      <v-window-item v-if="rights.includes('rolesOuraUsersAdmin')" value="events">
        <AuditEvents :groups="groups" :items-per-page="100" :show-filters="true" />
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { AppStore, AuditStore } from '#stores'

  @Component
  class AuditView extends mixins(DateTime) {
    @Prop() public tab!: string

    public appStore = new AppStore()
    public auditStore = new AuditStore()

    public get rights() {
      return this.appStore.activeRights || []
    }

    public get groups() {
      return this.appStore.groups || []
    }

    public mounted() {
      this.auditStore.getAuditLogs({ page: 1 })
      this.auditStore.getAuditUsers()
    }
  }

  export default toNative(AuditView)
</script>

<style lang="scss" scoped>
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .audit :deep(.v-item-group),
  .audit :deep(.v-view) {
    background: transparent !important;
  }

  .audit :deep(.v-expansion-panel) {
    background: transparent !important;

    &::before {
      display: none;
    }

    .v-expansion-panel-header {
      padding: 0;
    }

    .v-expansion-panel-header__icon i {
      color: #e91e63 !important;
    }

    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }

  .infos :deep(.v-text-field__slot) {
    input {
      text-align: right;
    }
  }
</style>
