<template>
  <div class="pa-4">
    <div class="text-overline">Debug Logs</div>

    <v-sheet class="pa-3">
      <v-checkbox v-model="caseSensitive" label="Case sensitive" class="ml-n3" />
      <v-select v-model="count" density="compact" :items="counts" label="Max lines count" />
    </v-sheet>
  </div>
</template>

<script lang="ts">
  import { Component, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { PrefsStore } from '#stores'

  @Component
  class DebugLogs extends Vue {
    public prefsStore = new PrefsStore()
    public caseSensitive = false

    public count = 0
    public counts = [100, 500, 1000, 5000, 10000]

    @Watch('caseSensitive')
    protected onCaseSensitiveChange(val: boolean) {
      this.prefsStore.changeDebugLogPrefs({
        caseSensitive: val,
        count: this.count,
      })
    }

    @Watch('count')
    protected onCountChange(val: number) {
      this.prefsStore.changeDebugLogPrefs({
        caseSensitive: this.caseSensitive,
        count: val,
      })
    }

    public mounted() {
      const debugLogPrefs = this.prefsStore.debugLogPrefs

      this.caseSensitive = debugLogPrefs.caseSensitive || false
      this.count = debugLogPrefs.count || 100
    }
  }

  export default toNative(DebugLogs)
</script>

<style lang="scss" scoped></style>
