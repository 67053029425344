import { DiagnosticErrorLevel } from '#types'
import { Diagnostic, DiagnosticGroupData, DiagnosticsContainer } from '#types'

interface GroupedByStatus {
  [key: string]: Diagnostic[]
}

const GROUP_PINNED = 'Pinned'
const GROUP_PRIORITY = 'Priority'

function getEmptyGroupedByStatus(extraFields: boolean = false): GroupedByStatus {
  const groupedByStatus: GroupedByStatus = {}
  if (extraFields) {
    groupedByStatus[GROUP_PINNED] = []
    groupedByStatus[GROUP_PRIORITY] = []
  }
  groupedByStatus[DiagnosticErrorLevel.error] = []
  groupedByStatus[DiagnosticErrorLevel.warning] = []
  groupedByStatus[DiagnosticErrorLevel.info] = []
  groupedByStatus[DiagnosticErrorLevel.NotEnoughData] = []
  groupedByStatus[DiagnosticErrorLevel.success] = []
  return groupedByStatus
}

/**
 * Extract grouped diagnostics from diagnostics container
 *
 * Improvement ideas
 *  - Split this function into smaller pieces
 *  - If-else refactoring
 */
export function extractGroupedDiagnostics(
  diagnosticContainer: DiagnosticsContainer,
  pinnedGroupIds: string[] = [],
): DiagnosticGroupData[] {
  const priorityGroupedByStatus = getEmptyGroupedByStatus()
  const pinnedGroupedByStatus = getEmptyGroupedByStatus()
  const groupedByStatus = getEmptyGroupedByStatus(true)

  Object.values(diagnosticContainer).forEach((diagnostic: Diagnostic) => {
    if (pinnedGroupIds && pinnedGroupIds.includes(diagnostic.diagnosticId)) {
      pinnedGroupedByStatus[diagnostic.status].push(diagnostic)
    } else if (diagnostic.isPriority) {
      priorityGroupedByStatus[diagnostic.status].push(diagnostic)
    } else if (DiagnosticErrorLevel.error === diagnostic.status) {
      groupedByStatus[DiagnosticErrorLevel.error].push(diagnostic)
    } else if (DiagnosticErrorLevel.warning === diagnostic.status) {
      groupedByStatus[DiagnosticErrorLevel.warning].push(diagnostic)
    } else if (DiagnosticErrorLevel.info === diagnostic.status) {
      groupedByStatus[DiagnosticErrorLevel.info].push(diagnostic)
    } else if (DiagnosticErrorLevel.NotEnoughData === diagnostic.status) {
      groupedByStatus[DiagnosticErrorLevel.NotEnoughData].push(diagnostic)
    } else if (DiagnosticErrorLevel.success === diagnostic.status) {
      groupedByStatus[DiagnosticErrorLevel.success].push(diagnostic)
    }
  })

  // Here we arrange all pinned diagnostics in priority order
  groupedByStatus[GROUP_PINNED] = [
    ...pinnedGroupedByStatus[DiagnosticErrorLevel.error],
    ...pinnedGroupedByStatus[DiagnosticErrorLevel.warning],
    ...pinnedGroupedByStatus[DiagnosticErrorLevel.info],
    ...pinnedGroupedByStatus[DiagnosticErrorLevel.NotEnoughData],
    ...pinnedGroupedByStatus[DiagnosticErrorLevel.success],
  ]

  // Here we arrange all priority diagnostics in priority order
  groupedByStatus[GROUP_PRIORITY] = [
    ...priorityGroupedByStatus[DiagnosticErrorLevel.error],
    ...priorityGroupedByStatus[DiagnosticErrorLevel.warning],
    ...priorityGroupedByStatus[DiagnosticErrorLevel.info],
    ...priorityGroupedByStatus[DiagnosticErrorLevel.NotEnoughData],
    ...priorityGroupedByStatus[DiagnosticErrorLevel.success],
  ]

  const groupProperties: { groupId: string[]; title: string; expanded: boolean }[] = [
    {
      groupId: [GROUP_PRIORITY, GROUP_PINNED],
      title: 'Priority and pinned',
      expanded: true,
    },
    {
      groupId: [DiagnosticErrorLevel.error, DiagnosticErrorLevel.warning],
      title: 'Alerts and warnings',
      expanded: true,
    },
    {
      groupId: [DiagnosticErrorLevel.info, DiagnosticErrorLevel.NotEnoughData],
      title: 'Informational',
      expanded: false,
    },
    {
      groupId: [DiagnosticErrorLevel.success],
      title: 'Passed',
      expanded: false,
    },
  ]

  const groupedDiagnostics: DiagnosticGroupData[] = []
  groupProperties.forEach((group) => {
    let groupDiagnostics: Diagnostic[] = []
    for (const groupId of group.groupId) {
      if (groupedByStatus[groupId]) {
        groupDiagnostics = [...groupDiagnostics, ...groupedByStatus[groupId]]
      }
    }
    groupedDiagnostics.push({
      title: group.title,
      diagnostics: groupDiagnostics,
      expanded: group.expanded,
    })
  })
  return groupedDiagnostics
}
