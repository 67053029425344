import { findCountryByIsoAlpha2Code } from '#utils/country/list'

import { ShippingDetailsErrors, ShippingDetailsInfo } from '#types'

const TYPE_COUNTRY_CODE = 'countryCode'

interface ValidatorOptions {
  minLength: number
  maxLength: number
  required: boolean
  type?: string
  regExp?: RegExp
}

const userShippingDetailsValidation: { [key: string]: ValidatorOptions } = {
  firstName: {
    minLength: 2,
    maxLength: 100,
    required: true,
  },
  lastName: {
    minLength: 2,
    maxLength: 100,
    required: true,
  },
  personalEmail: {
    minLength: 6,
    maxLength: 80,
    regExp: new RegExp('[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)'),
    required: true,
  },
  address1: {
    minLength: 3,
    maxLength: 255,
    required: true,
  },
  address2: {
    minLength: 1,
    maxLength: 255,
    required: false,
  },
  city: {
    minLength: 2,
    maxLength: 40,
    required: true,
  },
  country: {
    minLength: 2,
    maxLength: 64,
    required: true,
    type: TYPE_COUNTRY_CODE,
  },
  state: {
    minLength: 2,
    maxLength: 40,
    required: false,
  },
  postalCode: {
    minLength: 2,
    maxLength: 20,
    required: true,
  },
}

export function shippingDetailsEmpty(): ShippingDetailsInfo {
  return {
    firstName: '',
    lastName: '',
    personalEmail: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    state: undefined,
    postalCode: '',
  }
}

export function shippingDetailsNoErrors(): ShippingDetailsErrors {
  return {
    firstName: [],
    lastName: [],
    personalEmail: [],
    address1: [],
    address2: [],
    city: [],
    country: [],
    state: [],
    postalCode: [],
    generalError: [],
  }
}

export class UserShippingDetailsValidator {
  private validateField(
    key: string,
    validatorOptions: ValidatorOptions,
    shippingDetails: ShippingDetailsInfo,
  ): string | true {
    if (validatorOptions.required && !shippingDetails[key as keyof ShippingDetailsInfo]) {
      return 'Cannot be empty'
    }

    if (!validatorOptions.required && !shippingDetails[key as keyof ShippingDetailsInfo]) {
      return true
    }

    if (
      validatorOptions.minLength &&
      shippingDetails[key as keyof ShippingDetailsInfo]!.length < validatorOptions.minLength
    ) {
      return 'Value too short'
    }

    if (
      validatorOptions.maxLength &&
      shippingDetails[key as keyof ShippingDetailsInfo]!.length > validatorOptions.maxLength
    ) {
      return 'Value too long'
    }

    if (validatorOptions.regExp && !validatorOptions.regExp.test(shippingDetails[key as keyof ShippingDetailsInfo]!)) {
      return 'Invalid value'
    }

    if (validatorOptions.type && validatorOptions.type === TYPE_COUNTRY_CODE) {
      if (!findCountryByIsoAlpha2Code(shippingDetails[key as keyof ShippingDetailsInfo]!)) {
        return `Country not found by country code ${shippingDetails[key as keyof ShippingDetailsInfo]}`
      }
    }

    return true
  }

  private sanitizeField(
    key: string,
    validatorOptions: ValidatorOptions,
    shippingDetails: ShippingDetailsInfo,
    cleanShippingDetails: ShippingDetailsInfo,
  ): void {
    if (!validatorOptions.required && !shippingDetails[key as keyof ShippingDetailsInfo]) {
      cleanShippingDetails[key as keyof ShippingDetailsInfo] = undefined
    } else if (shippingDetails[key as keyof ShippingDetailsInfo]) {
      cleanShippingDetails[key as keyof ShippingDetailsInfo] = shippingDetails[key as keyof ShippingDetailsInfo]?.trim()
    }
  }

  public sanitizeAndValidate(
    shippingDetails: ShippingDetailsInfo,
    suppressMessages: string[] = [],
  ): { errors: ShippingDetailsErrors; allValid: boolean; cleanShippingDetails: ShippingDetailsInfo } {
    const errors: ShippingDetailsErrors = shippingDetailsNoErrors()
    const cleanShippingDetails = shippingDetailsEmpty()
    let allValid = true
    for (const [key, validatorOptions] of Object.entries(userShippingDetailsValidation)) {
      this.sanitizeField(key, validatorOptions, shippingDetails, cleanShippingDetails)
      const validationError = this.validateField(key, validatorOptions, cleanShippingDetails)
      if (validationError !== true) {
        allValid = false
        if (!suppressMessages.includes(validationError)) {
          errors[key as keyof ShippingDetailsErrors]!.push(validationError)
        }
      }
    }
    return { errors: errors, allValid: allValid, cleanShippingDetails: cleanShippingDetails }
  }
}
