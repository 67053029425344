<template>
  <div class="text-no-wrap" @mouseover="hover = true" @mouseleave="hover = false">
    {{ uiText }}

    <v-tooltip location="top">
      <template #activator="{ props }">
        <v-btn v-bind="props" variant="text" @click.stop="copyToClipboard()">
          <v-icon v-show="hover" size="large">mdi-content-copy</v-icon>
        </v-btn>
      </template>
      {{ message }}
    </v-tooltip>
  </div>
</template>

<script lang="ts">
  import { useClipboard } from '@vueuse/core'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import * as Sentry from '@sentry/browser'

  @Component
  class HoverCopy extends Vue {
    @Prop() public data!: any
    @Prop() public message!: string
    @Prop() public text: any

    public useClipboard = useClipboard()

    public hover = false
    public timeout = 1500

    public get uiText() {
      if (this.text) {
        return this.text
      }
      return this.data
    }

    public copyToClipboard() {
      try {
        window.focus() // Attempt to focus the window (MXPRODS-5241)
        this.useClipboard.copy(this.data)
        this.$notify('Copied to clipboard', [{ text: 'Close' }])
      } catch (err) {
        const error = new Error('copyToClipboard error: ' + err)
        Sentry.captureException(error)
        console.error(error)
      }
    }
  }

  export default toNative(HoverCopy)
</script>
