import { Store } from 'pinia-class-component'

import { BaseStore } from '#stores/base'

@Store()
export class SidecarStore extends BaseStore {
  public diagnosticLocale = ''

  public diagnosticTags: any = []
  public diagnosticMacros: any = []

  public async getSidecarDiagnostics(zafClient: any) {
    const macroArray: any[] = []

    if (zafClient) {
      const data = await zafClient.get(['macros', 'ticket.requester.locale', 'ticket.tags'])
      const locale = data['ticket.requester.locale'] ? data['ticket.requester.locale'].slice(0, 2) : 'en'

      for (const macro of data.macros ?? []) {
        if (macro['description']) {
          macroArray.push(macro)
        }
      }

      this.diagnosticTags = data['ticket.tags']
      this.diagnosticMacros = macroArray
      this.diagnosticLocale = locale
    }
  }
}
