<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <v-app-bar>
    <v-app-bar-title>3rd Party Oura Apps {{ apps?.length ? `(${apps.length})` : '(0)' }}</v-app-bar-title>
  </v-app-bar>

  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <div class="text-h5 font-weight-light">Access Oura 3rd party applications / integrations</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          View information and edit status of Oura 3rd party appllication integrations
        </div>
      </v-col>

      <v-col md="3" cols="12" class="text-right">
        <v-btn text="Run search" color="primary" :disabled="!search" @click="searchApps()" />
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col class="d-flex" cols="12" sm="12" md="7">
        <v-text-field
          v-model="search"
          autofocus
          clearable
          label="Search for apps"
          append-inner-icon="mdi-magnify"
          placeholder="Type name, email or UID to search and press enter..."
          :error="!searchIsValid"
          :error-messages="searchErrors"
          required
          hide-details="auto"
          @keyup.enter="searchApps()"
          @click:clear="resetSearch()"
        />
      </v-col>

      <v-col class="d-flex align-center ga-4" cols="12" sm="12" md="5">
        <v-spacer />
        <v-btn color="blue" @click="csvDownload()">
          <v-icon>mdi-file-download</v-icon>
          CSV
        </v-btn>
      </v-col>
    </v-row>

    <v-sheet class="mt-8">
      <v-data-table
        :items="apps"
        :headers="headers"
        :loading="dataWait"
        :no-data-text="dataWait ? 'Loading apps...' : 'No matching apps found'"
        :items-per-page="100"
        :sort-by="[{ key: 'created', order: 'desc' }]"
        @click:row="(event: Event, val: any) => selectApp(event, val)"
      >
        <template #[`item.name`]="{ item }">
          <HoverCopy :data="item.name || '(No name set)'" :message="'Copy name to clipboard'" />
        </template>
        <template #[`item.uid`]="{ item }">
          <HoverCopy :data="item.uid" :message="'Copy UID to clipboard'" />
        </template>

        <template #[`item.createdAt`]="{ item }">
          <div class="text-no-wrap">
            {{ formatDateTime(item.createdAt, 'DD MMM YYYY') }}
          </div>
        </template>

        <template #[`item.status`]="{ item }">
          {{ getStatusText(item.status) }}
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>

  <v-dialog v-if="showDetail" v-model="dialogOpen" scrollable width="800">
    <v-card>
      <v-card-title class="headline">
        {{ showDetail.name || 'Unnamed Oura App / Integration' }}
        <v-spacer />
        <span class="text-button">
          {{ showDetail.userCount || 'Has no' }}
          {{ showDetail.userCount !== 1 ? 'Users' : 'User' }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col class="pr-6" cols="12" md="7">
            <v-text-field :model-value="showDetail.uid" label="UID" readonly />
            <v-text-field :model-value="showDetail.clientId" label="Client ID" readonly />

            <v-textarea :model-value="showDetail.description" label="Description" readonly rows="1" />
            <v-text-field
              v-if="showDetail.managers && showDetail.managers.length"
              :model-value="showDetail.managers[0]"
              label="Owner email"
              readonly
            />
            <v-text-field :model-value="showDetail.email" label="Contact email" readonly />

            <v-textarea
              label="Redirect URIs"
              :model-value="showDetail.redirectUris.replace(/ /g, '\n')"
              :rows="showDetail.redirectUris.split(' ').length"
              readonly
            />
          </v-col>

          <v-col class="pa-6" cols="12" md="5" style="background-color: #88888811; border-radius: 8px">
            <div class="label pt-2 pb-2">Website Url</div>
            <v-btn
              class="text-no-wrap text-lowercase text-wrap"
              variant="text"
              color="blue"
              target="_blank"
              :href="validateUrl(showDetail.homepageUrl)"
            >
              {{ showDetail.homepageUrl || 'Not provided' }}
            </v-btn>

            <div class="label pt-6 pb-2">Privacy Policy</div>
            <v-btn
              class="text-no-wrap text-lowercase text-wrap"
              variant="text"
              color="blue"
              target="_blank"
              :href="validateUrl(showDetail.privacyUrl)"
            >
              {{ showDetail.privacyUrl || 'Not provided' }}
            </v-btn>

            <div class="label pt-6 pb-2">Terms of Service</div>
            <v-btn
              class="text-no-wrap text-lowercase text-wrap"
              variant="text"
              color="blue"
              target="_blank"
              :href="validateUrl(showDetail.serviceUrl)"
            >
              {{ showDetail.serviceUrl || 'Not provided' }}
            </v-btn>
          </v-col>
        </v-row>

        <v-divider class="my-8" />

        <v-select
          v-model="showDetail.status"
          :items="statuses"
          item-title="text"
          label="Status"
          hide-details
          @update:model-value="updateStatusValue($event)"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn class="mr-4" variant="text" @click="deselectApp()">Close</v-btn>
        <v-btn color="red" variant="text" :disabled="!newStatus" @click="changeStatus()">Update status</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { validate as uuidValidate } from 'uuid'

  import { Component, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { appsHeaders, appsStatuses } from '#views/apps/constants'

  import { validateAppSearch } from '#utils/apps/appSearch'

  import { AppsStore, SearchType } from '#stores'

  import { APIApp } from '#types'

  @Component
  class AppsView extends mixins(DateTime) {
    public search: string = ''

    public searchIsValid: boolean = true
    public searchErrors: string[] = []

    public newStatus: string = ''

    public headers = appsHeaders
    public statuses = appsStatuses

    public showDetail: APIApp | null = null
    public dialogOpen = false

    public appsStore = new AppsStore()

    public get apps() {
      return this.appsStore.apps
    }

    public get app() {
      return this.appsStore.app
    }

    public get dataWait() {
      return this.appsStore.dataWait
    }

    public mounted() {
      this.appsStore.searchApps()
    }

    public updateStatusValue(value: string) {
      this.newStatus = value
    }

    // Validates given search keyword.
    // Keyword must be between 3 and 255 characters long.
    private validateAppSearch() {
      const { isValid, errors } = validateAppSearch(this.search)
      this.searchIsValid = isValid
      this.searchErrors = errors
    }

    public async searchApps() {
      let searchType: SearchType | null = null
      this.validateAppSearch()
      if (!this.searchIsValid) {
        return
      }
      if (this.search.includes('@')) {
        searchType = 'email'
      } else if (uuidValidate(this.search)) {
        searchType = 'uuid'
      } else if (this.search.length) {
        searchType = 'info'
      }

      if (searchType) {
        await this.appsStore.searchApps({
          search: this.search,
          searchType: searchType,
        })
      } else {
        await this.appsStore.searchApps()
      }
    }

    public async resetSearch() {
      this.search = ''
      this.searchIsValid = false
      this.searchErrors = []
      await this.appsStore.searchApps()
    }

    public changeStatus() {
      if (this.showDetail) {
        this.appsStore.setAppStatus({
          uid: this.showDetail?.uid,
          status: this.newStatus,
        })

        this.newStatus = ''
      }
    }

    public async selectApp(_event: any, selectedApp: any) {
      const app = selectedApp.item
      await this.appsStore.appDetails(app.uid)
      this.showDetail = this.app
      this.dialogOpen = true
    }

    public deselectApp() {
      this.showDetail = null
      this.dialogOpen = false
      this.appsStore.deselectApp()
    }

    public getStatusText(status: string): string {
      return this.statuses.find((s) => s.value === status)?.text ?? 'Unknown'
    }

    public async csvDownload() {
      this.appsStore.csvDownload().then((fileData) => {
        const data = URL.createObjectURL(fileData)
        const link = document.createElement('a')
        link.setAttribute('href', data)
        link.setAttribute('download', 'apps.csv')
        link.click()
      })
    }

    public validateUrl(url: string): string | undefined {
      const regex = /^https?:\/\/.*$/i
      if (regex.test(url)) {
        return url
      }
    }
  }

  export default toNative(AppsView)
</script>

<style lang="scss" scoped>
  :deep(.v-data-table) {
    tr:hover {
      cursor: pointer;
    }

    .v-data-footer__select {
      visibility: hidden;
    }
  }

  .label {
    color: #0009;
    font-size: 0.875rem;
  }

  :deep(.v-btn) {
    .v-btn__content {
      white-space: normal !important;
      word-break: break-all;
      max-width: 100%;
    }
  }

  :deep(.v-input) {
    .v-text-field {
      padding-top: 12px;
      margin-top: 4px;
    }
    .v-textarea {
      padding-top: 12px;
      margin-top: 4px;
    }
  }
</style>
