enum SetOrder {
  ring,
  charger,
  cable,
}

/**
 * Extract device set information from array
 *
 * @param   set           For example ['charger', 'ring']
 * @param   ucFirstChar   Uppercase first character ("ring, charger" becomes "Ring, charger")
 * @return                Set info as string and in correct order, for example 'ring, charger'
 */
export function deviceSetInfoFromArray(set: string[] = [], ucFirstChar: boolean = false): string {
  set.sort((a, b) => SetOrder[a as keyof typeof SetOrder] - SetOrder[b as keyof typeof SetOrder])
  const setStr = set.join(', ')
  if (ucFirstChar && setStr.length > 0) {
    return setStr.charAt(0).toUpperCase() + setStr.slice(1)
  }
  return setStr
}
