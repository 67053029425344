import { deviceSetInfoFromArray } from '#utils/warranty/setInfo'

import { Nullable, ReturnItem, WarrantyItemCounts } from '#types'

/**
 * Returns comma separated string of devices that are waiting to be returned
 *
 * @param itemCounts    Total item count which we should receive
 * @param returnedItems Already returned items
 * @param ucFirstChar   Uppercase first character
 * @return              List of devices waiting to be returned, for example "ring, cable". null if there are none
 */
export function waitingToBeReturned(
  itemCounts: WarrantyItemCounts,
  returnedItems: Nullable<ReturnItem[]>,
  ucFirstChar: boolean = false,
): Nullable<string> {
  const waiting: string[] = []

  if (returnedItems && returnedItems.length > 0) {
    for (const item of returnedItems) {
      if (itemCounts[item.itemType as keyof WarrantyItemCounts] == 1) {
        itemCounts[item.itemType as keyof WarrantyItemCounts] = 0
      }
    }
  }

  Object.keys(itemCounts).forEach((key) => {
    if (itemCounts[key as keyof WarrantyItemCounts] == 1) {
      waiting.push(key)
    }
  })

  if (waiting.length > 0) {
    return deviceSetInfoFromArray(waiting, ucFirstChar)
  }

  return null
}
