<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <v-app-bar title="Oura shop orders" />

  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <div class="text-h5 font-weight-light">Access Oura shop ring orders information</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          Search and view information / status for Oura ring orders
        </div>
      </v-col>

      <v-col md="3" cols="12" class="text-right">
        <v-btn
          color="primary"
          text="Run search"
          :disabled="!routeParams.search || waitingForData"
          @click="performSearch()"
        />
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="7">
        <v-text-field
          v-model.trim="routeParams.search"
          autofocus
          hide-details="auto"
          label="Search ring orders"
          append-inner-icon="mdi-database-search"
          placeholder="Enter keyword and press enter to search..."
          :error="!searchIsValid"
          :error-messages="searchErrors"
          @keyup.enter="performSearch()"
        />
      </v-col>

      <v-col cols="5">
        <v-select v-model="routeParams.searchType" label="Search type" :items="searchTypes" />
      </v-col>
    </v-row>

    <v-sheet class="mt-8">
      <v-data-table
        hide-default-footer
        item-value="object.orderId"
        :headers="headers"
        :items="searchResults"
        :loading="waitingForData"
        :items-per-page="itemsPerPage"
        :no-data-text="waitingForData ? 'Loading search results...' : 'No matches / results found'"
        :row-props="
          (row) => {
            const isSelectedRow = row.item.object.orderId === selectedOrder?.orderId

            return isSelectedRow ? { class: 'bg-pink-lighten-5' } : {}
          }
        "
        @click:row="
          (_event: any, row: any) => {
            selectedOrder = row.item.object
          }
        "
      >
        <template #[`item.object.createdAt`]="{ item }">
          {{ $dayjs(item.object.createdAt).format('HH:mm - DD MMM YYYY') }}
        </template>
      </v-data-table>
    </v-sheet>

    <v-card v-if="selectedOrder" class="mt-8">
      <div class="text-subtitle-2 py-4">
        <JSONView :data="selectedOrder" />
      </div>
    </v-card>
  </v-container>
</template>

<script lang="ts">
  import { validate as isUUIDValid } from 'uuid'

  import { Component, mixins, toNative } from 'vue-facing-decorator'

  import { RouteParams } from '@jouzen/outo-toolkit-vuetify'

  import { orderSearchHeaders, orderSearchTypes } from '#views/orders/constants'

  import { isEmailValid } from '#utils/user/emailValidation'

  import { OrderStore } from '#stores'

  @Component
  class OrdersView extends mixins(RouteParams) {
    public routeParams: any = {
      search: '',
      searchType: 'email',
    }

    public itemsPerPage = 50

    public searchIsValid = true

    public selectedOrder: any = null

    public searchErrors: string[] = []

    public readonly headers = orderSearchHeaders
    public readonly searchTypes = orderSearchTypes

    protected readonly orderStore = new OrderStore()

    public get searchResults() {
      return this.orderStore.orders
    }

    public get waitingForData() {
      return this.orderStore.waitingForData()
    }

    public mounted() {
      this.orderStore.orders = []

      this.performSearch()
    }

    /**
     * Validates given search keyword.
     * @private
     */
    public validateSearch() {
      this.searchErrors = []

      if (
        this.routeParams.search &&
        this.routeParams.searchType === 'email' &&
        !isEmailValid(this.routeParams.search)
      ) {
        this.searchIsValid = false

        this.searchErrors.push('Invalid email.')

        return
      }

      if (
        this.routeParams.search &&
        this.routeParams.searchType === 'orderId' &&
        !isUUIDValid(this.routeParams.search)
      ) {
        this.searchIsValid = false

        this.searchErrors.push('Invalid order ID.')

        return
      }

      this.searchIsValid = true
    }

    public performSearch() {
      this.validateSearch()

      this.selectedOrder = null

      if (!this.searchIsValid || !this.routeParams.search) {
        return
      }

      this.orderStore.getOrders(this.routeParams.search.toLowerCase(), this.routeParams.searchType)
    }
  }

  export default toNative(OrdersView)
</script>
