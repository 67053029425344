<template>
  <div>
    <v-card min-width="100%">
      <v-card-item>
        <v-card-title class="d-flex text-overline">Account / Ring</v-card-title>
      </v-card-item>

      <v-card-text>
        <div class="pa-4">
          <v-row>
            <v-row class="align-start">
              <v-col cols="12" md="4">
                <v-text-field v-if="user" label="UUID" :model-value="user.uuid || ' '" readonly>
                  <template #append-inner>
                    <v-tooltip location="top">
                      <template #activator="{ props }">
                        <v-icon
                          v-bind="props"
                          color="grey"
                          icon="mdi-content-copy"
                          @click="useClipboard.copy(user.uuid)"
                        />
                      </template>
                      {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
                    </v-tooltip>
                  </template>
                </v-text-field>

                <v-text-field
                  v-if="ring && ring.serialNumber"
                  label="Ring Serial"
                  :model-value="ring.serialNumber || 'Ring not registered'"
                  readonly
                  class="mt-4"
                >
                  <template #append-inner>
                    <v-tooltip location="top">
                      <template #activator="{ props }">
                        <v-icon
                          v-bind="props"
                          color="grey"
                          icon="mdi-content-copy"
                          @click="useClipboard.copy(ring.serialNumber)"
                        />
                      </template>
                      {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
                    </v-tooltip>
                  </template>
                </v-text-field>

                <v-text-field
                  v-if="ring && !ring.serialNumber"
                  label="Ring MAC Address"
                  :disabled="!ring"
                  :model-value="ring.macAddress || 'Ring not registered'"
                  readonly
                  prepend-icon="mdi-content-copy"
                >
                  <template #append-inner>
                    <v-tooltip location="top">
                      <template #activator="{ props }">
                        <v-icon
                          color="grey"
                          v-bind="props"
                          icon="mdi-content-copy"
                          @click="useClipboard.copy(ring.macAddress)"
                        />
                      </template>
                      {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>

              <v-col class="pr-6" cols="12" md="4">
                <v-text-field label="Analytics ID" :model-value="user.analyticsId || 'No data available'" readonly>
                  <template #append-inner>
                    <v-tooltip location="top">
                      <template #activator="{ props }">
                        <v-icon
                          color="grey"
                          v-bind="props"
                          icon="mdi-content-copy"
                          @click="useClipboard.copy(user.analyticsId || '')"
                        />
                      </template>
                      {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
                    </v-tooltip>
                  </template>
                </v-text-field>

                <div v-if="$featureEnabled('warrantyDatesOnDashboard')">
                  <v-text-field
                    v-if="!!ring"
                    label="Warranty end date"
                    :model-value="warrantyEndDate"
                    class="mt-4"
                    :class="memberInfo.warrantyEndDateClass"
                    readonly
                  />
                  <v-tooltip v-if="warrantyStartDate" activator="parent" location="top" :disabled="false">
                    Warranty start date: {{ warrantyStartDate }}
                  </v-tooltip>
                </div>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field label="User created" :model-value="userCreatedAt" readonly />

                <v-text-field
                  v-if="ring && ring.registrationDate"
                  label="Ring registered"
                  :model-value="ringRegistered"
                  readonly
                  class="mt-4"
                />
              </v-col>
            </v-row>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
  import { useClipboard } from '@vueuse/core'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { extractMemberInfo } from '#utils/member-info/extract'

  import { AppStore, RingsStore, UserStore } from '#stores'

  import { Member, MemberInfo, Ring } from '#types'

  @Component({})
  class OverviewInfo extends mixins(DateTime) {
    @Prop() public user!: Member
    @Prop() public ring!: Ring
    @Prop() public rings!: Ring[]
    @Prop() public updateRing!: (ring: any) => void

    public useClipboard = useClipboard()

    public userStore = new UserStore()
    public appStore = new AppStore()
    public ringsStore = new RingsStore()

    public memberInfo: MemberInfo = {
      warrantyStartDate: null,
      warrantyEndDate: null,
      warrantyDaysLeftText: null,
      warrantyEndDateClass: 'not-expired',
    }

    public handleRingClick(ring: Ring) {
      this.ringsStore.setActiveRing(ring)
    }

    public get activeRing(): Ring | null {
      return this.ringsStore.activeRing
    }

    @Watch('activeRing')
    protected async onActiveRingChanged() {
      this.updateMemberInfo()
    }

    protected updateMemberInfo() {
      if (this.activeRing) {
        this.memberInfo = extractMemberInfo(this.activeRing)
      }
    }

    public get warrantyDetails() {
      return this.ringsStore.warrantyData
    }

    public get userCreatedAt() {
      return this.user.userCreatedAt
        ? this.formatDateTime(this.user.userCreatedAt, 'HH:mm Do MMM YYYY')
        : 'No data available'
    }

    public get ringRegistered() {
      return this.ring.registrationDate
        ? this.formatDateTime(this.ring.registrationDate, 'HH:mm Do MMM YYYY')
        : 'No data available'
    }

    public get warrantyStartDate(): string | null {
      return this.memberInfo.warrantyStartDate
        ? this.formatDateTime(this.activeRing?.warrantyStartDate, 'Do MMM YYYY')
        : null
    }

    public get warrantyEndDate() {
      return this.memberInfo.warrantyEndDate
        ? this.formatDateTime(this.memberInfo.warrantyEndDate, 'Do MMM YYYY') + this.memberInfo.warrantyDaysLeftText
        : 'No data available'
    }

    public mounted() {
      this.updateMemberInfo()
      setTimeout(() => {
        const e: any = this.$refs.rings
        const s: any = this.$refs.scroll

        if (s && e) {
          s.scrollLeft = Math.max(0, e.offsetWidth - s.offsetWidth)
        }
      })
    }

    public isNewRing(ring: any) {
      const rDate = new Date(ring.registrationDate)

      return Date.now() - rDate.getTime() < 30 * 24 * 60 * 60 * 1000
    }

    public isFaultyRing(ring: any) {
      return ring.warrantyData?.ringReturn?.replacedBy && ring.macAddress === this.rings[0].macAddress
    }

    public ringLabel(ring: any, index: number) {
      return index === 0 ? 'Current' : `Old ${index}`
    }

    public serialToLabel(serial: string) {
      if (serial) {
        serial = serial.replace(/,.*/, '')

        const index = this.rings.findIndex((r: any) => r.serialNumber === serial)

        return index >= 0 ? this.ringLabel(this.rings[index], index) + ' ring' : serial
      } else {
        return 'Unknown ring'
      }
    }

    public checkImgFile(design: string | null, color: string | null, type: string | null) {
      let file = ''
      switch (type) {
        case 'gen2':
          file = design?.replace('gen1', 'heritage').replace('gemma', 'heritagex') + '-' + color + '.png'
          break

        case 'gen2m':
          file = design?.replace('gen1', 'heritage').replace('gemma', 'heritagex') + '-' + color + '.png'
          break

        case 'gen2x':
          file = design?.replace('heritage', 'heritagex') + '-' + color + '.png'
      }

      return `/rings/${file}`
    }

    public fallbackRingImage(event: Event) {
      const element = event.target as HTMLImageElement
      if (element) {
        element.src = `/rings/unknown-ring.png`
      }
    }
  }

  export default toNative(OverviewInfo)
</script>

<style lang="scss" scoped>
  .ring {
    position: relative;
    cursor: pointer;
    text-align: center;
    border: 2px solid #c9c9c9;

    span {
      font-weight: bold;
    }

    &.selected {
      border-color: #03a9f4;

      &.new {
        border-color: #8bc34a;
      }
    }
  }

  .mdi-content-copy {
    cursor: pointer;
  }

  .expired {
    color: red;
  }

  .hipaa-member {
    color: #ff9800;
  }

  .non-hipaa-member {
    color: #4caf50;
  }
</style>
