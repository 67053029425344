<template>
  <v-row>
    <v-col cols="12" md="9">
      <div class="text-h5 font-weight-light">Managing shared accounts for Darwin users</div>

      <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
        Shared accounts are accessible / discoverable by all the Darwin users
      </div>
    </v-col>

    <v-col md="3" cols="12" class="text-right">
      <v-btn color="primary" @click="shareDialog = true">Add shared account</v-btn>
    </v-col>
  </v-row>

  <v-row class="mt-4">
    <v-col class="d-flex" cols="12" sm="12" md="7">
      <v-text-field
        v-model="filter"
        label="Search shared accounts"
        append-inner-icon="mdi-magnify"
        placeholder="Type to filter shared accounts..."
      />
    </v-col>
  </v-row>

  <v-sheet class="mt-8">
    <v-alert v-if="!!error" tile class="px-5 v-sheet--tile" type="error" color="red">
      <v-row class="px-3">
        {{ error }}
      </v-row>
    </v-alert>

    <v-data-table
      :headers="headers"
      :search="filter"
      :items="accounts"
      :loading="dataWait"
      :items-per-page="100"
      :sort-by="[{ key: 'createdAt', order: 'desc' }]"
    >
      <template #item="{ item }">
        <tr>
          <td>{{ item.email }}</td>
          <td>{{ item.creatorEmail }}</td>
          <td>{{ formatDateTime(item.createdAt, 'DD MMM YYYY') }}</td>
          <td>
            <div class="row text-no-wrap">
              <a v-if="item.url" :href="item.url" target="_blank">
                {{ item.url.slice(8) }}
              </a>
              <span v-else>
                {{ item.consentInfo?.length > 30 ? item.consentInfo.substring(0, 30) + '..' : item.consentInfo }}
              </span>
            </div>
          </td>

          <td>
            <div class="text-right text-no-wrap">
              <v-tooltip left>
                <template #activator="{ props }">
                  <v-btn
                    class="px-2"
                    variant="text"
                    v-bind="props"
                    icon="mdi-pencil"
                    color="grey"
                    @click="consentInfo(item.email, item.consentInfo)"
                  />
                </template>
                <span>Edit Consent Info</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ props }">
                  <v-btn
                    v-if="item.type === 'public'"
                    class="px-2"
                    variant="text"
                    icon="mdi-eye"
                    color="black"
                    v-on="props"
                    @click="confirmUpdate(item.type, item.email, item.consentInfo)"
                  />
                  <v-btn
                    v-else
                    class="px-2"
                    variant="text"
                    icon=" mdi-eye-off"
                    color="grey"
                    v-on="props"
                    @click="updateAccount(item.type, item.email, item.consentInfo)"
                  />
                </template>
                <span>Account is {{ item.type || 'private' }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ props }">
                  <v-btn
                    icon="mdi-trash-can"
                    class="px-2"
                    v-bind="props"
                    color="darkgrey"
                    @click="removeAccount(item.email)"
                  />
                </template>
                <span>Remove account</span>
              </v-tooltip>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-sheet>

  <v-dialog v-model="confirm" width="500">
    <v-card>
      <v-card-title>Confirm deletion</v-card-title>

      <v-card-text>
        <span>
          This will remove the email:
          <strong>{{ email }}</strong>
        </span>
      </v-card-text>

      <v-card-actions class="px-4">
        <v-spacer />
        <v-btn variant="text" @click="confirm = false">Cancel</v-btn>
        <v-btn variant="text" color="red" @click="confirmDelete()">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="update" width="500">
    <v-card>
      <v-card-title>Confirm update</v-card-title>

      <v-card-text>
        <span>
          Update
          <strong>{{ email }}</strong>
          account to
          <strong>public?</strong>
        </span>
        <br />
        <p>
          Account will be listed for everyone on the Oura users page.
          <br />
          Private accounts can be searched but they are not listed.
        </p>
      </v-card-text>

      <v-card-actions class="px-4">
        <v-spacer />
        <v-btn variant="text" @click="update = false">Keep {{ type }}</v-btn>
        <v-btn variant="text" color="red" @click="confirmUpdate(type, email, info)">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="ifConsent" width="500">
    <v-card>
      <v-card-title>Consent Information</v-card-title>
      <v-card-text>
        <v-textarea v-model="info" label="Update consent info" :rules="consentInfoRules" />
      </v-card-text>
      <v-card-actions class="px-4">
        <v-spacer />
        <v-btn variant="text" @click="ifConsent = false">Cancel</v-btn>
        <v-btn variant="text" color="primary" :disabled="!info" @click="confirmConsent(type, email, info)">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <ShareAccount v-if="shareDialog" @close="shareDialog = false" />
</template>

<script lang="ts">
  import { Component, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { accountsHeaders } from '#views/admin/constants'

  import { AdminStore } from '#stores'

  @Component
  class Admin extends mixins(DateTime) {
    public adminStore = new AdminStore()

    public info = ''
    public type = ''
    public error = ''
    public email = ''
    public filter = ''

    public update = false
    public confirm = false
    public ifConsent = false
    public emailValid = false
    public shareDialog = false

    public headers = accountsHeaders

    public consentInfoRules = [(v: string) => v.length > 5 || 'Consent Info is required']

    public mounted() {
      this.adminStore.listSharedAccounts()
    }

    public get accounts() {
      return this.adminStore.accounts
    }

    public get dataWait() {
      return this.adminStore.dataWait
    }

    public confirmDelete() {
      this.adminStore.removeSharedAccount(this.email).then((res) => {
        if (res !== 200) {
          this.error = 'Unable to remove email'
        } else {
          this.confirm = false
        }
      })
    }

    public removeAccount(email: string) {
      this.confirm = true
      this.email = email
    }

    public updateAccount(type: string, email: string, info: string) {
      this.update = true
      this.type = type
      this.email = email
      this.info = info
    }

    public consentInfo(email: string, info: string) {
      this.info = info
      this.email = email
      this.ifConsent = true
    }

    public confirmUpdate(type: string, email: string, info: string) {
      type = type === 'public' ? 'private' : 'public'
      this.adminStore
        .updateSharedAccount({
          type: type,
          email: email,
          consentInfo: info,
        })
        .then((res) => {
          if (res !== 200) {
            this.error = 'Unable to update email'
          } else {
            this.update = false
          }
        })
    }

    public confirmConsent(type: string, email: string, info: string) {
      this.adminStore
        .updateSharedAccount({
          type: type,
          email: email,
          consentInfo: info,
        })
        .then((res) => {
          if (res !== 200) {
            this.error = 'Unable to update email'
          } else {
            this.ifConsent = false
          }
        })
    }
  }

  export default toNative(Admin)
</script>

<style lang="scss" scoped>
  :deep(.v-data-table) {
    tr:hover {
      cursor: pointer;
    }

    .v-data-footer__select {
      visibility: hidden;
    }
  }
</style>
