import { AxiosError, AxiosResponse } from 'axios'

import { Store } from 'pinia-class-component'

import { getRemoteConfig, getString } from 'firebase/remote-config'

import { extractAvailableDiagnostics, extractDiagnostic } from '#utils/diagnostics/extract'
import { getDiagnosticVersion } from '#utils/user/diagnostic-versions'

import { BaseStore } from '#stores/base'
import { UserStore } from '#stores/user'

import {
  AvailableDiagnostic,
  AvailableDiagnosticResponseItem,
  DiagnosticCategory,
  DiagnosticResponse,
  DiagnosticsContainer,
} from '#types'

@Store()
export class DiagnosticsStoreV3 extends BaseStore {
  public dataWait = false

  // Will be set back to false after the warning message
  // has been shown to user even if no pins were removed
  // (so don't use this for any decision making logic).
  public maxPinsReached = false

  // Contains all diagnostics we were able to load
  public diagnostics: DiagnosticsContainer = {}

  // Pinned diagnostics, contains list of diagnostic IDs.
  public pinnedDiagnostics: string[] = []

  public availableDiagnostics: AvailableDiagnostic[] = []

  public constructor() {
    super()
    this.pinnedDiagnostics = JSON.parse(localStorage.getItem('pinnedDiagnostics') || '[]')
  }

  public setDataWait(wait: boolean) {
    this.dataWait = wait
  }

  public isPinned(diagnosticId: string) {
    return this.pinnedDiagnostics.includes(diagnosticId)
  }

  public togglePin(diagnosticId: string, maxPinned: number = 5) {
    const index = this.pinnedDiagnostics.indexOf(diagnosticId)
    this.maxPinsReached = false
    if (index === -1) {
      if (this.pinnedDiagnostics.length >= maxPinned) {
        this.maxPinsReached = true
        return
      }
      this.pinnedDiagnostics.push(diagnosticId)
    } else {
      this.pinnedDiagnostics.splice(index, 1)
    }

    localStorage.setItem('pinnedDiagnostics', JSON.stringify(this.pinnedDiagnostics))
  }

  public async getAvailableDiagnostic() {
    this.dataWait = true
    const response = await this.makeRequest(
      {
        method: 'get',
        url: `/api/v1/diagnostics`,
      },
      'getAvailableDiagnostic',
    )
      .then((value: AxiosResponse | null) => {
        return value?.data
      })
      .catch((_error: AxiosError) => {
        // Ignore
        return null
      })

    const availableDiagnosticsResponse: AvailableDiagnosticResponseItem[] | undefined = response?.diagnostics
    if (availableDiagnosticsResponse) {
      this.availableDiagnostics = extractAvailableDiagnostics(availableDiagnosticsResponse)
    }

    this.dataWait = false
  }

  public async getDiagnostics(data: { diagnosticItem: AvailableDiagnostic; uuid?: string; serial?: string }) {
    this.diagnostics = {}

    this.dataWait = true
    let macroId = ''
    let isHidden: boolean = false

    if (!data.serial && data.diagnosticItem.category == DiagnosticCategory.rings) {
      return
    }

    const hiddenDiagnostics: string[] = getString(getRemoteConfig(), 'hiddenDiagnostics')
      .split(',')
      .map((s) => s.trim())

    const diagnosticVersion = getDiagnosticVersion(data.diagnosticItem)

    const userStore = new UserStore()

    const sensitiveDataVisible = userStore.sensitiveDataVisible
    const params: any = {
      show_sensitive_data: sensitiveDataVisible ? 'true' : 'false',
      version: diagnosticVersion.toString(),
    }

    let url = `/api/v1`
    if (data.diagnosticItem.category == DiagnosticCategory.rings) {
      url = `${url}/rings/${data.serial}/diagnostics/`
    } else if (data.diagnosticItem.category == DiagnosticCategory.users) {
      url = `${url}/users/${data.uuid}/diagnostics/`
    } else if (data.diagnosticItem.category == DiagnosticCategory.userRings) {
      url = `${url}/users/${data.uuid}/rings/${data.serial}/diagnostics/`
    } else if (data.diagnosticItem.category == DiagnosticCategory.featureFlag) {
      url = `${url}/users/${data.uuid}/diagnostics-feature-flag/`
    }

    url += data.diagnosticItem.diagnosticId

    isHidden = hiddenDiagnostics.includes(data.diagnosticItem.diagnosticId)

    const diagnosticResponse: DiagnosticResponse | undefined = await this.makeRequest(
      {
        method: 'get',
        url: url,
        params: params,
      },
      `getDiagnosticV3+${data.diagnosticItem.diagnosticId}`,
    )
      .then((value: AxiosResponse | null) => {
        macroId = data?.diagnosticItem?.diagnosticId + '_' + value?.data?.status
        return value?.data
      })
      .catch((_error: AxiosError) => {
        // Ignore
        return null
      })

    if (diagnosticResponse) {
      this.diagnostics[data.diagnosticItem.diagnosticId] = extractDiagnostic(
        diagnosticResponse,
        data.diagnosticItem.diagnosticId,
        macroId,
        isHidden,
      )
    }
    this.dataWait = false
  }
}
