import {
  Member,
  MemberClient,
  MemberDeleteTicket,
  MemberDevice,
  MemberEmail,
  MemberEvent,
  Ring,
  UserAccessResponse,
  UserCloudManagementResponse,
  UserDeletionTicketsResponse,
  UserEmailsResponse,
  UserRingsResponse,
} from '#types'

export function extractMemberFromUserCloudManagementResponse(
  userResponse: UserCloudManagementResponse,
  userAccessResponse: UserAccessResponse,
  userEmailsResponse: UserEmailsResponse,
  userRingsResponse: UserRingsResponse,
  userDeletionTicketsResponse: UserDeletionTicketsResponse,
): Member {
  let firstRing: Ring | null = null
  let firstDeleteTicket: MemberDeleteTicket | null = null
  const devices: MemberDevice[] = []
  const clients: MemberClient[] = []
  const emails: MemberEmail[] = []
  const events: MemberEvent[] = []
  const rings: Ring[] = []
  const deleteTickets: MemberDeleteTicket[] = []

  for (const emailResponse of userEmailsResponse.emails) {
    emails.push({
      email: emailResponse.email ?? null,
      createdAt: emailResponse.createdAt,
      deletedAt: emailResponse.deletedAt,
      verified: emailResponse.verified,
      deleted: emailResponse.deleted,
      verifiedAt: emailResponse.verifiedAt,
      verificationSent: null,
      currentEmail: emailResponse.primary,
    })
  }
  for (const eventResponse of userEmailsResponse.events) {
    events.push({
      occurredAt: eventResponse.occurredAt,
      eventType: eventResponse.eventType,
      json: eventResponse.json,
    })
  }
  for (const ringResponse of userRingsResponse.rings) {
    const currentRing: Ring = {
      bootloaderUpdatedOn: ringResponse.bootloaderUpdatedOn,
      bootloaderVersion: ringResponse.bootloaderVersion,
      color: ringResponse.color,
      design: ringResponse.design,
      firmwareUpdatedOn: ringResponse.firmwareUpdatedOn,
      firmwareVersion: ringResponse.firmwareVersion,
      hardwareType: ringResponse.hardwareType,
      hardwareVersion: ringResponse.hardwareVersion,
      lastSeenDate: ringResponse.ringInfoModifiedAt,
      macAddress: ringResponse.macAddress,
      serialNumber: ringResponse.ringSerialNumber,
      chargerSerialNumber: ringResponse.chargerSerialNumber,
      clientFlavor: ringResponse.clientFlavor,
      clientVersion: ringResponse.clientVersion,
      clientUpdatedOn: ringResponse.clientUpdatedOn,
      deviceModel: ringResponse.deviceModel,
      deviceOs: ringResponse.deviceOs,
      deviceOsVersion: ringResponse.deviceOsVersion,
      deviceOsUpdatedOn: ringResponse.deviceOsUpdatedOn,
      offeredFirmwareVersion: ringResponse.offeredFirmwareVersion,
      offeredBootloaderVersion: ringResponse.offeredBootloaderVersion,
      netsuiteSalesOrderId: ringResponse.netsuiteSalesOrderId,
      lastSyncedAt: ringResponse.lastSyncedAt,
      orderStatus: ringResponse.orderStatus,
      orderTimestamp: ringResponse.orderTimestamp,
      returnReason: ringResponse.returnReason,
      returnStatus: ringResponse.returnStatus,
      ringInfoModifiedAt: ringResponse.ringInfoModifiedAt,
      ringSerialNumber: ringResponse.ringSerialNumber,
      warrantyStartDate: ringResponse.warrantyStartDate,
      warrantyEndDate: ringResponse.warrantyEndDate,
      zendeskNotes: ringResponse.zendeskNotes,
      registrationDate: ringResponse.registrationDate,
      ringSerialInfo: ringResponse.ringSerialInfo,
      chargerSerialInfo: ringResponse.chargerSerialInfo,
    }
    rings.push(currentRing)
    if (!firstRing) {
      firstRing = currentRing
      devices.push({
        id: null,
        model: firstRing.deviceModel,
        os: firstRing.deviceOs,
        osVersion: firstRing.deviceOsVersion,
        osVersionUpdatedOn: null,
        firstSeen: null,
        lastSeen: null,
        clientFlavor: firstRing.clientFlavor,
        registrationCount: null,
      })
      clients.push({
        platform: firstRing.deviceOs,
        registrationCount: null,
        flavor: firstRing.clientFlavor,
        language: null,
        version: firstRing.clientVersion,
        updatedOn: null,
        firstSeen: null,
        lastSeen: null,
      })
    }
  }

  for (const deleteTicketResponse of userDeletionTicketsResponse.tickets) {
    const currentDeleteTicket: MemberDeleteTicket = {
      aspects: deleteTicketResponse.aspects,
      created_at: deleteTicketResponse.createdAt,
      initiating_admin_email: deleteTicketResponse.initiatingAdminEmail,
      initiating_admin_uid: deleteTicketResponse.initiatingAdminUid,
      initiation_type: deleteTicketResponse.initiationType,
      status: deleteTicketResponse.status,
      uid: deleteTicketResponse.uid,
      updated_at: deleteTicketResponse.updatedAt,
      user_uid: deleteTicketResponse.userUid,
    }
    deleteTickets.push(currentDeleteTicket)
    if (!firstDeleteTicket) {
      firstDeleteTicket = currentDeleteTicket
    }
  }

  const member: Member = {
    uuid: userResponse.uuid,
    analyticsId: userResponse.analyticsId,
    userCreatedAt: userResponse.userCreatedAt,
    email: userResponse.email ?? null,
    emailVerified: userResponse.emailVerified,
    percentile: null,
    ringCount: userResponse.ringCount,
    lastChange: null,
    env: {
      environment: userResponse.environment ?? null,
      serviceName: null,
      serviceType: null,
    },

    ownerAccess: userAccessResponse.ownerAccess,
    supportAccess: userAccessResponse.supportAccess,
    sharedAccess: userAccessResponse.sharedAccess,
    engagementId: null,
    isVerificationBypassed: userResponse.emailVerificationBypassed,
    timeZone: userResponse.timeZone,
    rings: rings,
    emails: emails,
    devices: devices,
    clients: clients,
    deleteTicket: firstDeleteTicket,
    deleteTickets: deleteTickets,
    flags: userResponse.flags,
    events: events,
    hipaaStatus: userResponse.hipaaStatus,
    countryOfResidence: userResponse.countryOfResidence,
  }

  member.env.environment = member.env.environment === 'staging' ? 'stage' : member.env.environment

  return member
}
