import { UserStore } from './user'

import { Pinia, Store } from 'pinia-class-component'

import { AppStore } from '#stores'

import { DisplayTimezone, GraphMode } from '#types'

@Store()
export class PrefsStore extends Pinia {
  public newFeature = localStorage.OuraNewFeature || ''

  public adminDebugMode = JSON.parse(localStorage.OuraAdminDebugMode || 'false')

  public timezonePrefs = (localStorage.getItem('OuraTimezonePrefs') ?? 'UTC').toString()

  public debugLogPrefs: { caseSensitive?: boolean; count?: number } = JSON.parse(
    localStorage.OuraDebugLogPrefs || localStorage.OuraDebugSettings || '{}',
  )

  public graphMode: GraphMode =
    localStorage.getItem('OuraGraphMode') == GraphMode.SVG.toString() ? GraphMode.SVG : GraphMode.WebGL

  public timezoneSetting = this.timezonePrefs === 'USER' ? 'USER' : 'UTC'

  public timezoneAllowed() {
    const appStore = new AppStore()
    const userStore = new UserStore()

    return (
      this.timezoneSetting === 'UTC' ||
      userStore.user?.ownerAccess ||
      userStore.user?.supportAccess ||
      appStore.activeRights.includes('allowPersonalDataAccess')
    )
  }

  /**
   * Which timezone we want to display dates and times in for the Darwin user.
   *
   * If MEMBER is chosen, we use member's timezone. Other option is UTC
   */
  public get displayTimezone(): DisplayTimezone {
    if (this.timezoneSetting === 'UTC') {
      return DisplayTimezone.UTC
    } else {
      return DisplayTimezone.MEMBER
    }
  }

  public get getTimezoneSetting() {
    return !this.timezoneAllowed() ? 'UTC' : this.timezoneSetting
  }

  public async setUTCTimezone(utc: boolean) {
    const curSetting = this.timezoneSetting

    this.timezoneSetting = !utc ? 'USER' : 'UTC'

    if (curSetting !== this.timezoneSetting) {
      await this.$router.isReady()

      // This is to reset any data loading done by the pages

      if (this.$router.currentRoute.value.query.utc !== utc.toString()) {
        this.$router.replace({
          path: this.$router.currentRoute.value.path,
          query: {
            ...this.$router.currentRoute.value.query,
            utc: utc.toString(),
          },
        })
      }
    }
  }

  public updateNewFeature(feature: string) {
    localStorage.OuraNewFeature = feature

    this.newFeature = feature
  }

  public changeDebugLogPrefs(data: { caseSensitive: boolean; count: number }) {
    localStorage.OuraDebugLogPrefs = JSON.stringify(data)

    this.debugLogPrefs = data
  }

  public toggleAdminDebugMode(enabled: boolean) {
    localStorage.OuraAdminDebugMode = JSON.stringify(enabled)

    this.adminDebugMode = enabled
  }

  public changeTimezoneSetting(data: { timezone: string; permanent: boolean }) {
    if (data.permanent) {
      this.timezonePrefs = data.timezone

      localStorage.setItem('OuraTimezonePrefs', data.timezone)
    }

    this.setUTCTimezone(data.timezone === 'UTC')
  }

  public changeGraphMode(data: { mode: GraphMode; permanent: boolean }) {
    this.graphMode = data.mode

    if (data.permanent) {
      this.graphMode = data.mode

      localStorage.setItem('OuraGraphMode', data.mode)
    }
  }
}
