<template>
  <v-row>
    <v-col cols="12" md="9">
      <div class="text-h5 font-weight-light">Audit event messages of Darwin user actions</div>

      <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
        List of all the audit logged actions performed by the Darwin users
      </div>
    </v-col>

    <v-col cols="12" md="3" class="text-right">
      <v-btn text="Download as CSV" color="primary" @click="downloadAsCSV()" />
    </v-col>
  </v-row>

  <FilterBar
    v-if="showFilters"
    class="mt-4"
    :all-filters="allFiltersEvents"
    :filters-name="'by Darwin user email'"
    :filters-type="'event type'"
    @filter="updateEmailFilter($event)"
    @filters="updateTypeFilters($event)"
    @change="loadEvents(1)"
  />

  <v-sheet class="mt-8">
    <v-data-table
      single-expand
      show-expand
      :loading="dataWait"
      :expanded="expandedLog"
      :page="page"
      :items="logs"
      :headers="headers"
      :items-per-page="itemsPerPage"
      item-value="createdAt"
      disable-sort
      @update:page="moreEvents($event)"
    >
      <template #[`item.createdAt`]="{ item }">
        <div class="text-no-wrap">
          {{ item.createdAt ? $dayjs(item.createdAt).utc().format('HH:mm - DD MM YYYY [UTC]') : '' }}
        </div>
      </template>
      <template #[`item.organizationData`]="{ item }">
        {{ getOrgData(item) }}
      </template>
      <template #[`item.ouraUser`]="{ item }">
        {{ getOuraUser(item) }}
      </template>
      <template #[`item.email`]="{ item }">
        <span class="text-no-wrap" @click="showUserDetails(null, item)">
          {{ item.email }}
          <v-icon color="blue" small>mdi-information</v-icon>
          <v-tooltip location="top" activator="parent">Click to see more details</v-tooltip>
        </span>
      </template>
      <template #expanded-row="{ item }">
        <td :colspan="6" class="pa-0">
          <JSONView :data="item" />
        </td>
      </template>
    </v-data-table>
  </v-sheet>

  <v-dialog v-model="dialog" scrollable width="1200">
    <v-card class="dialog">
      <v-card-title class="headline lighten-2">
        <span>User details for user: {{ email }}</span>
        <v-spacer />
        <v-btn icon="mdi-close" variant="plain" @click="dialog = false" />
      </v-card-title>

      <v-card-text v-if="!groups.includes('groupDarwinAdmins')">
        You don't have the access right to see the user audit details
      </v-card-text>

      <v-card-text v-else-if="auditUsers.length && !auditUsers.find((u) => u.email.includes(email))">
        This user does not exist anymore
      </v-card-text>

      <AuditUsers v-else :user-filter-prefilled="email" :show-filters="false" />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { Debounce } from '@jouzen/outo-toolkit-vuetify'

  import { DateTime } from '#mixins/dateTime'

  import { allFiltersEvents, auditEventsHeaders } from '#views/audit/constants'

  import { generateCSVData } from '#utils/audit/csvHelper'

  import { AuditStore } from '#stores'

  import { AuditLog } from '#types'

  @Component
  class AuditEvents extends mixins(DateTime) {
    @Prop() public groups!: string[]
    @Prop() public filteredLogs!: AuditLog[]
    @Prop() public itemsPerPage: number = 100
    @Prop() public showFilters: boolean = true

    public auditStore = new AuditStore()

    public emailFilter: string = ''
    public typeFilters: any = null
    public expandedLog: any[] = []
    public dialog: boolean = false
    public email: string = ''

    public headers = auditEventsHeaders
    public allFiltersEvents = allFiltersEvents

    private localDataWait = false

    public get logs(): AuditLog[] {
      return this.filteredLogs || this.auditStore.auditLogs
    }

    public get auditUsers() {
      return this.auditStore.auditUsers || []
    }

    public get page() {
      return this.auditStore.auditPage
    }

    public get auditEvents() {
      return this.auditStore.auditEvents || []
    }

    public get dataWait() {
      return this.auditStore.dataWait || this.localDataWait
    }

    @Watch('emailFilter')
    protected onEmailFilterChanged() {
      this.localDataWait = true
      this.loadEvents(this.page)
    }

    @Debounce(1000)
    public loadEvents(page: number) {
      this.auditStore.getAuditLogs({
        page,
        filters: this.typeFilters,
        search: this.emailFilter,
      })
      this.localDataWait = false
    }

    public updateEmailFilter(value: string) {
      this.emailFilter = value
    }

    public updateTypeFilters(value: any) {
      this.localDataWait = true
      this.typeFilters = value
    }

    // download displayed audit log list as CSV file
    public downloadAsCSV() {
      const logsPerPage = this.itemsPerPage
      const startIndex = (this.page - 1) * logsPerPage
      const endIndex = this.page * logsPerPage
      const displayedLogs = this.logs.slice(startIndex, endIndex)

      const csvData = generateCSVData(displayedLogs)

      const pageNumber = this.page

      const fileName = `auditLogs_page${pageNumber}.csv`

      this.createAndDownloadCSV(csvData, fileName)
    }

    public createAndDownloadCSV(csvData: string, fileName: string) {
      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      a.click()
      URL.revokeObjectURL(url)
    }

    public getOuraUser(item: AuditLog): string {
      if (item?.ouraUserUid || item?.ouraUserEmail) {
        return `${item.ouraUserUid} (${item.ouraUserEmail})`
      } else if (item?.json?.userEmail) {
        return `${item.json.userEmail}`
      } else {
        return '-'
      }
    }

    public getOrgData(item: AuditLog): string {
      if (item?.organizationUid || item?.organizationName) {
        return `${item.organizationUid} (${item.organizationName})`
      } else if (item?.json?.orgUid || item?.json?.orgName) {
        return `${item.json.orgUid} (${item.json.orgName})`
      } else {
        return '-'
      }
    }

    public showUserDetails(_event: any, user: any) {
      this.dialog = true
      this.email = user.email
    }

    public moreEvents(page: number) {
      this.auditStore.getAuditLogs({
        page: page,
      })
    }
  }

  export default toNative(AuditEvents)
</script>

<style lang="scss" scoped>
  :deep(.v-data-table) {
    .v-data-footer__select,
    .v-data-footer__pagination {
      visibility: hidden;
    }
  }
</style>
