<template>
  <v-dialog :model-value="dialog" scrollable width="1000" @after-leave="emitClose()">
    <v-card>
      <v-card-title class="headline">Create organization</v-card-title>

      <v-alert v-if="!!error" tile class="px-5 v-sheet--tile" type="error" color="red">
        <v-row class="v-row px-3 my-n1">
          {{ error }}
        </v-row>
      </v-alert>

      <v-card-text>
        <h6 class="text-overline mb-4">Team info</h6>

        <v-row>
          <v-col>
            <v-text-field
              v-model.trim="organization.name"
              :rules="[rules.required, rules.minLength]"
              hide-details="auto"
              label="Organization name *"
            />
          </v-col>
          <v-col>
            <v-select v-model="organization.type" :items="types" item-title="text" label="Organization type *" />
          </v-col>
        </v-row>

        <ul class="mx-8 mb-6">
          <li>
            <b>Oura Teams</b>
            : An Oura Teams organization. Selecting "Research" does not affect functionality and is only meant for
            internal book-keeping.
          </li>
          <li>
            <b>Health Risk Management</b>
            : A Health Risk Management (HRM) organization.
          </li>
        </ul>

        <template v-if="subscription && defaultPlan">
          <h6 class="text-overline mb-4">Subscription</h6>

          <v-row>
            <v-col>
              <v-select v-model="defaultPlan.name" :items="planOptions" item-title="text" label="Plan" disabled />
            </v-col>
            <v-col>
              <v-select
                v-model="subscription.status"
                :items="subscriptionStatuses"
                item-title="text"
                label="Subscription status"
              />
            </v-col>
          </v-row>

          <v-row class="mb-n8">
            <v-col>
              <DateRangePicker
                v-model:start-date="subscription.startDate"
                v-model:end-date="subscription.endDate"
                :max-date="null"
                text-input-width="400px"
                @valid="datesAreValid = $event"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field v-model.number="subscription.seatCount" type="number" label="Seat count" />
            </v-col>

            <v-col v-if="organization.type !== 'health_private' && organization.type !== 'illness_detection'">
              <v-switch v-model="subscription.blackoutMode" label="Enable Remote App Management" />
            </v-col>
          </v-row>
        </template>

        <h6 class="text-overline my-4">Admin info</h6>

        <v-row>
          <v-col>
            <v-text-field
              v-model="organization.adminEmail"
              :rules="[rules.required, rules.email]"
              hide-details="auto"
              label="Admin email *"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="organization.accountManagerEmail"
              :rules="[rules.email]"
              hide-details="auto"
              label="Account manager email"
            />
          </v-col>
        </v-row>

        <h6 class="text-overline my-4">NetSuite Customer ID</h6>

        <v-row>
          <v-col>
            <v-text-field
              v-model="organization.externalId"
              :readonly="internalSwitch"
              class="mt-0 pt-0"
              label="NetSuite Customer ID"
              hint="Format: Cust-123456"
              hide-details="auto"
            />
          </v-col>

          <v-col class="align-self-end">
            <v-switch v-model="internalSwitch" label="Is an internal test / personal organization" />
          </v-col>
        </v-row>

        <template v-if="organization && organization.type !== 'health_private'">
          <h6 class="text-overline my-4">Access permissions</h6>

          <v-row class="ma-0">
            <v-checkbox
              v-model="organization.permissions"
              label="Full access"
              color="blue"
              hide-details
              class="mr-4"
              value="full_access"
            />
            <v-checkbox
              v-model="organization.permissions"
              label="Temperature access"
              color="blue"
              hide-details
              class="mr-4"
              value="temperature_access"
            />
            <v-checkbox
              v-model="organization.permissions"
              label="IBI access"
              color="blue"
              hide-details
              class="mr-4"
              value="ibi_access"
            />
            <v-checkbox
              v-model="organization.permissions"
              label="30S Hypno access"
              color="blue"
              hide-details
              class="mr-4"
              value="hypno_30s_access"
            />
          </v-row>
        </template>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn variant="text" class="mr-2" @click="emitClose()">Cancel</v-btn>
        <v-btn variant="text" color="primary" :disabled="verifyCreate" :loading="dataWait" @click="saveTeam()">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import {
    organizationNameMinLength,
    ouraTestingExternalId,
    planOptions,
    responseHandler,
    subscriptionStatuses,
  } from '#views/teams/constants'

  import { getEmailValidationErrorMessage, isEmailValid } from '#utils/user/emailValidation'

  import { TeamsStore } from '#stores'

  import { CreateSubscriptionData, TeamsOrganization, TeamsOrganizationType, TeamsPlan } from '#types'

  @Component
  class CreateOrganization extends Vue {
    @Prop() public dialog!: boolean
    @Prop() public organization!: TeamsOrganization

    public teamsStore = new TeamsStore()

    private env = import.meta.env.VITE_APP_ENV

    public rules = {
      required: (value: string) => !!value || 'Required.',
      email: (email: string) => isEmailValid(email, this.env) || getEmailValidationErrorMessage(this.env),
      minLength: (value: string) => value.length >= organizationNameMinLength || 'Minimum 3 characters',
    }

    public error = ''

    public internalSwitch = false

    public datesAreValid = false

    public types: { text: string; value: TeamsOrganizationType }[] = [
      {
        text: 'Oura Teams',
        value: 'coach',
      },
      {
        text: 'Oura Teams (Research)',
        value: 'research',
      },
      {
        text: 'Health Risk Management',
        value: 'health_private',
      },
      // {
      //   text: 'Health Risk Management (Non-Anonymous)',
      //   value: 'illness_detection',
      // },
    ]

    public planOptions = planOptions
    public subscriptionStatuses = subscriptionStatuses

    public subscription: CreateSubscriptionData | null = null

    @Emit('close')
    public emitClose() {
      return
    }

    public get dataWait() {
      return this.teamsStore.dataWait
    }

    public get plans(): TeamsPlan[] {
      return this.teamsStore.plans
    }

    public get defaultPlan(): TeamsPlan | undefined {
      const planName =
        this.organization?.type === 'health_private' || this.organization?.type === 'illness_detection'
          ? 'hrm'
          : 'teams'

      return this.plans?.find(({ name }) => name === planName)
    }

    public get verifyCreate() {
      return (
        !this.datesAreValid ||
        !this.organization.name ||
        this.organization.name.length < organizationNameMinLength ||
        !this.organization.type ||
        !this.organization.adminEmail ||
        (!!this.organization.adminEmail && !isEmailValid(this.organization.adminEmail, this.env)) ||
        this.dataWait ||
        !this.defaultPlan ||
        !this.subscription ||
        (!!this.organization.accountManagerEmail && !isEmailValid(this.organization.accountManagerEmail, this.env))
      )
    }

    @Watch('defaultPlan')
    protected onDefaultPlanChange() {
      this.setupSubscription()
    }

    @Watch('internalSwitch')
    protected onInternalSwitchChange() {
      if (this.internalSwitch) {
        this.organization.externalId = ouraTestingExternalId
      } else {
        this.organization.externalId = ''
      }
    }

    public mounted() {
      this.teamsStore.listPlans().then(() => {
        this.setupSubscription()
      })
    }

    public saveTeam() {
      this.error = ''

      if (this.subscription) {
        const startDate = this.$dayjs.utc(this.subscription.startDate).toISOString()
        const endDate = this.$dayjs.utc(this.subscription.endDate).toISOString()

        this.teamsStore
          .createOrganization({
            organization: this.organization,
            subscription: {
              ...this.subscription,
              endDate,
              startDate,
            },
          })
          .then(
            responseHandler({
              onError: (error) => {
                this.error = error
              },
              onSuccess: () => this.emitClose(),
            }),
          )
      }
    }

    public setupSubscription() {
      if (!this.defaultPlan) {
        this.error =
          'Could not find default plan type for the organization. ' +
          'You cannot create new organization without default plan.'
      } else {
        this.subscription = {
          organizationID: '',
          planID: (this.defaultPlan as any).plan_uid,
          status: 'trial',
          startDate: this.$dayjs.utc().format('YYYY-MM-DD'),
          endDate: this.$dayjs.utc().add(30, 'days').format('YYYY-MM-DD'),
          seatCount: undefined,
          blackoutMode: undefined,
        }
      }
    }
  }

  export default toNative(CreateOrganization)
</script>
