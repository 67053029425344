import { QueryClient } from '@tanstack/query-core'

const cacheStaleTime = 1000 * 60 * 60 * 4 // 4 hours in milliseconds

/**
 * QueryClient of TanStack Query can be used for caching
 *
 * Check documentation here: https://tanstack.com/query/v5/docs/reference/QueryClient
 */
export const cacheQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: cacheStaleTime, // Cache TTL in milliseconds. `Infinity` = never considered stale.
    },
  },
})
