import { MemberInfo } from '#types'
import { Ring } from '#types'

/**
 * Extract member info component specific data
 */
export function extractMemberInfo(ring: Ring): MemberInfo {
  let daysLeftText = ''
  let warrantyEndDateClass = 'not-expired'
  if (ring?.warrantyEndDate) {
    const diff = new Date(ring.warrantyEndDate).getTime() - new Date().getTime()
    const diffDays = Math.ceil(diff / 24 / 60 / 60 / 1000)

    if (diffDays > 0) {
      if (diffDays == 1) {
        daysLeftText = `(expires tomorrow)`
      } else {
        daysLeftText = `(expires in ${diffDays} days)`
      }
    } else {
      if (diffDays == 0) {
        daysLeftText = `(expires today)`
      } else if (diffDays == -1) {
        daysLeftText = `(expired yesterday)`
      } else {
        daysLeftText = `(expired ${-diffDays} days ago)`
      }
      warrantyEndDateClass = 'expired'
    }
  }

  return {
    warrantyEndDate: ring?.warrantyEndDate ?? null,
    warrantyStartDate: ring?.warrantyStartDate ?? null,
    warrantyDaysLeftText: daysLeftText.length > 0 ? daysLeftText : null,
    warrantyEndDateClass: warrantyEndDateClass,
  }
}
