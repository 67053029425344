<template>
  <v-app-bar>
    <v-tabs style="max-width: 480px">
      <v-tab to="bulk">Bulk Account Batches</v-tab>
      <v-tab v-if="rights.includes('rolesOuraUsersAdmin')" to="shared">Manage Shared Accounts</v-tab>
    </v-tabs>
  </v-app-bar>

  <v-container>
    <v-window v-model="tab">
      <v-window-item value="bulk">
        <BulkAccounts />
      </v-window-item>

      <v-window-item v-if="rights.includes('rolesOuraUsersAdmin')" value="shared">
        <SharedAccounts />
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { AppStore } from '#stores'

  @Component
  class AdminView extends Vue {
    @Prop() public tab!: string

    public AppsStore = new AppStore()

    public get rights() {
      return this.AppsStore.activeRights || []
    }
  }

  export default toNative(AdminView)
</script>

<style lang="scss" scoped>
  .accounts :deep(.v-item-group),
  .accounts :deep(.v-window) {
    background: transparent !important;
  }

  .accounts :deep(.v-expansion-panel) {
    background: transparent !important;

    &::before {
      display: none;
    }

    .v-expansion-panel-header {
      padding: 0;
    }

    .v-expansion-panel-header__icon i {
      color: #e91e63 !important;
    }

    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }

  .infos :deep(.v-text-field__slot) {
    input {
      text-align: right;
    }
  }
</style>
