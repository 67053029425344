// sort-imports-ignore

import { createRouter, createWebHistory } from 'vue-router'

import { getAnalytics, logEvent } from 'firebase/analytics'

import Home from '#Home.vue'

import { AppStore, PrefsStore } from '#stores'

import AppsView from '#views/apps/AppsView.vue'
import TeamsView from '#views/teams/TeamsView.vue'

import AdminView from '#views/admin/AdminView.vue'
import AuditView from '#views/audit/AuditView.vue'

import OrdersView from '#views/orders/OrdersView.vue'

import SidecarView from '#views/sidecar/SidecarView.vue'

import EditOrganization from '#views/teams/EditOrganization.vue'

import DeviceDetailsView from '#views/devices/DeviceDetailsView.vue'
import DevicesSearchView from '#views/devices/DevicesSearchView.vue'

import MemberDetailsView from '#views/members/MemberDetailsView.vue'
import MembersSearchView from '#views/members/MembersSearchView.vue'

import InternalDebugView from '#views/debug/InternalDebugView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'Home',
    },
    component: Home,
  },

  {
    path: '/login',
    component: Home,
    meta: {
      title: 'Login',
    },
    beforeEnter(to: any, from: any, next: any) {
      const appStore = new AppStore()

      setTimeout(() => appStore.login(), 1000)

      next({ path: '/' })
    },
  },
  {
    path: '/logout',
    component: Home,
    meta: {
      title: 'Logout',
    },
    beforeEnter(to: any, from: any, next: any) {
      const appStore = new AppStore()

      appStore.logout()

      next({ path: '/' })
    },
  },

  {
    path: '/audit/:tab',
    component: AuditView,
    name: 'Auditing',
    meta: {
      title: 'Auditing',
    },
    props: true,
    // beforeEnter: requireUserConsent,
  },

  {
    path: '/admin/:tab',
    component: AdminView,
    meta: {
      title: 'Accounts',
    },
    props: true,
    // beforeEnter: requireUserConsent,
  },
  {
    path: '/admin',
    redirect: () => '/admin/bulk',
  },

  {
    path: '/apps',
    component: AppsView,
    name: 'Oura Apps',
    meta: {
      title: 'Oura Apps',
    },
    props: true,
    // beforeEnter: requireUserConsent,
  },
  {
    path: '/teams',
    component: TeamsView,
    name: 'Oura Teams',
    meta: {
      title: 'Oura Teams',
    },
    props: true,
    // beforeEnter: requireUserConsent,
    children: [
      {
        path: ':orgID',
        component: EditOrganization,
        meta: {
          title: 'Oura Teams',
        },
        props: true,
        // beforeEnter: requireUserConsent,
      },
    ],
  },

  {
    path: '/users',
    name: 'Oura Members',
    meta: {
      title: 'Oura Members',
    },
    component: MembersSearchView,
  },
  {
    path: '/users/:uuid',
    redirect: (to: any) => `/users/${to.params.uuid}/account/overview`,
  },
  {
    path: '/users/:uuid/account',
    redirect: (to: any) => `/users/${to.params.uuid}/account/overview`,
  },
  {
    path: '/users/:uuid/health',
    redirect: (to: any) => `/users/${to.params.uuid}/health/arrhythmia`,
  },
  {
    path: '/users/:uuid/data',
    redirect: (to: any) => `/users/${to.params.uuid}/data/cconfig`,
  },
  {
    path: '/users/:uuid/:tab/:step',
    component: MemberDetailsView,
    name: 'Oura Member',
    meta: {
      title: 'Oura Member',
    },
    props: true,
  },

  {
    path: '/rings',
    component: DevicesSearchView,
    meta: {
      title: 'Oura Devices',
    },
    props: true,
  },
  {
    path: '/rings/:ringOrChargerSerial',
    component: DeviceDetailsView,
    name: 'ring-details',
    meta: {
      title: 'Oura Devices',
    },
    props: true,
  },

  {
    path: '/orders',
    component: OrdersView,
    meta: {
      title: 'Orders',
    },
    props: true,
  },

  {
    path: '/sidecar',
    name: 'Darwin Sidecar',
    component: SidecarView,
    meta: {
      title: 'Darwin Sidecar',
      panel: true,
    },
    props: true,
  },
  {
    path: '/internal-debug',
    name: 'Internal debug',
    component: InternalDebugView,
    meta: {
      title: 'Internal debug',
    },
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.afterEach((to, from) => {
  if (from?.name !== to?.name || !from.name) {
    logEvent(getAnalytics(), 'page_view', {
      page_title: to?.name?.toString() || '',
      page_location: window.location.toString().split('?')[0],
      page_path: to?.name === 'Oura User' ? '/users/:id' : to.path?.toString(),
    })
  }
})

// Let's set the path to store so we can manage user access there
router.beforeEach(async (to, from, next) => {
  const appStore = new AppStore()
  const prefsStore = new PrefsStore()

  if (to.path !== from.path) {
    appStore.currentRoute = to.path
    appStore.previousRoute = from.path.replace(/\/$/, '')
  }

  if (to.path.includes('/sidecar')) {
    appStore.autoLoginDisabled = true
  }

  // Make sure the env and utc query params are always in the url

  if (to.query.env) {
    appStore.setCloudEnv(to.query.env as string)
  } else {
    to.query.env = appStore.cloudEnv || import.meta.env.VITE_SYS_ENV

    next(to)

    return
  }

  if (to.query.utc) {
    prefsStore.setUTCTimezone(to.query.utc === 'true')
  } else if (to.path.startsWith('/users')) {
    to.query.utc = prefsStore.timezoneSetting === 'UTC' ? 'true' : 'false'

    next(to)

    return
  }

  // Wait for the auth state so beforeEnter can be used to load data

  await appStore.auth()

  next()
})

export default router
