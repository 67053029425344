<template>
  <div v-if="user">
    <v-row class="mt-0 mb-4">
      <v-col cols="12" md="9">
        <div class="d-flex text-h5 font-weight-light">{{ (user.email || user.uuid).toUpperCase() }}</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          <template v-if="user.emailVerified">Email has been verified and</template>
          <template v-else>
            Email has
            <b class="text-error">not been verified</b>
            and
          </template>

          <template v-if="user.emails && uniqueEmailAddresses === 1">
            this is the only registered email address for this account
          </template>
          <template v-else>
            there is
            <b>{{ uniqueEmailAddresses }}</b>
            older email{{ uniqueEmailAddresses > 1 ? 's' : '' }} registered for this account
          </template>
        </div>

        <div class="mt-4">
          <v-chip v-if="!user.rings?.length" color="red" class="mr-2 mb-2">No rings registered</v-chip>
          <template v-else>
            <v-hover
              v-for="(r, index) in user.rings"
              :key="r.macAddress"
              @update:model-value="previewRing = $event ? r : null"
            >
              <template #default="{ props }">
                <v-chip
                  v-bind="props"
                  :variant="r.macAddress === ring?.macAddress ? 'elevated' : 'tonal'"
                  class="mr-2 mb-2"
                  :color="isNewRing(r) ? 'success' : index === 0 ? 'info' : ''"
                  @click="ring = r"
                >
                  <template #prepend>
                    <div
                      class="v-avatar v-avatar--start v-theme--light v-avatar--density-default v-avatar--size-default v-avatar--variant-flat"
                    >
                      <img
                        alt="Ring image"
                        style="
                          display: block;
                          margin: 8px auto;
                          width: 20px;
                          min-width: 20px;
                          max-width: 20px;
                          min-height: 20px;
                          max-height: 20px;
                        "
                        :src="checkImgFile(r.design, r.color, r.hardwareType)"
                        @error="fallbackRingImage($event)"
                      />
                    </div>
                  </template>

                  <span class="text-capitalize">
                    {{ getRingDisplayName(r) }}
                  </span>
                </v-chip>
              </template>
            </v-hover>
          </template>
        </div>
      </v-col>

      <v-col v-if="ring" md="3" cols="12" class="d-flex align-start">
        <v-spacer />

        <v-tooltip :model-value="!!previewRing" location="bottom end" offset="36">
          <template #activator="{ props }">
            <div v-bind="props" class="d-flex flex-row align-center text-center">
              <div class="mr-8 text-subtitle-2 text-medium-emphasis font-weight-light">
                <template v-if="!user.rings?.length">No rings</template>
                <template v-else>
                  <div v-if="user.rings[0]?.macAddress === (previewRing || ring)?.macAddress" class="mb-2">
                    Current ring:
                  </div>
                  <div v-else-if="previewRing && previewRing.macAddress !== ring?.macAddress" class="mb-2">
                    Previous ring:
                  </div>
                  <div v-else class="mb-2">Selected ring:</div>

                  <span class="text-capitalize">
                    {{ getRingDisplayName(previewRing || ring) }}
                  </span>
                </template>
              </div>

              <v-badge
                icon="mdi-key"
                :color="rights.includes('allowDataDownloadAccess') || personal ? 'info' : 'grey'"
                style="cursor: pointer"
                location="bottom end"
                offset-x="16"
                offset-y="2"
                @click="authKeyModal = rights.includes('allowDataDownloadAccess') || personal ? true : false"
              >
                <div
                  class="d-flex align-center justify-center elevation-4"
                  style="
                    border-radius: 100%;
                    background-color: rgb(var(--v-theme-surface));
                    padding: 16px;
                    margin-top: -10px;
                    margin-bottom: -10px;

                    min-width: 120px;
                    min-height: 120px;
                    max-width: 120px;
                    max-height: 120px;
                    overflow: hidden;
                  "
                >
                  <v-img
                    v-if="!ring"
                    class="mx-4 mt-2"
                    :src="`/logos/logo-${theme?.global?.current?.value?.dark ? 'white' : 'black'}.png`"
                  />

                  <img
                    v-else
                    alt="Ring image"
                    style="width: 72px; height: 72px"
                    :src="
                      checkImgFile(
                        (previewRing || ring).design,
                        (previewRing || ring).color,
                        (previewRing || ring).hardwareType,
                      )
                    "
                    @error="fallbackRingImage($event)"
                  />
                </div>
              </v-badge>
            </div>
          </template>
          Ring serial: {{ (previewRing || ring).serialNumber || (previewRing || ring).macAddress }}
          <br />
          Last update:
          {{ formatDateTime((previewRing || ring).lastSeenDate, 'HH:mm Do MMM YYYY') }}
          <template v-if="ring.warrantyEndDate">
            <br />
            Warranty end date: {{ formatDateTime((previewRing || ring).warrantyEndDate, 'Do MMM YYYY') }}
          </template>
          <br />
          Days since ring registered:
          <span v-if="(previewRing || ring).registrationDate">
            {{
              Math.round(
                (Date.now() - new Date((previewRing || ring).registrationDate!).getTime()) / 1000 / 60 / 60 / 24,
              )
            }}
          </span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-stepper class="mb-8" :model-value="step">
      <v-stepper-header>
        <router-link style="color: var(--v-theme-on-surface)" :to="'/users/' + user.uuid + '/account/overview'">
          <v-stepper-item
            editable
            value="overview"
            icon="mdi-eye"
            edit-icon="mdi-eye"
            :color="step === 'overview' ? 'info' : ''"
          >
            Account overview
          </v-stepper-item>
        </router-link>

        <router-link style="color: var(--v-theme-on-surface)" :to="'/users/' + user.uuid + '/account/battery'">
          <v-stepper-item
            editable
            value="battery"
            icon="mdi-battery"
            edit-icon="mdi-battery"
            :color="step === 'battery' ? 'info' : ''"
          >
            Battery state
          </v-stepper-item>
        </router-link>

        <router-link style="color: var(--v-theme-on-surface)" :to="'/users/' + user.uuid + '/account/devices'">
          <v-stepper-item
            editable
            value="devices"
            icon="mdi-cellphone"
            edit-icon="mdi-cellphone"
            :color="step === 'devices' ? 'info' : ''"
            :to="'/users/' + user.uuid + '/account/devices'"
          >
            Devices summary
          </v-stepper-item>
        </router-link>

        <router-link style="color: var(--v-theme-on-surface)" :to="'/users/' + user.uuid + '/account/membership'">
          <v-stepper-item
            editable
            value="membership"
            icon="mdi-account"
            edit-icon="mdi-account"
            :color="step === 'membership' ? 'info' : ''"
            :to="'/users/' + user.uuid + '/account/membership'"
          >
            Membership info
          </v-stepper-item>
        </router-link>

        <router-link style="color: var(--v-theme-on-surface)" :to="'/users/' + user.uuid + '/account/troubleshooter'">
          <v-stepper-item
            editable
            value="troubleshooter"
            icon="mdi-chart-line"
            edit-icon="mdi-chart-line"
            :color="step === 'troubleshooter' ? 'info' : ''"
            :to="'/users/' + user.uuid + '/account/troubleshooter'"
          >
            Data troubleshooter
          </v-stepper-item>
        </router-link>
      </v-stepper-header>
    </v-stepper>

    <FetchRingAuthKey v-if="authKeyModal && user && ring" :user="user" :ring="ring" @close="authKeyModal = false" />
  </div>
</template>

<script lang="ts">
  import { uniqBy } from 'lodash-es'

  import { ThemeInstance, useTheme } from 'vuetify'

  import { Component, Prop, Setup, mixins, toNative } from 'vue-facing-decorator'

  import { getDeviceNameByType } from '@jouzen/outo-toolkit-vuetify'

  import { DateTime } from '#mixins/dateTime'

  import { AppStore, PrefsStore, RingsStore, TimelineStore, UserStore } from '#stores'

  import { Ring, RingDetails } from '#types'

  @Component
  class AccountHeading extends mixins(DateTime) {
    @Prop() public step!: string

    @Setup(() => useTheme())
    public readonly theme: ThemeInstance | undefined

    public authKeyModal = false

    public previewRing: Ring | null = null

    public userStore = new UserStore()
    public prefsStore = new PrefsStore()
    public timelineStore = new TimelineStore()
    public appStore = new AppStore()
    public ringsStore = new RingsStore()

    public get user() {
      return this.userStore.user
    }

    public get personal() {
      return this.userStore.user?.ownerAccess || this.userStore.user?.supportAccess || this.userStore.user?.sharedAccess
    }
    public get ringDetails(): RingDetails | null {
      return this.ringsStore.ring
    }

    public get ring(): Ring | null {
      return this.ringsStore.activeRing || null
    }

    public set ring(newRing: any) {
      this.ringsStore.setActiveRing(newRing)
    }

    public get rights() {
      return this.appStore.activeRights
    }

    public get dataWait() {
      return this.userStore.dataWait
    }

    public get uniqueEmailAddresses() {
      return uniqBy(this.user?.emails || [], 'email').length
    }

    public isNewRing(ring: any) {
      const rDate = new Date(ring.registrationDate)

      return Date.now() - rDate.getTime() < 30 * 24 * 60 * 60 * 1000
    }

    public checkImgFile(design: string | null, color: string | null, type: string | null) {
      let file = ''

      switch (type) {
        case 'gen2':
          file = design?.replace('gen1', 'heritage').replace('gemma', 'heritagex') + '-' + color + '.png'
          break

        case 'gen2m':
          file = design?.replace('gen1', 'heritage').replace('gemma', 'heritagex') + '-' + color + '.png'
          break

        case 'gen2x':
          file = design?.replace('heritage', 'heritagex') + '-' + color + '.png'
          break

        default:
          file = design + '-' + color + '.png'
          break
      }

      return `/rings/${file}`
    }

    public fallbackRingImage(event: Event) {
      const element = event.target as HTMLImageElement
      if (element) {
        element.src = `/rings/unknown-ring.png`
      }
    }

    public getRingDisplayName(ring: Ring): string {
      const generation = ring.ringSerialInfo?.generation ?? 'unknown'
      const marketingName = getDeviceNameByType(generation)
      return marketingName + (ring.design ? ` - ${ring.design.replace(generation, '').replace('_', ' ')}` : '')
    }
  }

  export default toNative(AccountHeading)
</script>

<style lang="scss" scoped>
  :deep(.v-chip) {
    .v-avatar {
      background: rgb(var(--v-theme-background));
      padding: 4px;
    }

    .v-chip__underlay {
      background-color: rgb(var(--v-theme-on-surface)) !important;
    }
  }
</style>
