// Country list from: https://github.com/jouzen/supa/blob/main/common/src/countryList.ts
// Source: https://knowledgecenter.zuora.com/BB_Introducing_Z_Business/D_Country%2C_State%2C_and_Province_Codes/A_Country_Names_and_Their_ISO_Codes

interface Country {
  standardCountryName: string
  countryNameInZuora: string
  countryLabelInZuora: string
  isoAlpha2Code: string
  isoAlpha3Code: string
}

export const list: Country[] = [
  {
    standardCountryName: 'Afghanistan',
    countryNameInZuora: 'Afghanistan',
    countryLabelInZuora: 'Afghanistan',
    isoAlpha2Code: 'AF',
    isoAlpha3Code: 'AFG',
  },
  {
    standardCountryName: 'Aland Islands',
    countryNameInZuora: 'Aland Islands',
    countryLabelInZuora: 'Aland Islands',
    isoAlpha2Code: 'AX',
    isoAlpha3Code: 'ALA',
  },
  {
    standardCountryName: 'Albania',
    countryNameInZuora: 'Albania',
    countryLabelInZuora: 'Albania',
    isoAlpha2Code: 'AL',
    isoAlpha3Code: 'ALB',
  },
  {
    standardCountryName: 'Algeria',
    countryNameInZuora: 'Algeria',
    countryLabelInZuora: 'Algeria',
    isoAlpha2Code: 'DZ',
    isoAlpha3Code: 'DZA',
  },
  {
    standardCountryName: 'American Samoa',
    countryNameInZuora: 'American Samoa',
    countryLabelInZuora: 'American Samoa',
    isoAlpha2Code: 'AS',
    isoAlpha3Code: 'ASM',
  },
  {
    standardCountryName: 'Andorra',
    countryNameInZuora: 'Andorra',
    countryLabelInZuora: 'Andorra',
    isoAlpha2Code: 'AD',
    isoAlpha3Code: 'AND',
  },
  {
    standardCountryName: 'Angola',
    countryNameInZuora: 'Angola',
    countryLabelInZuora: 'Angola',
    isoAlpha2Code: 'AO',
    isoAlpha3Code: 'AGO',
  },
  {
    standardCountryName: 'Anguilla',
    countryNameInZuora: 'Anguilla',
    countryLabelInZuora: 'Anguilla',
    isoAlpha2Code: 'AI',
    isoAlpha3Code: 'AIA',
  },
  {
    standardCountryName: 'Antarctica',
    countryNameInZuora: 'Antarctica',
    countryLabelInZuora: 'Antarctica',
    isoAlpha2Code: 'AQ',
    isoAlpha3Code: 'ATA',
  },
  {
    standardCountryName: 'Antigua And Barbuda',
    countryNameInZuora: 'Antigua And Barbuda',
    countryLabelInZuora: 'Antigua And Barbuda',
    isoAlpha2Code: 'AG',
    isoAlpha3Code: 'ATG',
  },
  {
    standardCountryName: 'Argentina',
    countryNameInZuora: 'Argentina',
    countryLabelInZuora: 'Argentina',
    isoAlpha2Code: 'AR',
    isoAlpha3Code: 'ARG',
  },
  {
    standardCountryName: 'Armenia',
    countryNameInZuora: 'Armenia',
    countryLabelInZuora: 'Armenia',
    isoAlpha2Code: 'AM',
    isoAlpha3Code: 'ARM',
  },
  {
    standardCountryName: 'Aruba',
    countryNameInZuora: 'Aruba',
    countryLabelInZuora: 'Aruba',
    isoAlpha2Code: 'AW',
    isoAlpha3Code: 'ABW',
  },
  {
    standardCountryName: 'Australia',
    countryNameInZuora: 'Australia',
    countryLabelInZuora: 'Australia',
    isoAlpha2Code: 'AU',
    isoAlpha3Code: 'AUS',
  },
  {
    standardCountryName: 'Austria',
    countryNameInZuora: 'Austria',
    countryLabelInZuora: 'Austria',
    isoAlpha2Code: 'AT',
    isoAlpha3Code: 'AUT',
  },
  {
    standardCountryName: 'Azerbaijan',
    countryNameInZuora: 'Azerbaijan',
    countryLabelInZuora: 'Azerbaijan',
    isoAlpha2Code: 'AZ',
    isoAlpha3Code: 'AZE',
  },
  {
    standardCountryName: 'Bahamas',
    countryNameInZuora: 'Bahamas',
    countryLabelInZuora: 'Bahamas',
    isoAlpha2Code: 'BS',
    isoAlpha3Code: 'BHS',
  },
  {
    standardCountryName: 'Bahrain',
    countryNameInZuora: 'Bahrain',
    countryLabelInZuora: 'Bahrain',
    isoAlpha2Code: 'BH',
    isoAlpha3Code: 'BHR',
  },
  {
    standardCountryName: 'Bangladesh',
    countryNameInZuora: 'Bangladesh',
    countryLabelInZuora: 'Bangladesh',
    isoAlpha2Code: 'BD',
    isoAlpha3Code: 'BGD',
  },
  {
    standardCountryName: 'Barbados',
    countryNameInZuora: 'Barbados',
    countryLabelInZuora: 'Barbados',
    isoAlpha2Code: 'BB',
    isoAlpha3Code: 'BRB',
  },
  {
    standardCountryName: 'Belarus',
    countryNameInZuora: 'Belarus',
    countryLabelInZuora: 'Belarus',
    isoAlpha2Code: 'BY',
    isoAlpha3Code: 'BLR',
  },
  {
    standardCountryName: 'Belgium',
    countryNameInZuora: 'Belgium',
    countryLabelInZuora: 'Belgium',
    isoAlpha2Code: 'BE',
    isoAlpha3Code: 'BEL',
  },
  {
    standardCountryName: 'Belize',
    countryNameInZuora: 'Belize',
    countryLabelInZuora: 'Belize',
    isoAlpha2Code: 'BZ',
    isoAlpha3Code: 'BLZ',
  },
  {
    standardCountryName: 'Benin',
    countryNameInZuora: 'Benin',
    countryLabelInZuora: 'Benin',
    isoAlpha2Code: 'BJ',
    isoAlpha3Code: 'BEN',
  },
  {
    standardCountryName: 'Bermuda',
    countryNameInZuora: 'Bermuda',
    countryLabelInZuora: 'Bermuda',
    isoAlpha2Code: 'BM',
    isoAlpha3Code: 'BMU',
  },
  {
    standardCountryName: 'Bhutan',
    countryNameInZuora: 'Bhutan',
    countryLabelInZuora: 'Bhutan',
    isoAlpha2Code: 'BT',
    isoAlpha3Code: 'BTN',
  },
  {
    standardCountryName: 'Bolivia, Plurinational Station of',
    countryNameInZuora: 'Bolivia',
    countryLabelInZuora: 'Bolivia',
    isoAlpha2Code: 'BO',
    isoAlpha3Code: 'BOL',
  },
  {
    standardCountryName: 'Bonaire, Saint Eustatius and Saba',
    countryNameInZuora: 'Bonaire, Saint Eustatius and Saba',
    countryLabelInZuora: 'Bonaire, Saint Eustatius and Saba',
    isoAlpha2Code: 'BQ',
    isoAlpha3Code: 'BES',
  },
  {
    standardCountryName: 'Bosnia and Herzegovina',
    countryNameInZuora: 'Bosnia and Herzegovina',
    countryLabelInZuora: 'Bosnia and Herzegovina',
    isoAlpha2Code: 'BA',
    isoAlpha3Code: 'BIH',
  },
  {
    standardCountryName: 'Botswana',
    countryNameInZuora: 'Botswana',
    countryLabelInZuora: 'Botswana',
    isoAlpha2Code: 'BW',
    isoAlpha3Code: 'BWA',
  },
  {
    standardCountryName: 'Bouvet Island',
    countryNameInZuora: 'Bouvet Island',
    countryLabelInZuora: 'Bouvet Island',
    isoAlpha2Code: 'BV',
    isoAlpha3Code: 'BVT',
  },
  {
    standardCountryName: 'Brazil',
    countryNameInZuora: 'Brazil',
    countryLabelInZuora: 'Brazil',
    isoAlpha2Code: 'BR',
    isoAlpha3Code: 'BRA',
  },
  {
    standardCountryName: 'British Indian Ocean Territory',
    countryNameInZuora: 'British Indian Ocean Territory',
    countryLabelInZuora: 'British Indian Ocean Territory',
    isoAlpha2Code: 'IO',
    isoAlpha3Code: 'IOT',
  },
  {
    standardCountryName: 'Brunei Darussalam',
    countryNameInZuora: 'Brunei Darussalam',
    countryLabelInZuora: 'Brunei Darussalam',
    isoAlpha2Code: 'BN',
    isoAlpha3Code: 'BRN',
  },
  {
    standardCountryName: 'Bulgaria',
    countryNameInZuora: 'Bulgaria',
    countryLabelInZuora: 'Bulgaria',
    isoAlpha2Code: 'BG',
    isoAlpha3Code: 'BGR',
  },
  {
    standardCountryName: 'Burkina Faso',
    countryNameInZuora: 'Burkina Faso',
    countryLabelInZuora: 'Burkina Faso',
    isoAlpha2Code: 'BF',
    isoAlpha3Code: 'BFA',
  },
  {
    standardCountryName: 'Burundi',
    countryNameInZuora: 'Burundi',
    countryLabelInZuora: 'Burundi',
    isoAlpha2Code: 'BI',
    isoAlpha3Code: 'BDI',
  },
  {
    standardCountryName: 'Cambodia',
    countryNameInZuora: 'Cambodia',
    countryLabelInZuora: 'Cambodia',
    isoAlpha2Code: 'KH',
    isoAlpha3Code: 'KHM',
  },
  {
    standardCountryName: 'Cameroon',
    countryNameInZuora: 'Cameroon',
    countryLabelInZuora: 'Cameroon',
    isoAlpha2Code: 'CM',
    isoAlpha3Code: 'CMR',
  },
  {
    standardCountryName: 'Canada',
    countryNameInZuora: 'Canada',
    countryLabelInZuora: 'Canada',
    isoAlpha2Code: 'CA',
    isoAlpha3Code: 'CAN',
  },
  {
    standardCountryName: 'Cape Verde',
    countryNameInZuora: 'Cape Verde',
    countryLabelInZuora: 'Cape Verde',
    isoAlpha2Code: 'CV',
    isoAlpha3Code: 'CPV',
  },
  {
    standardCountryName: 'Cayman Islands',
    countryNameInZuora: 'Cayman Islands',
    countryLabelInZuora: 'Cayman Islands',
    isoAlpha2Code: 'KY',
    isoAlpha3Code: 'CYM',
  },
  {
    standardCountryName: 'Central African Republic',
    countryNameInZuora: 'Central African Republic',
    countryLabelInZuora: 'Central African Republic',
    isoAlpha2Code: 'CF',
    isoAlpha3Code: 'CAF',
  },
  {
    standardCountryName: 'Chad',
    countryNameInZuora: 'Chad',
    countryLabelInZuora: 'Chad',
    isoAlpha2Code: 'TD',
    isoAlpha3Code: 'TCD',
  },
  {
    standardCountryName: 'Chile',
    countryNameInZuora: 'Chile',
    countryLabelInZuora: 'Chile',
    isoAlpha2Code: 'CL',
    isoAlpha3Code: 'CHL',
  },
  {
    standardCountryName: 'China',
    countryNameInZuora: 'China',
    countryLabelInZuora: 'China',
    isoAlpha2Code: 'CN',
    isoAlpha3Code: 'CHN',
  },
  {
    standardCountryName: 'Christmas Island',
    countryNameInZuora: 'Christmas Island',
    countryLabelInZuora: 'Christmas Island',
    isoAlpha2Code: 'CX',
    isoAlpha3Code: 'CXR',
  },
  {
    standardCountryName: 'Cocos (Keeling) Islands',
    countryNameInZuora: 'Cocos (Keeling) Islands',
    countryLabelInZuora: 'Cocos (Keeling) Islands',
    isoAlpha2Code: 'CC',
    isoAlpha3Code: 'CCK',
  },
  {
    standardCountryName: 'Colombia',
    countryNameInZuora: 'Colombia',
    countryLabelInZuora: 'Colombia',
    isoAlpha2Code: 'CO',
    isoAlpha3Code: 'COL',
  },
  {
    standardCountryName: 'Comoros',
    countryNameInZuora: 'Comoros',
    countryLabelInZuora: 'Comoros',
    isoAlpha2Code: 'KM',
    isoAlpha3Code: 'COM',
  },
  {
    standardCountryName: 'Congo',
    countryNameInZuora: 'Congo',
    countryLabelInZuora: 'Congo',
    isoAlpha2Code: 'CG',
    isoAlpha3Code: 'COG',
  },
  {
    standardCountryName: 'Congo, the Democratic Republic of the',
    countryNameInZuora: 'Congo the Democratic Republic of the',
    countryLabelInZuora: 'Congo, Democratic Republic of the',
    isoAlpha2Code: 'CD',
    isoAlpha3Code: 'COD',
  },
  {
    standardCountryName: 'Cook Islands',
    countryNameInZuora: 'Cook Islands',
    countryLabelInZuora: 'Cook Islands',
    isoAlpha2Code: 'CK',
    isoAlpha3Code: 'COK',
  },
  {
    standardCountryName: 'Costa Rica',
    countryNameInZuora: 'Costa Rica',
    countryLabelInZuora: 'Costa Rica',
    isoAlpha2Code: 'CR',
    isoAlpha3Code: 'CRI',
  },
  {
    standardCountryName: "Cote d'Ivoire",
    countryNameInZuora: "Cote d'Ivoire",
    countryLabelInZuora: "Cote d'Ivoire",
    isoAlpha2Code: 'CI',
    isoAlpha3Code: 'CIV',
  },
  {
    standardCountryName: 'Croatia',
    countryNameInZuora: 'Croatia',
    countryLabelInZuora: 'Croatia',
    isoAlpha2Code: 'HR',
    isoAlpha3Code: 'HRV',
  },
  {
    standardCountryName: 'Cuba',
    countryNameInZuora: 'Cuba',
    countryLabelInZuora: 'Cuba',
    isoAlpha2Code: 'CU',
    isoAlpha3Code: 'CUB',
  },
  {
    standardCountryName: 'Curacao',
    countryNameInZuora: 'Curacao',
    countryLabelInZuora: 'Curacao',
    isoAlpha2Code: 'CW',
    isoAlpha3Code: 'CUW',
  },
  {
    standardCountryName: 'Cyprus',
    countryNameInZuora: 'Cyprus',
    countryLabelInZuora: 'Cyprus',
    isoAlpha2Code: 'CY',
    isoAlpha3Code: 'CYP',
  },
  {
    standardCountryName: 'Czech Republic',
    countryNameInZuora: 'Czech Republic',
    countryLabelInZuora: 'Czech Republic',
    isoAlpha2Code: 'CZ',
    isoAlpha3Code: 'CZE',
  },
  {
    standardCountryName: 'Denmark',
    countryNameInZuora: 'Denmark',
    countryLabelInZuora: 'Denmark',
    isoAlpha2Code: 'DK',
    isoAlpha3Code: 'DNK',
  },
  {
    standardCountryName: 'Djibouti',
    countryNameInZuora: 'Djibouti',
    countryLabelInZuora: 'Djibouti',
    isoAlpha2Code: 'DJ',
    isoAlpha3Code: 'DJI',
  },
  {
    standardCountryName: 'Dominica',
    countryNameInZuora: 'Dominica',
    countryLabelInZuora: 'Dominica',
    isoAlpha2Code: 'DM',
    isoAlpha3Code: 'DMA',
  },
  {
    standardCountryName: 'Dominican Republic',
    countryNameInZuora: 'Dominican Republic',
    countryLabelInZuora: 'Dominican Republic',
    isoAlpha2Code: 'DO',
    isoAlpha3Code: 'DOM',
  },
  {
    standardCountryName: 'Ecuador',
    countryNameInZuora: 'Ecuador',
    countryLabelInZuora: 'Ecuador',
    isoAlpha2Code: 'EC',
    isoAlpha3Code: 'ECU',
  },
  {
    standardCountryName: 'Egypt',
    countryNameInZuora: 'Egypt',
    countryLabelInZuora: 'Egypt',
    isoAlpha2Code: 'EG',
    isoAlpha3Code: 'EGY',
  },
  {
    standardCountryName: 'El Salvador',
    countryNameInZuora: 'El Salvador',
    countryLabelInZuora: 'El Salvador',
    isoAlpha2Code: 'SV',
    isoAlpha3Code: 'SLV',
  },
  {
    standardCountryName: 'Equatorial Guinea',
    countryNameInZuora: 'Equatorial Guinea',
    countryLabelInZuora: 'Equatorial Guinea',
    isoAlpha2Code: 'GQ',
    isoAlpha3Code: 'GNQ',
  },
  {
    standardCountryName: 'Eritrea',
    countryNameInZuora: 'Eritrea',
    countryLabelInZuora: 'Eritrea',
    isoAlpha2Code: 'ER',
    isoAlpha3Code: 'ERI',
  },
  {
    standardCountryName: 'Estonia',
    countryNameInZuora: 'Estonia',
    countryLabelInZuora: 'Estonia',
    isoAlpha2Code: 'EE',
    isoAlpha3Code: 'EST',
  },
  {
    standardCountryName: 'Ethiopia',
    countryNameInZuora: 'Ethiopia',
    countryLabelInZuora: 'Ethiopia',
    isoAlpha2Code: 'ET',
    isoAlpha3Code: 'ETH',
  },
  {
    standardCountryName: 'Falkland Islands (Malvinas)',
    countryNameInZuora: 'Falkland Islands (Malvinas)',
    countryLabelInZuora: 'Falkland Islands',
    isoAlpha2Code: 'FK',
    isoAlpha3Code: 'FLK',
  },
  {
    standardCountryName: 'Faroe Islands',
    countryNameInZuora: 'Faroe Islands',
    countryLabelInZuora: 'Faroe Islands',
    isoAlpha2Code: 'FO',
    isoAlpha3Code: 'FRO',
  },
  {
    standardCountryName: 'Fiji',
    countryNameInZuora: 'Fiji',
    countryLabelInZuora: 'Fiji',
    isoAlpha2Code: 'FJ',
    isoAlpha3Code: 'FJI',
  },
  {
    standardCountryName: 'Finland',
    countryNameInZuora: 'Finland',
    countryLabelInZuora: 'Finland',
    isoAlpha2Code: 'FI',
    isoAlpha3Code: 'FIN',
  },
  {
    standardCountryName: 'France',
    countryNameInZuora: 'France',
    countryLabelInZuora: 'France',
    isoAlpha2Code: 'FR',
    isoAlpha3Code: 'FRA',
  },
  {
    standardCountryName: 'French Guiana',
    countryNameInZuora: 'French Guiana',
    countryLabelInZuora: 'French Guiana',
    isoAlpha2Code: 'GF',
    isoAlpha3Code: 'GUF',
  },
  {
    standardCountryName: 'French Polynesia',
    countryNameInZuora: 'French Polynesia',
    countryLabelInZuora: 'French Polynesia',
    isoAlpha2Code: 'PF',
    isoAlpha3Code: 'PYF',
  },
  {
    standardCountryName: 'French Southern Territories',
    countryNameInZuora: 'French Southern Territories',
    countryLabelInZuora: 'French Southern Territories',
    isoAlpha2Code: 'TF',
    isoAlpha3Code: 'ATF',
  },
  {
    standardCountryName: 'Gabon',
    countryNameInZuora: 'Gabon',
    countryLabelInZuora: 'Gabon',
    isoAlpha2Code: 'GA',
    isoAlpha3Code: 'GAB',
  },
  {
    standardCountryName: 'Gambia',
    countryNameInZuora: 'Gambia',
    countryLabelInZuora: 'Gambia',
    isoAlpha2Code: 'GM',
    isoAlpha3Code: 'GMB',
  },
  {
    standardCountryName: 'Georgia',
    countryNameInZuora: 'Georgia',
    countryLabelInZuora: 'Georgia',
    isoAlpha2Code: 'GE',
    isoAlpha3Code: 'GEO',
  },
  {
    standardCountryName: 'Germany',
    countryNameInZuora: 'Germany',
    countryLabelInZuora: 'Germany',
    isoAlpha2Code: 'DE',
    isoAlpha3Code: 'DEU',
  },
  {
    standardCountryName: 'Ghana',
    countryNameInZuora: 'Ghana',
    countryLabelInZuora: 'Ghana',
    isoAlpha2Code: 'GH',
    isoAlpha3Code: 'GHA',
  },
  {
    standardCountryName: 'Gibraltar',
    countryNameInZuora: 'Gibraltar',
    countryLabelInZuora: 'Gibraltar',
    isoAlpha2Code: 'GI',
    isoAlpha3Code: 'GIB',
  },
  {
    standardCountryName: 'Greece',
    countryNameInZuora: 'Greece',
    countryLabelInZuora: 'Greece',
    isoAlpha2Code: 'GR',
    isoAlpha3Code: 'GRC',
  },
  {
    standardCountryName: 'Greenland',
    countryNameInZuora: 'Greenland',
    countryLabelInZuora: 'Greenland',
    isoAlpha2Code: 'GL',
    isoAlpha3Code: 'GRL',
  },
  {
    standardCountryName: 'Grenada',
    countryNameInZuora: 'Grenada',
    countryLabelInZuora: 'Grenada',
    isoAlpha2Code: 'GD',
    isoAlpha3Code: 'GRD',
  },
  {
    standardCountryName: 'Guadeloupe',
    countryNameInZuora: 'Guadeloupe',
    countryLabelInZuora: 'Guadeloupe',
    isoAlpha2Code: 'GP',
    isoAlpha3Code: 'GLP',
  },
  {
    standardCountryName: 'Guatemala',
    countryNameInZuora: 'Guatemala',
    countryLabelInZuora: 'Guatemala',
    isoAlpha2Code: 'GT',
    isoAlpha3Code: 'GTM',
  },
  {
    standardCountryName: 'Guernsey',
    countryNameInZuora: 'Guernsey',
    countryLabelInZuora: 'Guernsey',
    isoAlpha2Code: 'GG',
    isoAlpha3Code: 'GGY',
  },
  {
    standardCountryName: 'Guinea',
    countryNameInZuora: 'Guinea',
    countryLabelInZuora: 'Guinea',
    isoAlpha2Code: 'GN',
    isoAlpha3Code: 'GIN',
  },
  {
    standardCountryName: 'Guinea-Bissau',
    countryNameInZuora: 'Guinea-Bissau',
    countryLabelInZuora: 'Guinea-Bissau',
    isoAlpha2Code: 'GW',
    isoAlpha3Code: 'GNB',
  },
  {
    standardCountryName: 'Guyana',
    countryNameInZuora: 'Guyana',
    countryLabelInZuora: 'Guyana',
    isoAlpha2Code: 'GY',
    isoAlpha3Code: 'GUY',
  },
  {
    standardCountryName: 'Haiti',
    countryNameInZuora: 'Haiti',
    countryLabelInZuora: 'Haiti',
    isoAlpha2Code: 'HT',
    isoAlpha3Code: 'HTI',
  },
  {
    standardCountryName: 'Heard Island and McDonald Islands',
    countryNameInZuora: 'Heard Island and McDonald Islands',
    countryLabelInZuora: 'Heard and Mc Donald Islands',
    isoAlpha2Code: 'HM',
    isoAlpha3Code: 'HMD',
  },
  {
    standardCountryName: 'Holy See (Vatican City State)',
    countryNameInZuora: 'Holy See (Vatican City State)',
    countryLabelInZuora: 'Vatican City State (Holy See)',
    isoAlpha2Code: 'VA',
    isoAlpha3Code: 'VAT',
  },
  {
    standardCountryName: 'Honduras',
    countryNameInZuora: 'Honduras',
    countryLabelInZuora: 'Honduras',
    isoAlpha2Code: 'HN',
    isoAlpha3Code: 'HND',
  },
  {
    standardCountryName: 'Hong Kong',
    countryNameInZuora: 'Hong Kong',
    countryLabelInZuora: 'Hong Kong, China',
    isoAlpha2Code: 'HK',
    isoAlpha3Code: 'HKG',
  },
  {
    standardCountryName: 'Hungary',
    countryNameInZuora: 'Hungary',
    countryLabelInZuora: 'Hungary',
    isoAlpha2Code: 'HU',
    isoAlpha3Code: 'HUN',
  },
  {
    standardCountryName: 'Iceland',
    countryNameInZuora: 'Iceland',
    countryLabelInZuora: 'Iceland',
    isoAlpha2Code: 'IS',
    isoAlpha3Code: 'ISL',
  },
  {
    standardCountryName: 'India',
    countryNameInZuora: 'India',
    countryLabelInZuora: 'India',
    isoAlpha2Code: 'IN',
    isoAlpha3Code: 'IND',
  },
  {
    standardCountryName: 'Indonesia',
    countryNameInZuora: 'Indonesia',
    countryLabelInZuora: 'Indonesia',
    isoAlpha2Code: 'ID',
    isoAlpha3Code: 'IDN',
  },
  {
    standardCountryName: 'Iran, Islamic Republic of',
    countryNameInZuora: 'Iran Islamic Republic of',
    countryLabelInZuora: 'Iran',
    isoAlpha2Code: 'IR',
    isoAlpha3Code: 'IRN',
  },
  {
    standardCountryName: 'Iraq',
    countryNameInZuora: 'Iraq',
    countryLabelInZuora: 'Iraq',
    isoAlpha2Code: 'IQ',
    isoAlpha3Code: 'IRQ',
  },
  {
    standardCountryName: 'Ireland',
    countryNameInZuora: 'Ireland',
    countryLabelInZuora: 'Ireland',
    isoAlpha2Code: 'IE',
    isoAlpha3Code: 'IRL',
  },
  {
    standardCountryName: 'Isle of Man',
    countryNameInZuora: 'Isle of Man',
    countryLabelInZuora: 'Isle of Man',
    isoAlpha2Code: 'IM',
    isoAlpha3Code: 'IMN',
  },
  {
    standardCountryName: 'Israel',
    countryNameInZuora: 'Israel',
    countryLabelInZuora: 'Israel',
    isoAlpha2Code: 'IL',
    isoAlpha3Code: 'ISR',
  },
  {
    standardCountryName: 'Italy',
    countryNameInZuora: 'Italy',
    countryLabelInZuora: 'Italy',
    isoAlpha2Code: 'IT',
    isoAlpha3Code: 'ITA',
  },
  {
    standardCountryName: 'Jamaica',
    countryNameInZuora: 'Jamaica',
    countryLabelInZuora: 'Jamaica',
    isoAlpha2Code: 'JM',
    isoAlpha3Code: 'JAM',
  },
  {
    standardCountryName: 'Japan',
    countryNameInZuora: 'Japan',
    countryLabelInZuora: 'Japan',
    isoAlpha2Code: 'JP',
    isoAlpha3Code: 'JPN',
  },
  {
    standardCountryName: 'Jersey',
    countryNameInZuora: 'Jersey',
    countryLabelInZuora: 'Jersey',
    isoAlpha2Code: 'JE',
    isoAlpha3Code: 'JEY',
  },
  {
    standardCountryName: 'Jordan',
    countryNameInZuora: 'Jordan',
    countryLabelInZuora: 'Jordan',
    isoAlpha2Code: 'JO',
    isoAlpha3Code: 'JOR',
  },
  {
    standardCountryName: 'Kazakhstan',
    countryNameInZuora: 'Kazakhstan',
    countryLabelInZuora: 'Kazakhstan',
    isoAlpha2Code: 'KZ',
    isoAlpha3Code: 'KAZ',
  },
  {
    standardCountryName: 'Kenya',
    countryNameInZuora: 'Kenya',
    countryLabelInZuora: 'Kenya',
    isoAlpha2Code: 'KE',
    isoAlpha3Code: 'KEN',
  },
  {
    standardCountryName: 'Kiribati',
    countryNameInZuora: 'Kiribati',
    countryLabelInZuora: 'Kiribati',
    isoAlpha2Code: 'KI',
    isoAlpha3Code: 'KIR',
  },
  {
    standardCountryName: "Korea, Democratic People's Republic of",
    countryNameInZuora: "Korea, Democratic People's Republic of",
    countryLabelInZuora: 'Korea (North)',
    isoAlpha2Code: 'KP',
    isoAlpha3Code: 'PRK',
  },
  {
    standardCountryName: 'Korea, Republic of',
    countryNameInZuora: 'Korea, Republic of',
    countryLabelInZuora: 'Korea, Republic of',
    isoAlpha2Code: 'KR',
    isoAlpha3Code: 'KOR',
  },
  {
    standardCountryName: 'Kuwait',
    countryNameInZuora: 'Kuwait',
    countryLabelInZuora: 'Kuwait',
    isoAlpha2Code: 'KW',
    isoAlpha3Code: 'KWT',
  },
  {
    standardCountryName: 'Kyrgyzstan',
    countryNameInZuora: 'Kyrgyzstan',
    countryLabelInZuora: 'Kyrgyzstan',
    isoAlpha2Code: 'KG',
    isoAlpha3Code: 'KGZ',
  },
  {
    standardCountryName: "Lao People's Democratic Republic",
    countryNameInZuora: "Lao People's Democratic Republic",
    countryLabelInZuora: "Lao People's Democratic Republic",
    isoAlpha2Code: 'LA',
    isoAlpha3Code: 'LAO',
  },
  {
    standardCountryName: 'Latvia',
    countryNameInZuora: 'Latvia',
    countryLabelInZuora: 'Latvia',
    isoAlpha2Code: 'LV',
    isoAlpha3Code: 'LVA',
  },
  {
    standardCountryName: 'Lebanon',
    countryNameInZuora: 'Lebanon',
    countryLabelInZuora: 'Lebanon',
    isoAlpha2Code: 'LB',
    isoAlpha3Code: 'LBN',
  },
  {
    standardCountryName: 'Lesotho',
    countryNameInZuora: 'Lesotho',
    countryLabelInZuora: 'Lesotho',
    isoAlpha2Code: 'LS',
    isoAlpha3Code: 'LSO',
  },
  {
    standardCountryName: 'Liberia',
    countryNameInZuora: 'Liberia',
    countryLabelInZuora: 'Liberia',
    isoAlpha2Code: 'LR',
    isoAlpha3Code: 'LBR',
  },
  {
    standardCountryName: 'Libyan Arab Jamahiriya',
    countryNameInZuora: 'Libyan Arab Jamahiriya',
    countryLabelInZuora: 'Libya',
    isoAlpha2Code: 'LY',
    isoAlpha3Code: 'LBY',
  },
  {
    standardCountryName: 'Liechtenstein',
    countryNameInZuora: 'Liechtenstein',
    countryLabelInZuora: 'Liechtenstein',
    isoAlpha2Code: 'LI',
    isoAlpha3Code: 'LIE',
  },
  {
    standardCountryName: 'Lithuania',
    countryNameInZuora: 'Lithuania',
    countryLabelInZuora: 'Lithuania',
    isoAlpha2Code: 'LT',
    isoAlpha3Code: 'LTU',
  },
  {
    standardCountryName: 'Luxembourg',
    countryNameInZuora: 'Luxembourg',
    countryLabelInZuora: 'Luxembourg',
    isoAlpha2Code: 'LU',
    isoAlpha3Code: 'LUX',
  },
  {
    standardCountryName: 'Macao',
    countryNameInZuora: 'Macao',
    countryLabelInZuora: 'Macau',
    isoAlpha2Code: 'MO',
    isoAlpha3Code: 'MAC',
  },
  {
    standardCountryName: 'Republic of North Macedonia',
    countryNameInZuora: 'Macedonia, the former Yugoslav Republic of',
    countryLabelInZuora: 'Macedonia',
    isoAlpha2Code: 'MK',
    isoAlpha3Code: 'MKD',
  },
  {
    standardCountryName: 'Madagascar',
    countryNameInZuora: 'Madagascar',
    countryLabelInZuora: 'Madagascar',
    isoAlpha2Code: 'MG',
    isoAlpha3Code: 'MDG',
  },
  {
    standardCountryName: 'Malawi',
    countryNameInZuora: 'Malawi',
    countryLabelInZuora: 'Malawi',
    isoAlpha2Code: 'MW',
    isoAlpha3Code: 'MWI',
  },
  {
    standardCountryName: 'Malaysia',
    countryNameInZuora: 'Malaysia',
    countryLabelInZuora: 'Malaysia',
    isoAlpha2Code: 'MY',
    isoAlpha3Code: 'MYS',
  },
  {
    standardCountryName: 'Maldives',
    countryNameInZuora: 'Maldives',
    countryLabelInZuora: 'Maldives',
    isoAlpha2Code: 'MV',
    isoAlpha3Code: 'MDV',
  },
  {
    standardCountryName: 'Mali',
    countryNameInZuora: 'Mali',
    countryLabelInZuora: 'Mali',
    isoAlpha2Code: 'ML',
    isoAlpha3Code: 'MLI',
  },
  {
    standardCountryName: 'Malta',
    countryNameInZuora: 'Malta',
    countryLabelInZuora: 'Malta',
    isoAlpha2Code: 'MT',
    isoAlpha3Code: 'MLT',
  },
  {
    standardCountryName: 'Marshall Islands',
    countryNameInZuora: 'Marshall Islands',
    countryLabelInZuora: 'Marshall Islands',
    isoAlpha2Code: 'MH',
    isoAlpha3Code: 'MHL',
  },
  {
    standardCountryName: 'Martinique',
    countryNameInZuora: 'Martinique',
    countryLabelInZuora: 'Martinique',
    isoAlpha2Code: 'MQ',
    isoAlpha3Code: 'MTQ',
  },
  {
    standardCountryName: 'Mauritania',
    countryNameInZuora: 'Mauritania',
    countryLabelInZuora: 'Mauritania',
    isoAlpha2Code: 'MR',
    isoAlpha3Code: 'MRT',
  },
  {
    standardCountryName: 'Mauritius',
    countryNameInZuora: 'Mauritius',
    countryLabelInZuora: 'Mauritius',
    isoAlpha2Code: 'MU',
    isoAlpha3Code: 'MUS',
  },
  {
    standardCountryName: 'Mayotte',
    countryNameInZuora: 'Mayotte',
    countryLabelInZuora: 'Mayotte',
    isoAlpha2Code: 'YT',
    isoAlpha3Code: 'MYT',
  },
  {
    standardCountryName: 'Mexico',
    countryNameInZuora: 'Mexico',
    countryLabelInZuora: 'Mexico',
    isoAlpha2Code: 'MX',
    isoAlpha3Code: 'MEX',
  },
  {
    standardCountryName: 'Micronesia, Federated States of',
    countryNameInZuora: 'Micronesia, Federated States of',
    countryLabelInZuora: 'Micronesia, Federated States of',
    isoAlpha2Code: 'FM',
    isoAlpha3Code: 'FSM',
  },
  {
    standardCountryName: 'Moldova, Republic of',
    countryNameInZuora: 'Moldova, Republic of',
    countryLabelInZuora: 'Moldova, Republic of',
    isoAlpha2Code: 'MD',
    isoAlpha3Code: 'MDA',
  },
  {
    standardCountryName: 'Monaco',
    countryNameInZuora: 'Monaco',
    countryLabelInZuora: 'Monaco',
    isoAlpha2Code: 'MC',
    isoAlpha3Code: 'MCO',
  },
  {
    standardCountryName: 'Mongolia',
    countryNameInZuora: 'Mongolia',
    countryLabelInZuora: 'Mongolia',
    isoAlpha2Code: 'MN',
    isoAlpha3Code: 'MNG',
  },
  {
    standardCountryName: 'Montenegro',
    countryNameInZuora: 'Montenegro',
    countryLabelInZuora: 'Montenegro',
    isoAlpha2Code: 'ME',
    isoAlpha3Code: 'MNE',
  },
  {
    standardCountryName: 'Montserrat',
    countryNameInZuora: 'Montserrat',
    countryLabelInZuora: 'Montserrat',
    isoAlpha2Code: 'MS',
    isoAlpha3Code: 'MSR',
  },
  {
    standardCountryName: 'Morocco',
    countryNameInZuora: 'Morocco',
    countryLabelInZuora: 'Morocco',
    isoAlpha2Code: 'MA',
    isoAlpha3Code: 'MAR',
  },
  {
    standardCountryName: 'Mozambique',
    countryNameInZuora: 'Mozambique',
    countryLabelInZuora: 'Mozambique',
    isoAlpha2Code: 'MZ',
    isoAlpha3Code: 'MOZ',
  },
  {
    standardCountryName: 'Myanmar',
    countryNameInZuora: 'Myanmar',
    countryLabelInZuora: 'Myanmar',
    isoAlpha2Code: 'MM',
    isoAlpha3Code: 'MMR',
  },
  {
    standardCountryName: 'Namibia',
    countryNameInZuora: 'Namibia',
    countryLabelInZuora: 'Namibia',
    isoAlpha2Code: 'NA',
    isoAlpha3Code: 'NAM',
  },
  {
    standardCountryName: 'Nauru',
    countryNameInZuora: 'Nauru',
    countryLabelInZuora: 'Nauru',
    isoAlpha2Code: 'NR',
    isoAlpha3Code: 'NRU',
  },
  {
    standardCountryName: 'Nepal',
    countryNameInZuora: 'Nepal',
    countryLabelInZuora: 'Nepal',
    isoAlpha2Code: 'NP',
    isoAlpha3Code: 'NPL',
  },
  {
    standardCountryName: 'Netherlands',
    countryNameInZuora: 'Netherlands',
    countryLabelInZuora: 'Netherlands',
    isoAlpha2Code: 'NL',
    isoAlpha3Code: 'NLD',
  },
  {
    standardCountryName: 'New Caledonia',
    countryNameInZuora: 'New Caledonia',
    countryLabelInZuora: 'New Caledonia',
    isoAlpha2Code: 'NC',
    isoAlpha3Code: 'NCL',
  },
  {
    standardCountryName: 'New Zealand',
    countryNameInZuora: 'New Zealand',
    countryLabelInZuora: 'New Zealand',
    isoAlpha2Code: 'NZ',
    isoAlpha3Code: 'NZL',
  },
  {
    standardCountryName: 'Nicaragua',
    countryNameInZuora: 'Nicaragua',
    countryLabelInZuora: 'Nicaragua',
    isoAlpha2Code: 'NI',
    isoAlpha3Code: 'NIC',
  },
  {
    standardCountryName: 'Niger',
    countryNameInZuora: 'Niger',
    countryLabelInZuora: 'Niger',
    isoAlpha2Code: 'NE',
    isoAlpha3Code: 'NER',
  },
  {
    standardCountryName: 'Nigeria',
    countryNameInZuora: 'Nigeria',
    countryLabelInZuora: 'Nigeria',
    isoAlpha2Code: 'NG',
    isoAlpha3Code: 'NGA',
  },
  {
    standardCountryName: 'Niue',
    countryNameInZuora: 'Niue',
    countryLabelInZuora: 'Niue',
    isoAlpha2Code: 'NU',
    isoAlpha3Code: 'NIU',
  },
  {
    standardCountryName: 'Norfolk Island',
    countryNameInZuora: 'Norfolk Island',
    countryLabelInZuora: 'Norfolk Island',
    isoAlpha2Code: 'NF',
    isoAlpha3Code: 'NFK',
  },
  {
    standardCountryName: 'Northern Ireland',
    countryNameInZuora: 'Northern Ireland',
    countryLabelInZuora: 'Northern Ireland',
    isoAlpha2Code: 'XI',
    isoAlpha3Code: 'GBR',
  },
  {
    standardCountryName: 'Northern Mariana Islands',
    countryNameInZuora: 'Northern Mariana Islands',
    countryLabelInZuora: 'Northern Mariana Islands',
    isoAlpha2Code: 'MP',
    isoAlpha3Code: 'MNP',
  },
  {
    standardCountryName: 'Norway',
    countryNameInZuora: 'Norway',
    countryLabelInZuora: 'Norway',
    isoAlpha2Code: 'NO',
    isoAlpha3Code: 'NOR',
  },
  {
    standardCountryName: 'Oman',
    countryNameInZuora: 'Oman',
    countryLabelInZuora: 'Oman',
    isoAlpha2Code: 'OM',
    isoAlpha3Code: 'OMN',
  },
  {
    standardCountryName: 'Pakistan',
    countryNameInZuora: 'Pakistan',
    countryLabelInZuora: 'Pakistan',
    isoAlpha2Code: 'PK',
    isoAlpha3Code: 'PAK',
  },
  {
    standardCountryName: 'Palau',
    countryNameInZuora: 'Palau',
    countryLabelInZuora: 'Palau',
    isoAlpha2Code: 'PW',
    isoAlpha3Code: 'PLW',
  },
  {
    standardCountryName: 'Palestinian, State of',
    countryNameInZuora: 'Palestinian Territory, Occupied',
    countryLabelInZuora: 'Palestinian Territory, Occupied',
    isoAlpha2Code: 'PS',
    isoAlpha3Code: 'PSE',
  },
  {
    standardCountryName: 'Panama',
    countryNameInZuora: 'Panama',
    countryLabelInZuora: 'Panama',
    isoAlpha2Code: 'PA',
    isoAlpha3Code: 'PAN',
  },
  {
    standardCountryName: 'Papua New Guinea',
    countryNameInZuora: 'Papua New Guinea',
    countryLabelInZuora: 'Papua New Guinea',
    isoAlpha2Code: 'PG',
    isoAlpha3Code: 'PNG',
  },
  {
    standardCountryName: 'Paraguay',
    countryNameInZuora: 'Paraguay',
    countryLabelInZuora: 'Paraguay',
    isoAlpha2Code: 'PY',
    isoAlpha3Code: 'PRY',
  },
  {
    standardCountryName: 'Peru',
    countryNameInZuora: 'Peru',
    countryLabelInZuora: 'Peru',
    isoAlpha2Code: 'PE',
    isoAlpha3Code: 'PER',
  },
  {
    standardCountryName: 'Philippines',
    countryNameInZuora: 'Philippines',
    countryLabelInZuora: 'Philippines',
    isoAlpha2Code: 'PH',
    isoAlpha3Code: 'PHL',
  },
  {
    standardCountryName: 'Pitcairn',
    countryNameInZuora: 'Pitcairn',
    countryLabelInZuora: 'Pitcairn',
    isoAlpha2Code: 'PN',
    isoAlpha3Code: 'PCN',
  },
  {
    standardCountryName: 'Poland',
    countryNameInZuora: 'Poland',
    countryLabelInZuora: 'Poland',
    isoAlpha2Code: 'PL',
    isoAlpha3Code: 'POL',
  },
  {
    standardCountryName: 'Portugal',
    countryNameInZuora: 'Portugal',
    countryLabelInZuora: 'Portugal',
    isoAlpha2Code: 'PT',
    isoAlpha3Code: 'PRT',
  },
  {
    standardCountryName: 'Qatar',
    countryNameInZuora: 'Qatar',
    countryLabelInZuora: 'Qatar',
    isoAlpha2Code: 'QA',
    isoAlpha3Code: 'QAT',
  },
  {
    standardCountryName: 'Reunion',
    countryNameInZuora: 'Reunion',
    countryLabelInZuora: 'Reunion',
    isoAlpha2Code: 'RE',
    isoAlpha3Code: 'REU',
  },
  {
    standardCountryName: 'Romania',
    countryNameInZuora: 'Romania',
    countryLabelInZuora: 'Romania',
    isoAlpha2Code: 'RO',
    isoAlpha3Code: 'ROU',
  },
  {
    standardCountryName: 'Russian Federation',
    countryNameInZuora: 'Russian Federation',
    countryLabelInZuora: 'Russian Federation',
    isoAlpha2Code: 'RU',
    isoAlpha3Code: 'RUS',
  },
  {
    standardCountryName: 'Rwanda',
    countryNameInZuora: 'Rwanda',
    countryLabelInZuora: 'Rwanda',
    isoAlpha2Code: 'RW',
    isoAlpha3Code: 'RWA',
  },
  {
    standardCountryName: 'Saint Barthelemy',
    countryNameInZuora: 'Saint Barthelemy',
    countryLabelInZuora: 'Saint Barthelemy',
    isoAlpha2Code: 'BL',
    isoAlpha3Code: 'BLM',
  },
  {
    standardCountryName: 'Saint Helena, Ascension and Tristan da Cunha',
    countryNameInZuora: 'Saint Helena',
    countryLabelInZuora: 'St. Helena',
    isoAlpha2Code: 'SH',
    isoAlpha3Code: 'SHN',
  },
  {
    standardCountryName: 'Saint Kitts and Nevis',
    countryNameInZuora: 'Saint Kitts and Nevis',
    countryLabelInZuora: 'Saint Kitts and Nevis',
    isoAlpha2Code: 'KN',
    isoAlpha3Code: 'KNA',
  },
  {
    standardCountryName: 'Saint Lucia',
    countryNameInZuora: 'Saint Lucia',
    countryLabelInZuora: 'Saint Lucia',
    isoAlpha2Code: 'LC',
    isoAlpha3Code: 'LCA',
  },
  {
    standardCountryName: 'Saint Martin (French part)',
    countryNameInZuora: 'Saint Martin (French part)',
    countryLabelInZuora: 'Saint Martin (French part)',
    isoAlpha2Code: 'MF',
    isoAlpha3Code: 'MAF',
  },
  {
    standardCountryName: 'Saint Pierre and Miquelon',
    countryNameInZuora: 'Saint Pierre and Miquelon',
    countryLabelInZuora: 'St. Pierre and Miquelon',
    isoAlpha2Code: 'PM',
    isoAlpha3Code: 'SPM',
  },
  {
    standardCountryName: 'Saint Vincent and the Grenadines',
    countryNameInZuora: 'Saint Vincent and the Grenadines',
    countryLabelInZuora: 'Saint Vincent and the Grenadines',
    isoAlpha2Code: 'VC',
    isoAlpha3Code: 'VCT',
  },
  {
    standardCountryName: 'Samoa',
    countryNameInZuora: 'Samoa (Independent)',
    countryLabelInZuora: 'Samoa (Independent)',
    isoAlpha2Code: 'WS',
    isoAlpha3Code: 'WSM',
  },
  {
    standardCountryName: 'San Marino',
    countryNameInZuora: 'San Marino',
    countryLabelInZuora: 'San Marino',
    isoAlpha2Code: 'SM',
    isoAlpha3Code: 'SMR',
  },
  {
    standardCountryName: 'Sao Tome and Principe',
    countryNameInZuora: 'Sao Tome and Principe',
    countryLabelInZuora: 'Sao Tome and Principe',
    isoAlpha2Code: 'ST',
    isoAlpha3Code: 'STP',
  },
  {
    standardCountryName: 'Saudi Arabia',
    countryNameInZuora: 'Saudi Arabia',
    countryLabelInZuora: 'Saudi Arabia',
    isoAlpha2Code: 'SA',
    isoAlpha3Code: 'SAU',
  },
  {
    standardCountryName: 'Senegal',
    countryNameInZuora: 'Senegal',
    countryLabelInZuora: 'Senegal',
    isoAlpha2Code: 'SN',
    isoAlpha3Code: 'SEN',
  },
  {
    standardCountryName: 'Serbia',
    countryNameInZuora: 'Serbia',
    countryLabelInZuora: 'Serbia',
    isoAlpha2Code: 'RS',
    isoAlpha3Code: 'SRB',
  },
  {
    standardCountryName: 'Seychelles',
    countryNameInZuora: 'Seychelles',
    countryLabelInZuora: 'Seychelles',
    isoAlpha2Code: 'SC',
    isoAlpha3Code: 'SYC',
  },
  {
    standardCountryName: 'Sierra Leone',
    countryNameInZuora: 'Sierra Leone',
    countryLabelInZuora: 'Sierra Leone',
    isoAlpha2Code: 'SL',
    isoAlpha3Code: 'SLE',
  },
  {
    standardCountryName: 'Singapore',
    countryNameInZuora: 'Singapore',
    countryLabelInZuora: 'Singapore',
    isoAlpha2Code: 'SG',
    isoAlpha3Code: 'SGP',
  },
  {
    standardCountryName: 'Sint Maarten (Dutch part)',
    countryNameInZuora: 'Sint Maarten',
    countryLabelInZuora: 'Sint Maarten',
    isoAlpha2Code: 'SX',
    isoAlpha3Code: 'SXM',
  },
  {
    standardCountryName: 'Slovakia',
    countryNameInZuora: 'Slovakia',
    countryLabelInZuora: 'Slovakia',
    isoAlpha2Code: 'SK',
    isoAlpha3Code: 'SVK',
  },
  {
    standardCountryName: 'Slovenia',
    countryNameInZuora: 'Slovenia',
    countryLabelInZuora: 'Slovenia',
    isoAlpha2Code: 'SI',
    isoAlpha3Code: 'SVN',
  },
  {
    standardCountryName: 'Solomon Islands',
    countryNameInZuora: 'Solomon Islands',
    countryLabelInZuora: 'Solomon Islands',
    isoAlpha2Code: 'SB',
    isoAlpha3Code: 'SLB',
  },
  {
    standardCountryName: 'Somalia',
    countryNameInZuora: 'Somalia',
    countryLabelInZuora: 'Somalia',
    isoAlpha2Code: 'SO',
    isoAlpha3Code: 'SOM',
  },
  {
    standardCountryName: 'South Africa',
    countryNameInZuora: 'South Africa',
    countryLabelInZuora: 'South Africa',
    isoAlpha2Code: 'ZA',
    isoAlpha3Code: 'ZAF',
  },
  {
    standardCountryName: 'South Georgia and the South Sandwich Islands',
    countryNameInZuora: 'South Georgia and the South Sandwich Islands',
    countryLabelInZuora: 'South Georgia and the South Sandwich Islands',
    isoAlpha2Code: 'GS',
    isoAlpha3Code: 'SGS',
  },
  {
    standardCountryName: 'South Sudan',
    countryNameInZuora: 'South Sudan',
    countryLabelInZuora: 'South Sudan',
    isoAlpha2Code: 'SS',
    isoAlpha3Code: 'SSD',
  },
  {
    standardCountryName: 'Spain',
    countryNameInZuora: 'Spain',
    countryLabelInZuora: 'Spain',
    isoAlpha2Code: 'ES',
    isoAlpha3Code: 'ESP',
  },
  {
    standardCountryName: 'Sri Lanka',
    countryNameInZuora: 'Sri Lanka',
    countryLabelInZuora: 'Sri Lanka',
    isoAlpha2Code: 'LK',
    isoAlpha3Code: 'LKA',
  },
  {
    standardCountryName: 'Sudan',
    countryNameInZuora: 'Sudan',
    countryLabelInZuora: 'Sudan',
    isoAlpha2Code: 'SD',
    isoAlpha3Code: 'SDN',
  },
  {
    standardCountryName: 'Suriname',
    countryNameInZuora: 'Suriname',
    countryLabelInZuora: 'Suriname',
    isoAlpha2Code: 'SR',
    isoAlpha3Code: 'SUR',
  },
  {
    standardCountryName: 'Svalbard and Jan Mayen',
    countryNameInZuora: 'Svalbard and Jan Mayen',
    countryLabelInZuora: 'Svalbard and Jan Mayen Islands',
    isoAlpha2Code: 'SJ',
    isoAlpha3Code: 'SJM',
  },
  {
    standardCountryName: 'Swaziland',
    countryNameInZuora: 'Swaziland',
    countryLabelInZuora: 'Swaziland',
    isoAlpha2Code: 'SZ',
    isoAlpha3Code: 'SWZ',
  },
  {
    standardCountryName: 'Sweden',
    countryNameInZuora: 'Sweden',
    countryLabelInZuora: 'Sweden',
    isoAlpha2Code: 'SE',
    isoAlpha3Code: 'SWE',
  },
  {
    standardCountryName: 'Switzerland',
    countryNameInZuora: 'Switzerland',
    countryLabelInZuora: 'Switzerland',
    isoAlpha2Code: 'CH',
    isoAlpha3Code: 'CHE',
  },
  {
    standardCountryName: 'Syrian Arab Republic',
    countryNameInZuora: 'Syrian Arab Republic',
    countryLabelInZuora: 'Syrian Arab Republic',
    isoAlpha2Code: 'SY',
    isoAlpha3Code: 'SYR',
  },
  {
    standardCountryName: 'Taiwan, Province of China',
    countryNameInZuora: 'Taiwan',
    countryLabelInZuora: 'Taiwan',
    isoAlpha2Code: 'TW',
    isoAlpha3Code: 'TWN',
  },
  {
    standardCountryName: 'Tajikistan',
    countryNameInZuora: 'Tajikistan',
    countryLabelInZuora: 'Tajikistan',
    isoAlpha2Code: 'TJ',
    isoAlpha3Code: 'TJK',
  },
  {
    standardCountryName: 'Tanzania, United Republic of',
    countryNameInZuora: 'Tanzania, United Republic of',
    countryLabelInZuora: 'Tanzania',
    isoAlpha2Code: 'TZ',
    isoAlpha3Code: 'TZA',
  },
  {
    standardCountryName: 'Thailand',
    countryNameInZuora: 'Thailand',
    countryLabelInZuora: 'Thailand',
    isoAlpha2Code: 'TH',
    isoAlpha3Code: 'THA',
  },
  {
    standardCountryName: 'Timor-Leste',
    countryNameInZuora: 'Timor-Leste',
    countryLabelInZuora: 'East Timor',
    isoAlpha2Code: 'TL',
    isoAlpha3Code: 'TLS',
  },
  {
    standardCountryName: 'Togo',
    countryNameInZuora: 'Togo',
    countryLabelInZuora: 'Togo',
    isoAlpha2Code: 'TG',
    isoAlpha3Code: 'TGO',
  },
  {
    standardCountryName: 'Tokelau',
    countryNameInZuora: 'Tokelau',
    countryLabelInZuora: 'Tokelau',
    isoAlpha2Code: 'TK',
    isoAlpha3Code: 'TKL',
  },
  {
    standardCountryName: 'Tonga',
    countryNameInZuora: 'Tonga',
    countryLabelInZuora: 'Tonga',
    isoAlpha2Code: 'TO',
    isoAlpha3Code: 'TON',
  },
  {
    standardCountryName: 'Trinidad and Tobago',
    countryNameInZuora: 'Trinidad and Tobago',
    countryLabelInZuora: 'Trinidad and Tobago',
    isoAlpha2Code: 'TT',
    isoAlpha3Code: 'TTO',
  },
  {
    standardCountryName: 'Tunisia',
    countryNameInZuora: 'Tunisia',
    countryLabelInZuora: 'Tunisia',
    isoAlpha2Code: 'TN',
    isoAlpha3Code: 'TUN',
  },
  {
    standardCountryName: 'Turkey',
    countryNameInZuora: 'Turkey',
    countryLabelInZuora: 'Turkey',
    isoAlpha2Code: 'TR',
    isoAlpha3Code: 'TUR',
  },
  {
    standardCountryName: 'Turkmenistan',
    countryNameInZuora: 'Turkmenistan',
    countryLabelInZuora: 'Turkmenistan',
    isoAlpha2Code: 'TM',
    isoAlpha3Code: 'TKM',
  },
  {
    standardCountryName: 'Turks and Caicos Islands',
    countryNameInZuora: 'Turks and Caicos Islands',
    countryLabelInZuora: 'Turks and Caicos Islands',
    isoAlpha2Code: 'TC',
    isoAlpha3Code: 'TCA',
  },
  {
    standardCountryName: 'Tuvalu',
    countryNameInZuora: 'Tuvalu',
    countryLabelInZuora: 'Tuvalu',
    isoAlpha2Code: 'TV',
    isoAlpha3Code: 'TUV',
  },
  {
    standardCountryName: 'Uganda',
    countryNameInZuora: 'Uganda',
    countryLabelInZuora: 'Uganda',
    isoAlpha2Code: 'UG',
    isoAlpha3Code: 'UGA',
  },
  {
    standardCountryName: 'Ukraine',
    countryNameInZuora: 'Ukraine',
    countryLabelInZuora: 'Ukraine',
    isoAlpha2Code: 'UA',
    isoAlpha3Code: 'UKR',
  },
  {
    standardCountryName: 'United Arab Emirates',
    countryNameInZuora: 'United Arab Emirates',
    countryLabelInZuora: 'United Arab Emirates',
    isoAlpha2Code: 'AE',
    isoAlpha3Code: 'ARE',
  },
  {
    standardCountryName: 'United Kingdom',
    countryNameInZuora: 'United Kingdom',
    countryLabelInZuora: 'United Kingdom',
    isoAlpha2Code: 'GB',
    isoAlpha3Code: 'GBR',
  },
  {
    standardCountryName: 'United States',
    countryNameInZuora: 'United States',
    countryLabelInZuora: 'United States',
    isoAlpha2Code: 'US',
    isoAlpha3Code: 'USA',
  },
  {
    standardCountryName: 'United States Minor Outlying Islands',
    countryNameInZuora: 'United States Minor Outlying Islands',
    countryLabelInZuora: 'United States Minor Outlying Islands',
    isoAlpha2Code: 'UM',
    isoAlpha3Code: 'UMI',
  },
  {
    standardCountryName: 'Uruguay',
    countryNameInZuora: 'Uruguay',
    countryLabelInZuora: 'Uruguay',
    isoAlpha2Code: 'UY',
    isoAlpha3Code: 'URY',
  },
  {
    standardCountryName: 'Uzbekistan',
    countryNameInZuora: 'Uzbekistan',
    countryLabelInZuora: 'Uzbekistan',
    isoAlpha2Code: 'UZ',
    isoAlpha3Code: 'UZB',
  },
  {
    standardCountryName: 'Vanuatu',
    countryNameInZuora: 'Vanuatu',
    countryLabelInZuora: 'Vanuatu',
    isoAlpha2Code: 'VU',
    isoAlpha3Code: 'VUT',
  },
  {
    standardCountryName: 'Venezuela, Bolivarian Republic of',
    countryNameInZuora: 'Venezuela',
    countryLabelInZuora: 'Venezuela',
    isoAlpha2Code: 'VE',
    isoAlpha3Code: 'VEN',
  },
  {
    standardCountryName: 'Viet Nam',
    countryNameInZuora: 'Viet Nam',
    countryLabelInZuora: 'Viet Nam',
    isoAlpha2Code: 'VN',
    isoAlpha3Code: 'VNM',
  },
  {
    standardCountryName: 'Virgin Islands, British',
    countryNameInZuora: 'Virgin Islands, British',
    countryLabelInZuora: 'Virgin Islands (British)',
    isoAlpha2Code: 'VG',
    isoAlpha3Code: 'VGB',
  },
  {
    standardCountryName: 'Wallis and Futuna',
    countryNameInZuora: 'Wallis and Futuna',
    countryLabelInZuora: 'Wallis and Futuna Islands',
    isoAlpha2Code: 'WF',
    isoAlpha3Code: 'WLF',
  },
  {
    standardCountryName: 'Western Sahara',
    countryNameInZuora: 'Western Sahara',
    countryLabelInZuora: 'Western Sahara',
    isoAlpha2Code: 'EH',
    isoAlpha3Code: 'ESH',
  },
  {
    standardCountryName: 'Yemen',
    countryNameInZuora: 'Yemen',
    countryLabelInZuora: 'Yemen',
    isoAlpha2Code: 'YE',
    isoAlpha3Code: 'YEM',
  },
  {
    standardCountryName: 'Zambia',
    countryNameInZuora: 'Zambia',
    countryLabelInZuora: 'Zambia',
    isoAlpha2Code: 'ZM',
    isoAlpha3Code: 'ZMB',
  },
  {
    standardCountryName: 'Zimbabwe',
    countryNameInZuora: 'Zimbabwe',
    countryLabelInZuora: 'Zimbabwe',
    isoAlpha2Code: 'ZW',
    isoAlpha3Code: 'ZWE',
  },
  {
    standardCountryName: 'Kosovo', // this one was N/A in original list
    countryNameInZuora: 'Kosovo',
    countryLabelInZuora: 'Kosovo',
    isoAlpha2Code: 'XK',
    isoAlpha3Code: 'XKX',
  },
]

export function getCountryNameList() {
  return list.map((country) => country.standardCountryName)
}

export function getCountryNameAndCodeList() {
  return list.map((country) => ({ title: country.standardCountryName, value: country.isoAlpha2Code }))
}

export const convertStandardCountryNameToIsoAlpha2Code = (standardCountryName: string): string | undefined =>
  list.find((c) => c.standardCountryName === standardCountryName)?.isoAlpha2Code

export const findCountryByIsoAlpha2Code = (isoAlpha2Code: string): Country | undefined => {
  const isoAlpha2CodeAsUpperCase = isoAlpha2Code.toUpperCase()
  return list.find((c) => c.isoAlpha2Code === isoAlpha2CodeAsUpperCase)
}
