import { RingDetails, RingDetailsResponse, WarrantyData, WarrantyDetailsResponse } from '#types'

export function extractRingDetailsFromRingDetailsResponse(ringDetailsResponse: RingDetailsResponse): RingDetails {
  // For now data format is identical. If and when external API changes, we now have separate internal data format
  return { ...ringDetailsResponse.ring }
}

export function extractWarrantyDataFromWarrantyDetailsResponse(
  warrantyDetailsResponse: WarrantyDetailsResponse,
): WarrantyData {
  // For now data format is identical. If and when external API changes, we now have separate internal data format
  return { ...warrantyDetailsResponse }
}
