<template>
  <div class="pa-4">
    <div class="text-overline">Debug tools</div>
    <v-text-field label="Error message" :model-value="errorMessage" />
    <v-btn color="primary" class="ma-2" @click="triggerSentryError()">Trigger Sentry error</v-btn>
    <v-btn color="primary" class="ma-2" @click="throwException()">Throw Error</v-btn>
  </div>
</template>

<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import * as Sentry from '@sentry/browser'

  @Component
  class DebugTools extends Vue {
    public errorMessage = 'Error triggered from Darwin debug tool'

    public getError(): Error {
      return new Error(this.errorMessage)
    }

    public triggerSentryError() {
      const error = this.getError()
      console.error('Triggering Sentry error:', error)
      Sentry.captureException(error)
    }

    public throwException() {
      const error = this.getError()
      console.error('Throwing following error:', error)
      throw error
    }
  }

  export default toNative(DebugTools)
</script>
