import { AxiosError } from 'axios'

import { Store } from 'pinia-class-component'

import { extractLatestVersions } from '#utils/config/extract'

import { BaseStore } from '#stores/base'
import { UserStore } from '#stores/user'

import { LatestVersions } from '#types'

@Store()
export class ConfigStore extends BaseStore {
  public dataWait = false

  public configs: any[] = []

  public configEventDates: any[] = []

  public versions: LatestVersions | null = null

  public async clientConfigs(data: { uuid: string; from: string | null; to: string | null }) {
    this.configs = []
    const userStore = new UserStore()

    const params = { from: data.from, to: data.to }

    this.dataWait = true
    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${data.uuid}/client-config-diff?`, params },
      'clientConfigs',
      data.uuid,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.dataWait = false
    this.configs = response?.data?.contents
  }

  public async getConfigEventDates(data: { uuid: string }) {
    this.configEventDates = []
    const userStore = new UserStore()

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${data.uuid}/client-config-change-events` },
      'getConfigEventDates',
      data.uuid,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.configEventDates = response?.data?.changeEvents || []
  }

  public async getLatestVersions(uuid: string) {
    this.versions = null

    const userStore = new UserStore()

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${uuid}/latest-versions` },
      'latestVersions',
      uuid,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })

    if (response?.data) {
      this.versions = extractLatestVersions(response.data)
    }
  }
}
