import { shippingDetailsNoErrors } from '#utils/user/shippingDetailsValidator'

import { ShippingDetailsErrors } from '#types'
import { FormValidationProblem } from '#types'

export function extractShippingDetailsErrorsFromFormValidationProblems(
  formValidationProblems: FormValidationProblem[],
): ShippingDetailsErrors {
  const shippingDetailsErrors: any = shippingDetailsNoErrors()
  formValidationProblems.forEach((value: FormValidationProblem) => {
    const keyName = value.field ?? 'generalError'
    shippingDetailsErrors[keyName].push(value.message)
  })
  return shippingDetailsErrors
}
