import { ContactDetail, ContactDetails, ContactDetailsResponse, PaymentMethod, PaymentMethodsResponse } from '#types'

export function extractContactDetailsFromResponse(contactDetailsResponse: ContactDetailsResponse): ContactDetails {
  let billToContact: ContactDetail | null = null
  let shipToContact: ContactDetail | null = null

  if (contactDetailsResponse.billToContact) {
    billToContact = contactDetailsResponse.billToContact
  }

  if (contactDetailsResponse.shipToContact) {
    shipToContact = contactDetailsResponse.shipToContact
  }

  return { billToContact: billToContact, shipToContact: shipToContact }
}

export function extractPaymentMethodsFromResponse(paymentMethodsResponse: PaymentMethodsResponse): PaymentMethod[] {
  return paymentMethodsResponse.paymentMethods
}
