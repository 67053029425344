<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <div>
    <v-card>
      <v-card-title>
        <div class="text-overline">Debug logs search</div>
      </v-card-title>

      <v-card-text>
        <v-toolbar class="mb-4">
          <v-row>
            <v-col class="d-flex" cols="12" sm="12" md="6">
              <v-row>
                <v-col>
                  <v-text-field
                    ref="search"
                    label="Search text"
                    append-inner-icon="mdi-account-search"
                    placeholder="Type regexp search, e.g. assa.*sleep, press enter to search..."
                    :rules="[rules.minLength, rules.maxLength]"
                    hide-details="auto"
                    @click:append="loadLogs()"
                    @keydown.enter="loadLogs()"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col class="d-flex" cols="12" sm="12" md="6">
              <v-row align="center" class="">
                <v-col>
                  <DateRangePicker
                    v-model:start-date="fromDate"
                    v-model:end-date="toDate"
                    :min-date="date1MonthBack || null"
                    @valid="datesAreValid = $event"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-alert v-model="debugInit" class="mb-4" type="info">Syncing debug logs, please wait...</v-alert>

        <v-alert type="warning" class="mb-4">
          Please note that data is only available one month backwards from the current date.
        </v-alert>

        <v-alert type="info" class="mb-4">
          Debug logs are only available for the last 30 days. Use regular expressions to search the content of the debug
          logs. Case sensitiveness can be changed from the Quick Settings. For possible regexp options see the
          <a href="https://prestodb.io/docs/current/functions/regexp.html" target="_blank" rel="noreferrer noopener">
            Presto regexp documentation
          </a>
          .
        </v-alert>

        <v-alert type="info" class="mb-8">
          Tip: If you want to include special characters such as
          <code class="bg-blue-lighten-5">* # ( ) [ ] &lt; &gt;</code>
          into your search, you need to escape them with
          <code class="bg-blue-lighten-5">\</code>
          . For example, if you want to search for text
          <code class="bg-blue-lighten-5">(background)*foregroundEvent*-appForegrounded-&gt;(foregroundSync)</code>
          add
          <code class="bg-blue-lighten-5">\</code>
          in front of every special character like this:
          <code class="bg-blue-lighten-5">\(background\)\*foregroundEvent\*-appForegrounded-\>\(foregroundSync\)</code>
        </v-alert>

        <v-row>
          <v-col align="stretch">
            <v-card :loading="dataWait">
              <v-data-table
                :headers="visibleHeaders"
                :items="logs"
                :items-per-page="10000"
                density="compact"
                disable-pagination
                hide-default-footer
                class="elevation-1"
                :class="!search ? 'non-expandable' : ''"
              >
                <template #item="{ item }">
                  <tr :class="item.lineIndex === extra ? 'selected-row' : ''" @click="expandLogs(item)">
                    <td>
                      <div class="text-no-wrap">
                        {{ formatDateTime(item.timestamp, 'HH:mm:ss.SSS - DD MMM YYYY') }}
                      </div>
                    </td>
                    <td>{{ item.userUid }}</td>
                    <td>
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <div v-html="sanitizeHTML(item.content)" />
                    </td>
                    <td>
                      <v-icon v-if="search" small class="expand mr-2">mdi-unfold-more-horizontal</v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="openExtraLogsDialog" width="100vw" @update:model-value="!$event && removeExtraLogs()">
      <v-card>
        <v-card-title class="float-end mb-n5 pr-4 pt-2">
          <v-spacer />
          <v-icon icon="mdi-window-close" @click="removeExtraLogs()" />
        </v-card-title>

        <v-card-text>
          <v-data-table
            ref="extraLogsTable"
            :headers="visibleHeaders"
            :items="extraLogs"
            :items-per-page="1000"
            disable-pagination
            hide-default-footer
          >
            <template #item="{ item }">
              <tr :class="item.lineIndex === extra ? 'extra-logs-selected-row' : ''" :data-id="item.lineIndex">
                <td>
                  <div class="text-no-wrap">
                    {{ formatDateTime(item.timestamp, 'HH:mm:ss.SSS - DD MMM YYYY') }}
                  </div>
                </td>
                <td>{{ item.userUid }}</td>
                <td>
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <div v-html="sanitizeHTML(item.content)" />
                </td>
                <td>
                  <v-icon v-if="search" small class="expand mr-2">mdi-unfold-more-horizontal</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { Debounce } from '@jouzen/outo-toolkit-vuetify'

  import { DateTime } from '#mixins/dateTime'

  import { debugHeaders, debugLogSearchMaxLength, debugLogSearchMinLength } from '#views/members/constants'

  import { DebugStore, PrefsStore } from '#stores'

  @Component
  class DebugLogsSearch extends mixins(DateTime) {
    @Prop() public uuid!: string

    public prefsStore = new PrefsStore()

    public debugStore = new DebugStore()

    public declare $refs: {
      search: any
      extraLogsTable: any
    }

    public date1MonthBack = ''
    public fromDate = ''
    public toDate = ''

    public datesAreValid = false

    public search = ''

    public showExtraLogs: boolean = false

    public extra = 0

    public headers = debugHeaders

    public rules = {
      maxLength: (value: any) => {
        if (value) {
          return (
            value.length <= debugLogSearchMaxLength ||
            `Keyword too long, maximum length is ${debugLogSearchMaxLength} chars.`
          )
        }
        return true
      },
      minLength: (value: any) => {
        if (value) {
          return (
            value.length >= debugLogSearchMinLength ||
            `Keyword too short, min length is ${debugLogSearchMinLength} chars.`
          )
        }
        return true
      },
    }

    @Watch('fromDate')
    @Watch('toDate')
    @Watch('datesAreValid')
    protected onToOrFromDateChanged() {
      this.loadLogs()
    }

    @Watch('uuid')
    protected onUUIDChanged(_val: string, _oldVal: string) {
      this.loadLogs()
    }

    public mounted() {
      this.date1MonthBack = this.$dayjs().subtract(1, 'month').format('YYYY-MM-DD')
      this.fromDate = this.$dayjs().subtract(1, 'month').format('YYYY-MM-DD')
      this.toDate = this.$dayjs().format('YYYY-MM-DD')

      logEvent(this.$analytics, 'page_view', {
        page_title: 'Debug Logs',
        page_location: window.location.toString().split('?')[0],
      })

      this.loadLogs()
    }

    public get logs() {
      return this.debugStore.debugLogs || []
    }

    public get dataWait() {
      return this.debugStore.dataWait
    }

    public get debugInit() {
      return this.debugStore.initWait
    }

    public get extraLogs() {
      this.showExtraLogs = true
      return this.debugStore.extraLogs || []
    }

    public get debugLogPrefs() {
      return this.prefsStore.debugLogPrefs
    }

    public get visibleHeaders(): any[] {
      const devices = [...new Set(this.logs.map((log: any) => log.deviceUid))]

      return this.headers.filter(
        (header) => (header.key !== 'userUid' || !this.uuid) && (header.key !== 'deviceUid' || devices.length !== 1),
      )
    }

    public get openExtraLogsDialog() {
      return this.showExtraLogs && !!this.extraLogs.length
    }

    public loadLogs() {
      if (this.uuid && this.datesAreValid) {
        this.debugStore.resetLogs()
        this.updateLogs()
      }
    }

    // public lineClass(line: any) {
    //  return this.extraLogs.length && line.item.lineIndex === this.extra ? 'selected' : ''
    // }

    public sanitizeHTML(html: string) {
      return html
        .replace(/&/g, '&amp;')
        .replace(/(?!<\/?strong>)</g, '&lt;')
        .replace(/(!<\/?strong>?)>/g, '&gt;')
    }

    @Debounce(1000)
    private updateLogs() {
      // Due to Vue v-model lag/bug we only update search value here (will be fixed in Vue 3)
      this.search = this.$refs.search.$el.getElementsByTagName('input')[0].value
      if (
        this.uuid &&
        this.debugLogPrefs &&
        this.search &&
        this.search.length <= debugLogSearchMaxLength &&
        this.search.length >= debugLogSearchMinLength
      ) {
        this.debugStore.setDebugLogs({
          search: this.search,
          uuid: this.uuid,
          from: this.fromDate,
          to: this.toDate,
          count: this.debugLogPrefs['count'],
          caseSensitive: this.debugLogPrefs['caseSensitive'],
        })
      }
    }

    public async expandLogs(line: any) {
      this.extra = line.lineIndex

      await this.debugStore.setExtraLogs({
        uuid: this.uuid,
        fileTimestamp: line.fileTimestamp,
        line: line.lineIndex,
        before: 100,
        after: 100,
      })
      this.showExtraLogs = true

      this.$nextTick(() => {
        const activeRow = this.$refs.extraLogsTable?.$el?.querySelector(`[data-id="${this.extra}"]`)
        if (activeRow) {
          activeRow.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      })
    }

    public removeExtraLogs() {
      this.showExtraLogs = false
      this.debugStore.extraLogs = []
    }
  }

  export default toNative(DebugLogsSearch)
</script>

<style lang="scss" scoped>
  .bg {
    background: #f8f8fb;
    height: 100%;
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  :deep(.v-alert) {
    .v-icon {
      align-self: center !important;
    }
  }
  :deep(tr) {
    cursor: pointer;
  }

  :deep(.non-expandable tr) {
    cursor: auto;
  }

  :deep(.expand) {
    visibility: hidden;
  }

  :deep(tbody tr:hover) {
    cursor: pointer;
    .expand {
      visibility: visible;
    }
  }

  :deep(.selected-row) {
    background-color: #e0e0e0;
  }

  :deep(.extra-logs-selected-row) {
    background-color: #fce4ec;
  }

  code {
    background: #c7c7c7;
    padding: 0.1rem 0.2rem;
    border-radius: 0.2rem;
  }
</style>
